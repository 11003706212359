.switch {
  position: relative;
  display: inline-block;
  width: 2.8125rem;
  height: 1.375rem;
  border-radius: 0.9375rem;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--Carriben-Light-Green, #E3FAF6);
    box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.10);
    backdrop-filter: blur(2px);
    -webkit-transition: .4s;
    transition: .4s;

  }

  .slider:before {
    position: absolute;
    content: "";
    width: 1rem;
    height: 1rem;
    left: 0.18rem;
    bottom: 0.18rem;
    border-radius: 0.9375rem;
    background: var(--caribbean-green);
    box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.10), 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider:before {
    background: #FF584B;
  }

  input:checked+.slider {
    border-radius: 0.9375rem;
    background: rgba(255, 88, 75, 0.16);


  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(1.35rem);
    -ms-transform: translateX(1.35rem);
    transform: translateX(1.35rem);
  }

  /*------ ADDED CSS ---------*/
  .on {
    display: none;
    right: 35%;
  }

  .off {
    left: 65%;
  }

  .on,
  .off {

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    user-select: none;
    color: var(--neutral-01);
    font-family: Poppins;
    font-size: 0.5625rem;
    opacity: 0.3;
    font-weight: 500;

  }

  input:checked+.slider .on {
    display: block;
  }

  input:checked+.slider .off {
    display: none;
  }

  /*--------- END --------*/

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

}

//  Radio Button
.radio-button {
  cursor: pointer;
}

.radio-button__input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.radio-button__input {
  opacity: 0;
  position: absolute;
}

.radio-button__control {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background-color: inherit;
  color: #017b5f;
  border-radius: 50%;
  background-image: url("/public/images/icons/icon-radio-white.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}



.radio-button__input:checked+.radio-button__control {
  background-image: url("/public/images/icons/icon-radio.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

}



.radio-button__control {
  transform: scale(0.75);

}

.radio-button__label {
  color: var(--neutral-01);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

.radio-button__input:checked~.radio-button__label {
  color: var(--caribbean-green);
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}

// Checkboxes // 
.checkbox-wrapper {
  display: inline-block;

  input[type="checkbox"],
  input[type="radio"] {
    height: 0;
    width: 0;
  }

  input[type="checkbox"]+label,
  input[type="radio"]+label {
    position: relative;
    @include flexBox(inline-flex, null, center, null);

    &>span {
      padding: 0;
      @include flexBox(inline-flex, null, center, center);
      margin-right: 0.5rem;
      width: 0.775rem;
      height: 0.775rem;
      border-radius: 0.125rem;
      border: 1px solid var(--neutral-01);
      background-color: white;
      cursor: pointer;
      @include ts(0.3s);

      &::before {
        content: "";
        position: absolute;
        background: url(/public/images/icons/icon-input-checked.svg) no-repeat center;
        border-radius: 0.125rem;
        background-size: 0.28rem;
        width: 0.775rem;
        height: 0.775rem;
        inset: 0;
        opacity: 0;
        @include ts(0.3s);
        transform-origin: 0% 100%;
      }
    }

    &>div {
      padding-top: 1px;
      cursor: pointer;
      @include flexBox(inline-flex, null, center, null);
    }
  }

  input[type="checkbox"],
  input[type="radio"] {

    &+label:hover>span,
    &:focus+label>span {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
      border-color: var(--text-theme);
    }
  }

  input[type="checkbox"]:checked+label {
    &>span {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
      border-color: var(--caribbean-green);

      &:before {
        background-size: 0.775rem;
        opacity: 1;
      }
    }
  }
}


