@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Playfair+Display:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700;800&display=swap");
:root {
  --primary-dark: #000;
  --text-dark: #20272C;
  --text-theme: #135149;
  --text-theme-100: #106456;
  --neutral: #BCBCBC;
  --neutral-01: #63727E;
  --neutral-02: #E5EAEE;
  --neutral-03: #BCBCBC;
  --neutral-04: #BFC4C7;
  --neutral-05: #EAEAEA;
  --neutral-06: #f7f7f7;
  --caribbean-green: #36B8A3;
  --caribbean-light-green: #E3FAF6;
  --mustard: #ED9600;
  --white: #ffffff;
  --success: #20946b;
  --fail: #d90429;
  --pastel-green: #d2f2db;
  --pastel-blue: #d0e1ee;
  --pastel-pink: #efcfe4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: "Poppins", sans-serif;
}

img,
svg,
picture,
video {
  display: block;
  max-width: 100%;
}

input,
select,
textarea,
button {
  font: inherit;
}

.ts02 {
  transition: all 0.2s ease-in-out;
}
.ts03 {
  transition: all 0.3s ease-in-out;
}
.ts04 {
  transition: all 0.4s ease-in-out;
}
.ts05 {
  transition: all 0.5s ease-in-out;
}
.ts06 {
  transition: all 0.6s ease-in-out;
}

.form-control:focus, .form-select:focus {
  outline: 0;
  box-shadow: none;
}

a {
  text-decoration: none;
}

button {
  border: 0;
}
button:focus, button:active {
  outline: 0;
  box-shadow: none;
}

.redText {
  color: var(--fail);
}

.blueText {
  color: #3276E8;
}

.whiteText {
  color: var(--neutral-02);
}

.grayText {
  color: var(--neutral-01);
}

.lightGrey {
  color: #BFC4C7;
}

.primaryText {
  color: var(--text-theme);
}

.primaryDarkText {
  color: var(--text-dark);
}

.greenThemeText {
  color: var(--caribbean-green);
}

.lightgreenThemeText {
  color: var(--caribbean-light-green);
}

.mustardText {
  color: var(--mustard);
}

.cursor-pointer {
  cursor: pointer !important;
}

.bdr-gray {
  border: 1px solid var(--neutral-02);
}

.blur-2 {
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.blur-7 {
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
}

.shadow-34-05 {
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
}

.shadow-15-11-08 {
  box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
}

.bdrs10 {
  border-radius: 0.625rem;
}

.bdrs12 {
  border-radius: 0.75rem;
}

.bg-w-a49 {
  background: rgba(255, 255, 255, 0.49);
}

.bg-lightGrey {
  background: #F4F3F3;
}

.bg-lightPink {
  background: #FFDDDA;
}

.bg-lightGreen {
  background: #E3FAF6;
}

.blurred-widget-block {
  border-radius: 0.9375rem;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
}

/* Chrome/Safari and web-kit-based browsers (if it doesn't work, try maybe also on the parent/wrapper) */
::-webkit-file-upload-button {
  cursor: pointer;
}

/* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
input[type=file] {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 3px;
  z-index: 999999;
  position: relative;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
  margin-block: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: var(--text-dark);
}

/* Handle on hover and active */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: var(--primary-dark);
}

/* End of scrollbar style */
.fz10 {
  font-size: 10px;
}
.fz12 {
  font-size: clamp(0.6875rem, 0.5625rem + 0.1563vw, 0.75rem);
}
.fz14 {
  font-size: clamp(0.775rem, 0.5rem + 0.325vw, 0.875rem);
}
.fz16 {
  font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem);
}
.fz18 {
  font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem);
}
.fz20 {
  font-size: clamp(1rem, 0.5rem + 0.625vw, 1.25rem);
}
.fz22 {
  font-size: clamp(1.05rem, 1rem + 0.3vw, 1.375rem);
}
.fz24 {
  font-size: clamp(1rem, 0.68rem + 0.425vw, 1.5rem);
}
.fz26 {
  font-size: clamp(1.4375rem, 1rem + 0.43vw, 1.625rem);
}
.fz28 {
  font-size: clamp(1.5625rem, 1.1875rem + 0.4688vw, 1.75rem);
}
.fz30 {
  font-size: clamp(1.5rem, 0.75rem + 0.9375vw, 1.875rem);
}
.fz34 {
  font-size: 1.85rem;
}
.fz40 {
  font-size: clamp(2.1rem, 1.2rem + 1.125vw, 2.5rem);
}
.fz46 {
  font-size: clamp(2.375rem, 1.375rem + 1.25vw, 2.875rem);
}
.fz52 {
  font-size: clamp(3rem, 2.5rem + 0.625vw, 3.25rem);
}
.fz60 {
  font-size: clamp(2.65rem, 0.25rem + 3.125vw, 3.75rem);
}
.fz70 {
  font-size: clamp(3rem, 0.125rem + 3.7vw, 4.375rem);
}

.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw800 {
  font-weight: 800;
}

.lh1 {
  line-height: 1;
}
.lh1-2 {
  line-height: 1.2;
}
.lh1-5 {
  line-height: 1.5;
}
.lh1-65 {
  line-height: 1.65;
}

.font-primary {
  font-family: "Poppins", sans-serif;
}

.font-secondary {
  font-family: "Playfair Display", serif;
}

.font-tertiary {
  font-family: "Bebas Neue", sans-serif;
}

.form-group--search, .form-group--email {
  position: relative;
  border-radius: 0.9375rem;
  background: rgba(255, 255, 255, 0.49);
  padding: 0.5rem 0.56rem;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  gap: 1rem;
}
.form-group--search .form-control, .form-group--email .form-control {
  height: auto;
  background: #FFF;
  min-width: 24.5rem;
  padding-left: 3.6rem;
  color: var(--text-theme);
  text-transform: capitalize;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}
@media (max-width: 767.98px) {
  .form-group--search .form-control, .form-group--email .form-control {
    min-width: unset;
  }
}
.form-group--search .form-control::-moz-placeholder, .form-group--email .form-control::-moz-placeholder {
  font-weight: 400;
  color: var(--neutral-01);
}
.form-group--search .form-control::placeholder, .form-group--email .form-control::placeholder {
  font-weight: 400;
  color: var(--neutral-01);
}
.form-group--search .fab, .form-group--email .fab {
  position: absolute;
  left: 0.56rem;
  top: 0.5rem;
  z-index: 1;
}
.form-group--email .form-control {
  text-transform: lowercase;
}
.form-group--email .form-control::-moz-placeholder {
  text-transform: capitalize;
}
.form-group--email .form-control::placeholder {
  text-transform: capitalize;
}
.form-group ul.icons {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 0.65rem;
}
.form-group ul.icons a {
  padding: 0.35rem;
  position: relative;
  width: 1.85rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.form-group ul.icons .dot {
  position: absolute;
  right: 0.2rem;
  top: 0.05rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #FF5E48;
}
.form-group--otp {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
}
.form-group--otp .form-control {
  border-radius: 7px;
  width: 2.8125rem;
  height: 2.8125rem;
  min-width: unset;
  min-height: unset;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}
.form-group--otp .form-control:focus, .form-group--otp .form-control:required:valid {
  color: white;
  background-color: var(--text-dark);
}
.form-control {
  height: 2.8125rem;
  min-height: 2.8125rem;
}
.form-control::-moz-placeholder {
  color: var(--neutral);
}
.form-control::placeholder {
  color: var(--neutral);
}

.dark {
  width: 100%;
  height: 5rem;
  padding: 1rem;
  background-color: black;
}

.dark-options {
  border-radius: 6.25rem;
  background: var(--Heading-Black, #20272C);
  display: inline-flex;
  height: 1.5625rem;
  padding: 0.375rem 0.3125rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  flex-shrink: 0;
}
.dark-options span {
  color: var(--Carriben-Light-Green, #E3FAF6);
  font-family: Poppins;
  font-size: 0.5rem;
  font-weight: 400;
}

.folder {
  position: relative;
  width: 7.75rem;
  height: 6.2rem;
  background: var(--bg);
  border-radius: 0.625rem;
  padding: 0.65rem;
  --bg: #dce2e4;
  transition: 0.2s ease-out;
  z-index: 1;
}

.top {
  background: #D4D4D4;
  position: absolute;
  left: 0;
  top: -12px;
  height: 50px;
  width: 100%;
  -webkit-clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(50% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
  overflow: hidden;
  border-radius: 0.625rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: 0.2s ease-out;
}

.head {
  position: relative;
}

.head .dot2 {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head .dot2:hover {
  background: rgba(255, 255, 255, 0.5921568627);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}

.head .dot2:active {
  background: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.info {
  position: absolute;
  width: 100%;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}
.info span {
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}

.folder-wrapper {
  width: 7.75rem;
}

.total-div {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 500;
}
.total-div span {
  color: var(--Caribbean-Green, #36B8A3);
  margin-left: 0.5rem;
}

.pdf {
  width: 2.5rem;
  height: 1.12rem;
  background-image: url("/public/images/icons/icon-pdf.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jpg {
  width: 2.5rem;
  height: 1.12rem;
  background-image: url("/public/images/icons/icon-jpg.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.docx {
  width: 2.5rem;
  height: 1.12rem;
  background-image: url("/public/images/icons/icon-docx.svg");
  background-size: 100% 100%;
}

.verified-div {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
.verified-div span {
  color: var(--Caribbean-Green, #36B8A3);
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.pending-div {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
.pending-div span {
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.status-div {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
.status-div span {
  color: var(--Heading-Black, #20272C);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
}

.table-actions {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 3px;
  padding-inline: 0.5rem;
}
.table-actions .table-action-innerdiv {
  display: inline-flex;
  align-items: center;
  background: var(--Lighter-Grey, #F3F6F8);
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
}
.table-actions .table-action-innerdiv span {
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
}

.search-input-wrapper {
  width: 29.4rem;
  height: 3.8rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.form-control-search {
  height: 100%;
  border: none;
  border-radius: 0.625rem;
  transition: all 0.3s ease-in-out;
  border-radius: 0.625rem;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  line-height: 100%;
  /* 0.625rem */
  letter-spacing: 0.0375rem;
}

.table-search {
  background: #FFF;
  display: flex;
  align-items: center;
  width: 22.5rem;
  height: 2.8125rem;
  border-radius: 0.625rem;
  color: #BCBCBC;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}
.table-search input {
  padding-left: 3.7rem;
  width: 100%;
}
.table-search input:placeholder {
  color: #BCBCBC;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-fab {
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 0.6rem;
  border-radius: 0.625rem;
  background: inherit;
  height: 100%;
  position: relative;
}

.black-btn {
  border-radius: 12px;
  background: #20272C;
}

.filter-div {
  height: 100%;
  display: flex;
  gap: 1.18rem;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.table-area {
  margin-top: 2.2rem;
  padding: 1rem 0.75rem;
}
.table-area .table-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}
.table-area .table-title-bar__right .btn-holder-dd {
  position: relative;
  padding-inline: 0.75rem 3rem;
  min-width: 12.3125rem;
  height: 2.8125rem;
}
.table-area .table-title-bar__right .btn-holder-dd .fab {
  position: absolute;
  right: 0;
}
.table-area .table-title-bar__right .btn-holder-dd .fab img {
  max-width: 0.8rem;
}
.table-area .table-title-bar__right .btn-holder-dd:hover, .table-area .table-title-bar__right .btn-holder-dd:focus, .table-area .table-title-bar__right .btn-holder-dd:active {
  border: 1px solid var(--neutral-02);
}
.table-area .table-title-bar__right .btn-holder-dd::after {
  display: none;
}
.table-area .table-title-bar__right .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in-out;
}
.table-area .table-title-bar__right .dropdown-menu .dropdown-item:active, .table-area .table-title-bar__right .dropdown-menu .dropdown-item.active, .table-area .table-title-bar__right .dropdown-menu .dropdown-item.active:active {
  color: white;
  background: var(--caribbean-green);
}
.table-area .table-responsive {
  overflow: visible;
}

/* Define a base styling for the table */
.custom-dataTable {
  width: 100%;
  border-collapse: collapse;
  /* Style the td elements within tbody */
}
.custom-dataTable thead tr {
  border-top: 1px solid var(--neutral-02);
  border-bottom: 1px solid var(--neutral-02);
}
.custom-dataTable tbody tr td img {
  display: inline-block;
  margin-right: 0.35rem;
}
.custom-dataTable th,
.custom-dataTable td {
  border: 0;
  padding: 0.6rem;
  text-align: left;
  background: transparent;
  vertical-align: middle;
}
.custom-dataTable th .checkbox-wrapper input[type=checkbox] + label > span,
.custom-dataTable th .checkbox-wrapper input[type=radio] + label > span,
.custom-dataTable td .checkbox-wrapper input[type=checkbox] + label > span,
.custom-dataTable td .checkbox-wrapper input[type=radio] + label > span {
  border-color: var(--caribbean-green);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
}
.custom-dataTable th .select-privacy,
.custom-dataTable td .select-privacy {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
.custom-dataTable th .select-privacy::after,
.custom-dataTable td .select-privacy::after {
  display: none;
}
.custom-dataTable th .select-privacy[aria-expanded=true] .arrow,
.custom-dataTable td .select-privacy[aria-expanded=true] .arrow {
  transform: rotateX(-180deg);
}
.custom-dataTable th > div {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}
.custom-dataTable th > div img {
  max-height: 1rem;
}
.custom-dataTable tbody td {
  font-size: 12px;
  white-space: nowrap;
  font-size: clamp(0.6875rem, 0.5625rem + 0.1563vw, 0.75rem);
}
.custom-dataTable tbody td .grayText02 {
  color: var(--neutral-04);
}
.custom-dataTable tbody .table-remove-green {
  color: var(--caribbean-green);
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.custom-dataTable tbody .verification-status {
  display: inline-flex;
  align-items: center;
  gap: 0.56rem;
}

.dropdown-menu--privacy {
  padding: 0.5rem;
  border: 1px solid #EFEFEF;
  background: rgba(255, 255, 255, 0.77);
}
.dropdown-menu--privacy .blurred-widget-block {
  min-width: 12rem;
}
.dropdown-menu--privacy .blurred-widget-block a {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.35rem;
  color: var(--text-dark);
}
.dropdown-menu--privacy .blurred-widget-block a b {
  width: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.dropdown-menu--privacy .blurred-widget-block a span {
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}
.dropdown-menu--privacy .blurred-widget-block a:hover span {
  font-weight: 600;
}
.dropdown-menu--privacy .blurred-widget-block a.active {
  background: url(/public/images/icons/icon-selected-tickmark.svg) no-repeat center;
  background-position: 92%;
  background-size: 20px;
}
.dropdown-menu--privacy .blurred-widget-block a.active span {
  font-weight: 600;
}
.dropdown-menu--privacy .btn-add-recipient {
  height: 2.8125rem;
}
.dropdown-menu--privacy .form-group--search {
  border-radius: 0.75rem;
  background: #FFF;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}
.dropdown-menu--privacy .form-group--search .fab {
  top: 0;
  left: 0;
}
.dropdown-menu--privacy .form-group--search .form-control {
  padding-left: 3.5rem;
  min-width: unset;
}
.dropdown-menu--privacy ul.info-shared {
  padding: 1rem 0.65rem 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.125rem;
}
.dropdown-menu--privacy ul.info-shared .title {
  display: inline-flex;
  align-items: center;
  gap: 0.35rem;
}

.page-layout {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 2rem 2.37rem 1rem;
  background: url("/public/images/layout-blob-bg.jpg") no-repeat center center fixed;
  background-size: cover;
}
.page-layout .header {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.page-layout .header__left {
  width: 220px;
  flex: 0 0 220px;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  color: var(--neutral-01);
}
.page-layout .header__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex: 1;
}
.page-layout .header__right .form-control {
  padding-left: 4.2rem;
}
.page-layout .header__right .form-control::-moz-placeholder {
  color: var(--neutral-03);
}
.page-layout .header__right .form-control::placeholder {
  color: var(--neutral-03);
}
.page-layout .header__right--with-link {
  padding-inline: 0.56rem;
}
.page-layout .header__right--with-link .form-group--search {
  background: transparent;
  box-shadow: none;
  padding-inline: 0;
}
.page-layout .content-layout {
  padding: 1rem;
}
.page-layout .content-layout__inner {
  display: flex;
  gap: 1.5rem;
}

.user-info {
  display: inline-flex;
  align-items: center;
  gap: 0.81rem;
}
.user-info__dp {
  position: relative;
  background: transparent;
}
.user-info__dp:focus {
  outline: 0;
}
.user-info__dp-inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.5625rem;
  height: 3.5625rem;
  padding: 5px;
  border-radius: 0.9375rem;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}
.user-info__dp img.dp-img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 3.5625rem;
  height: 3.5625rem;
}
.user-info__dp span {
  width: 100%;
  height: 100%;
}
.user-info__dp span.initials {
  background: url(/public/images/user-dp-bg.svg) no-repeat center;
  background-size: cover;
  padding-top: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.user-info__dp b.arrow {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: -0.6rem;
  padding: 0.1rem 0 0.25rem;
  transform: translateX(-50%);
  border-radius: 0 0 3rem 3rem;
  width: 1.6rem;
  height: 0.8rem;
}
.user-info .dropdown-toggle::after {
  display: none;
  margin: 0;
}
.user-info .dropdown-menu {
  margin-top: 0.65rem !important;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}
.user-info .dropdown-menu a {
  color: var(--text-theme);
  padding: 0.6rem 1rem;
}
.user-info__name {
  padding-top: 0.65rem;
  display: inline-flex;
  flex-flow: column nowrap;
}
.user-info__name h6 {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}
.user-info__name h6 img {
  margin: -0.8rem -0.35rem;
}
.user-info__name p {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
}
.user-info__name p span {
  line-height: 1;
}

.link-to-previous {
  height: 2.8125rem;
  display: flex;
  min-width: 12.4375rem;
  background-color: var(--text-dark);
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
  display: inline-flex;
  align-items: center;
}
.link-to-previous .icon-wrap {
  height: 100%;
  width: 2.86825rem;
  background-color: var(--caribbean-green);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.link-to-previous span {
  padding-inline: 0.575rem;
}
.link-to-previous:hover .icon-wrap img, .link-to-previous:focus .icon-wrap img, .link-to-previous:active .icon-wrap img {
  scale: 1.1;
}

aside {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.2rem;
  margin-top: 0.85rem;
  width: 13.75rem;
  flex: 0 0 13.75rem;
  position: sticky;
  top: -0.8rem;
  max-height: calc(100vh - 7.2rem);
}
aside nav.navigation {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.1rem;
  padding: 1rem 0.75rem;
}
aside nav.navigation .nav-links {
  padding: 1rem 0.8rem 1rem 1.125rem;
  position: relative;
  display: flex;
}
aside nav.navigation .nav-links::after {
  content: "";
  position: absolute;
  inset: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 0.625rem;
  background-position: 135%;
}
aside nav.navigation .nav-links__inner {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  position: relative;
  z-index: 2;
}
aside nav.navigation .nav-links span {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
aside nav.navigation .nav-links span img {
  scale: 1;
  transition: all 0.3s ease-in-out;
}
aside nav.navigation .nav-links strong {
  font-weight: 400;
  color: var(--text-theme);
}
aside nav.navigation .nav-links--active::after {
  background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat right center;
  background-color: #FFF;
  background-position: 120%;
  box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.03);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 1;
}
aside nav.navigation .nav-links--active span img {
  scale: 1.2;
}
aside nav.navigation .nav-links--active strong {
  font-weight: 500;
}
aside .green-theme-block {
  width: 100%;
  min-height: 15.875rem;
  background: url(/public/images/bg-particles/below-aside-navigation-theme-block-bg.svg) no-repeat center;
  background-size: contain;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
aside .green-theme-block h2 {
  font-size: 1rem;
}
aside .green-theme-block p {
  color: var(--neutral-01);
  text-align: center;
  padding: 0 1.5rem;
  font-size: 10px;
}
aside .green-theme-block .btn {
  width: 9.5625rem;
  height: 2.625rem;
  margin-block: 0.4rem 0.8rem;
}
aside .green-theme-block .btn span {
  margin-top: 1px;
}
aside.no-nav-feature-block {
  width: 17.1875rem;
  flex: 0 0 17.1875rem;
  position: static;
}

.auth-layout {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  background: url("/public/images/auth-blob-bg.jpg") no-repeat center center fixed;
  background-size: cover;
}
.auth-layout__left {
  max-width: 45%;
  flex: 0 0 45%;
}
.auth-layout__right {
  max-width: 55%;
  flex: 0 0 55%;
  border: 3px solid #FFF;
  background: rgba(255, 255, 255, 0.68);
  box-shadow: -8px -14px 45px 0px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
}

@media (max-width: 991.98px) {
  .auth-layout {
    background-image: url("/public/images/bg-auth-form-screen.svg");
  }
  .auth-layout__left {
    flex: 1;
    max-width: 100%;
  }
  .auth-layout__right {
    display: none;
  }
  .auth-layout--splash {
    overflow-y: auto;
    background: url("/public/images/bg-splash-screen.svg") no-repeat center center fixed;
    background-size: cover;
  }
  .auth-layout--splash .container {
    height: 100vh;
    padding-inline: 1.5rem;
    display: flex;
    flex-flow: column nowrap;
  }
  .auth-layout--splash .tnc-pp-links {
    width: 100%;
    margin-block: 1.125rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .auth-layout--splash .logo {
    max-width: 7.896rem;
    margin: 2.25rem auto;
  }
  .auth-layout--splash .splash-inner {
    flex: 1;
    padding-bottom: min(12vh, 8rem);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
  .auth-layout--splash .sub-help-info {
    padding-left: 0;
    width: 100%;
  }
  .auth-layout--splash .to-auth-btn {
    position: absolute;
    bottom: 6.85rem;
    right: 1.5rem;
    width: 53px;
    height: 53px;
    border-radius: 8px;
    padding: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .auth-layout--splash .to-auth-btn span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
}
.fab {
  width: 2.86825rem;
  height: 2.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.fab--search {
  background: var(--text-dark);
}
.fab--search img {
  width: 1.5rem;
  height: auto;
}
.fab--dark {
  background-color: var(--text-dark);
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-solid:focus, .btn-solid:focus-visible {
  outline: 0;
}
.btn-solid--green {
  color: var(--text-dark);
  letter-spacing: 0.0375rem;
  background-color: var(--caribbean-green);
}
.btn-solid--green:hover, .btn-solid--green:focus, .btn-solid--green:active {
  color: var(--text-dark);
  background-color: var(--caribbean-green);
}
.btn-solid--dark {
  letter-spacing: 0.0375rem;
  background-color: var(--text-dark);
  color: var(--caribbean-light-green);
}
.btn-solid--dark:hover, .btn-solid--dark:focus, .btn-solid--dark:active {
  color: var(--caribbean-light-green);
  background-color: var(--text-dark);
}
.btn-solid--lightgrey {
  color: var(--text-dark);
  letter-spacing: 0.0375rem;
  border: 1px solid var(--neutral-05);
  background-color: var(--neutral-02);
  height: 45px;
}
.btn-solid--lightgrey:hover, .btn-solid--lightgrey:focus, .btn-solid--lightgrey:active {
  color: var(--text-dark);
  border-color: var(--neutral-05);
  background-color: var(--neutral-02);
  box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
}

.upload-docs {
  position: relative;
  height: 2.8125rem;
  display: flex;
  align-items: center;
  padding-inline: 2.5rem 1rem;
  border-radius: 10px;
  /* Chrome/Safari and web-kit-based browsers (if it doesn't work, try maybe also on the parent/wrapper) */
  /* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
}
.upload-docs input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  inset: 0;
}
.upload-docs .btn {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 2.8125rem;
  height: 100%;
  box-shadow: 2px 8px 31px 0px rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.upload-docs span {
  flex: 1;
  padding-top: 3px;
  text-align: center;
  letter-spacing: 0.0375rem;
}
.upload-docs img {
  pointer-events: none;
}
.upload-docs ::-webkit-file-upload-button {
  cursor: pointer;
}
.upload-docs input[type=file] {
  cursor: pointer;
}

.upload-photo-comp {
  height: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.13rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}

.p-instructions {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;
  padding-right: 0.8rem;
}
.p-instructions__format {
  height: 1.1rem;
  border-radius: 0.24rem;
  padding: 0 0.2rem;
  padding-top: 1px;
  min-width: 2.375rem;
  letter-spacing: 0.45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.p-instructions__format--png {
  background-color: #FF7069;
}
.p-instructions__format--jpg {
  background-color: #00C650;
}
.p-instructions__format--docx {
  background-color: #0072FF;
}
.p-instructions__format--pdf {
  background-color: #FF7069;
}
.p-instructions__format--csv {
  background-color: #36B8A3;
}
.p-instructions__format--gif {
  background-color: #FF9908;
}
.p-instructions__format--10mb {
  color: var(--text-dark);
  background-color: white;
  border: 1px solid rgba(34, 34, 34, 0.1);
}

.upload-photo-btn {
  position: relative;
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  /* Chrome/Safari and web-kit-based browsers (if it doesn't work, try maybe also on the parent/wrapper) */
  /* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
}
.upload-photo-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  inset: 0;
}
.upload-photo-btn .btn {
  padding: 0.15rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.4375rem;
  background-color: #F3F6F8;
}
.upload-photo-btn .btn img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -o-object-fit: cover;
     object-fit: cover;
}
.upload-photo-btn span {
  flex: 1;
  padding-top: 3px;
  padding-inline: 0.75rem;
  text-align: center;
}
.upload-photo-btn ::-webkit-file-upload-button {
  cursor: pointer;
}
.upload-photo-btn input[type=file] {
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2.8125rem;
  height: 1.375rem;
  border-radius: 0.9375rem;
  /*------ ADDED CSS ---------*/
  /*--------- END --------*/
  /* Rounded sliders */
}
.switch input {
  display: none;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--Carriben-Light-Green, #E3FAF6);
  box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.1);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  transition: 0.4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  left: 0.18rem;
  bottom: 0.18rem;
  border-radius: 0.9375rem;
  background: var(--caribbean-green);
  box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.1), 0px 4px 4px 0px rgba(255, 255, 255, 0.2) inset;
  transition: 0.4s;
}
.switch input:checked + .slider:before {
  background: #FF584B;
}
.switch input:checked + .slider {
  border-radius: 0.9375rem;
  background: rgba(255, 88, 75, 0.16);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.switch input:checked + .slider:before {
  transform: translateX(1.35rem);
}
.switch .on {
  display: none;
  right: 35%;
}
.switch .off {
  left: 65%;
}
.switch .on,
.switch .off {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: var(--neutral-01);
  font-family: Poppins;
  font-size: 0.5625rem;
  opacity: 0.3;
  font-weight: 500;
}
.switch input:checked + .slider .on {
  display: block;
}
.switch input:checked + .slider .off {
  display: none;
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}

.radio-button {
  cursor: pointer;
}

.radio-button__input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.radio-button__input {
  opacity: 0;
  position: absolute;
}

.radio-button__control {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background-color: inherit;
  color: #017b5f;
  border-radius: 50%;
  background-image: url("/public/images/icons/icon-radio-white.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.radio-button__input:checked + .radio-button__control {
  background-image: url("/public/images/icons/icon-radio.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.radio-button__control {
  transform: scale(0.75);
}

.radio-button__label {
  color: var(--neutral-01);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

.radio-button__input:checked ~ .radio-button__label {
  color: var(--caribbean-green);
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}

.checkbox-wrapper {
  display: inline-block;
}
.checkbox-wrapper input[type=checkbox],
.checkbox-wrapper input[type=radio] {
  height: 0;
  width: 0;
}
.checkbox-wrapper input[type=checkbox] + label,
.checkbox-wrapper input[type=radio] + label {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.checkbox-wrapper input[type=checkbox] + label > span,
.checkbox-wrapper input[type=radio] + label > span {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  width: 0.775rem;
  height: 0.775rem;
  border-radius: 0.125rem;
  border: 1px solid var(--neutral-01);
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.checkbox-wrapper input[type=checkbox] + label > span::before,
.checkbox-wrapper input[type=radio] + label > span::before {
  content: "";
  position: absolute;
  background: url(/public/images/icons/icon-input-checked.svg) no-repeat center;
  border-radius: 0.125rem;
  background-size: 0.28rem;
  width: 0.775rem;
  height: 0.775rem;
  inset: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform-origin: 0% 100%;
}
.checkbox-wrapper input[type=checkbox] + label > div,
.checkbox-wrapper input[type=radio] + label > div {
  padding-top: 1px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.checkbox-wrapper input[type=checkbox] + label:hover > span, .checkbox-wrapper input[type=checkbox]:focus + label > span,
.checkbox-wrapper input[type=radio] + label:hover > span,
.checkbox-wrapper input[type=radio]:focus + label > span {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
  border-color: var(--text-theme);
}
.checkbox-wrapper input[type=checkbox]:checked + label > span {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  border-color: var(--caribbean-green);
}
.checkbox-wrapper input[type=checkbox]:checked + label > span:before {
  background-size: 0.775rem;
  opacity: 1;
}

.auth-form-area {
  padding: 3.66rem 3rem 2rem;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
@media (max-width: 1450px), (max-height: 760px) {
  .auth-form-area {
    padding: 2.2rem 2.2rem 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .auth-form-area {
    padding-inline: 1.8rem;
  }
}
.auth-form-area .logo {
  max-width: 7.896rem;
}
@media (max-width: 767.98px) {
  .auth-form-area .logo {
    margin: auto;
  }
}
.auth-form-area form {
  flex: 1;
  margin-top: 12vh;
  max-width: 26rem;
  margin-inline: auto;
  display: flex;
  flex-flow: column nowrap;
}
@media (max-width: 767.98px) {
  .auth-form-area form {
    margin-top: 18vh;
    max-width: 100%;
    width: 100%;
  }
}
.auth-form-area form .form-group--search .form-control {
  min-width: 20rem;
}
@media (max-width: 767.98px) {
  .auth-form-area form .form-group--search .form-control {
    min-width: unset;
  }
}
@media (max-width: 767.98px) {
  .auth-form-area form .form-group--otp {
    position: relative;
    border-radius: 0.9375rem;
    background: rgba(255, 255, 255, 0.49);
    padding: 0.5rem 0.56rem;
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
  }
}
.auth-form-area form .checkbox-wrapper {
  margin-block: 0.938rem 1.2rem;
  padding-left: 0.4rem;
}
@media (max-width: 767.98px) {
  .auth-form-area form .checkbox-wrapper {
    margin-block: 1.2rem 1.5rem;
  }
}
.auth-form-area form em {
  margin-block: 0.938rem 1.2rem;
  padding-left: 0.6rem;
}
@media (max-width: 767.98px) {
  .auth-form-area form em {
    padding-left: 0;
    font-size: 12px;
    font-style: normal;
  }
  .auth-form-area form em strong {
    color: var(--text-dark);
  }
}
.auth-form-area form .btn {
  height: 2.8125rem;
  border-radius: 0.375rem;
}
.auth-form-area form .or {
  margin: 1.2rem auto 0.65rem;
}
.auth-form-area form .alternative {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-form-area .tnc-pp-links {
  width: 17rem;
  margin: 10vh auto 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  .auth-form-area .tnc-pp-links {
    margin-top: 1rem;
  }
}
.auth-form-area .tnc-pp-links a:hover, .auth-form-area .tnc-pp-links a:focus, .auth-form-area .tnc-pp-links a:active {
  color: var(--caribbean-green);
}

.auth-features {
  padding: 3.66rem 3.5rem 1.5rem 3.2rem;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
@media (max-width: 1450px), (max-height: 760px) {
  .auth-features {
    padding: 2rem 2rem 0.5rem 2.5rem;
  }
}
.auth-features__top {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .auth-features__top {
    padding-bottom: 1rem;
  }
}
@media (max-width: 767.98px) {
  .auth-features__top {
    max-width: calc(100% - 1.5rem);
  }
}
.auth-features__bottom ul {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auth-features__bottom ul .amazon-logo {
  margin-bottom: -0.5rem;
}
@media (max-width: 767.98px) {
  .auth-features__bottom ul img {
    max-height: 0.65rem;
  }
  .auth-features__bottom ul img[alt=logo-sony] {
    max-height: 0.4rem;
  }
  .auth-features__bottom ul img[alt=logo-disnep] {
    max-height: 0.8rem;
  }
  .auth-features__bottom ul img[alt=logo-instacart] {
    max-height: 0.5rem;
  }
}

.masked-text {
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;
  font-size: 3.5rem;
  padding-bottom: 4.5rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text {
    font-size: 3.215rem;
  }
}
.masked-text .greenThemeText {
  line-height: 1.2;
  letter-spacing: 0.15rem;
}
.masked-text .auth-grad-text {
  text-align: left;
  line-height: 1.2;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
  margin-top: -0.5rem;
}
.masked-text .dark-theme-mix-text {
  background-image: linear-gradient(90deg, #000 44%, #36B8A3 44%);
}
.masked-text .dark-white-mix-text {
  background-image: linear-gradient(90deg, #000 44%, #fff 44%);
}
.masked-text .above-img-text {
  position: absolute;
  z-index: 1;
  top: 18.125rem;
  right: 0;
  line-height: 1.2;
  color: var(--caribbean-light-green);
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text .above-img-text {
    top: 16.5rem;
  }
}
.masked-text .link-to-home {
  position: absolute;
  right: -5.35rem;
  height: 1rem;
  rotate: -90deg;
  top: 16.5rem;
  color: var(--primary-dark);
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text .link-to-home {
    right: -5.2rem;
    top: 15rem;
  }
}
.masked-text .main-bg {
  width: 216px;
  position: absolute;
  right: -0.885rem;
  top: 4rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text .main-bg {
    width: 200px;
    top: 3.5rem;
  }
}
.masked-text .confetti {
  max-height: 1.2rem;
  position: absolute;
}
.masked-text .confetti.cross-black {
  left: -1.25rem;
  top: 0.5rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text .confetti.cross-black {
    top: 0;
  }
}
@media (max-width: 767.98px) {
  .masked-text .confetti.cross-black {
    top: unset;
    bottom: -2.35rem;
    left: 0.15rem;
  }
}
.masked-text .confetti.cross-green {
  right: -2.5rem;
  top: 3.35rem;
}
@media (max-width: 767.98px) {
  .masked-text .confetti.cross-green {
    right: -1.8rem;
    top: 0;
  }
}
.masked-text .confetti.rhombus-red {
  left: -1.2rem;
  top: 16rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text .confetti.rhombus-red {
    top: 14.5rem;
  }
}
@media (max-width: 767.98px) {
  .masked-text .confetti.rhombus-red {
    left: 0rem;
    top: 16.5rem;
  }
}
.masked-text .confetti.rhombus-black {
  right: 6.55rem;
  top: 4.85rem;
  z-index: 2;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text .confetti.rhombus-black {
    right: 5.9rem;
    top: 4.5rem;
  }
}
.masked-text .confetti.wave-red-long {
  right: -2.8rem;
  top: 0.65rem;
}
@media (max-width: 767.98px) {
  .masked-text .confetti.wave-red-long {
    top: 20rem;
    right: -2rem;
  }
}
.masked-text .confetti.wave-red-short {
  top: 18rem;
  left: 7rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text .confetti.wave-red-short {
    top: 16.5rem;
    left: 6rem;
  }
}
@media (max-width: 767.98px) {
  .masked-text .confetti.wave-red-short {
    right: 0.25rem;
    top: 0.1rem;
    left: unset;
  }
}
.masked-text .confetti.line-black-short {
  top: 16.5rem;
  left: 4.15rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text .confetti.line-black-short {
    top: 15rem;
  }
}
.masked-text--signup {
  font-size: 3.85rem;
  padding-right: 1.9rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text--signup {
    font-size: 3.215rem;
    padding-right: 2.35rem;
  }
}
.masked-text--signup .greenThemeText {
  line-height: 1.15;
}
.masked-text--signup .auth-grad-text {
  line-height: 1.15;
}
.masked-text--signup .dark-theme-mix-text {
  background-image: linear-gradient(90deg, #000 46.2%, #36B8A3 46.2%);
}
.masked-text--signup .dark-white-mix-text {
  background-image: linear-gradient(90deg, #000 46.2%, #fff 46.2%);
}
.masked-text--signup .main-bg {
  top: 4rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text--signup .main-bg {
    top: 3.45rem;
  }
}
.masked-text--signup .confetti.rhombus-red {
  top: 17rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text--signup .confetti.rhombus-red {
    top: 14.5rem;
  }
}
.masked-text--signup .confetti.line-black-short {
  top: 17.5rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text--signup .confetti.line-black-short {
    top: 15rem;
  }
}
.masked-text--signup .confetti.rhombus-black {
  right: 5.65rem;
  top: 3.2rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text--signup .confetti.rhombus-black {
    right: 5.4rem;
    top: 2.6rem;
  }
}
@media (max-width: 1450px), (max-height: 760px) {
  .masked-text--signup .confetti.wave-red-short {
    left: 4.85rem;
  }
}

.sub-help-info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 27rem;
  width: 100%;
  margin-top: 4rem;
  padding-left: 2.5rem;
  font-size: 9.5px;
}
@media (max-width: 1450px), (max-height: 760px) {
  .sub-help-info {
    max-width: 26rem;
    font-size: 9.1px;
  }
}
@media (max-width: 991.98px) {
  .sub-help-info {
    padding-left: 1.5rem;
    font-size: 12px;
    color: #63727E;
    margin-block: 2rem 1rem;
  }
  .sub-help-info span {
    opacity: 0.8;
  }
}
.sub-help-info > div {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  text-align: right;
}
@media (max-width: 991.98px) {
  .sub-help-info > div {
    max-width: 45%;
    flex: 0 0 45%;
  }
}
.sub-help-info .top-red-bd-text {
  position: relative;
}
.sub-help-info .top-red-bd-text::after {
  content: "";
  position: absolute;
  top: -1.125rem;
  left: 0;
  right: 0;
  height: 0.35581rem;
  background: linear-gradient(270deg, rgba(255, 113, 105, 0.28) 7.29%, #FF7169 43.75%, rgba(255, 113, 105, 0.21) 84.9%);
}
.sub-help-info .top-green-dark-bd-text {
  font-size: 1.25rem;
  color: #106456;
  padding: 0 0.25rem;
  position: relative;
  padding-bottom: 2px;
}
.sub-help-info .top-green-dark-bd-text::after {
  content: "";
  position: absolute;
  top: -0.6rem;
  right: 1.25%;
  width: 85%;
  border-top: 1px solid #106456;
}
@media (max-width: 991.98px) {
  .sub-help-info .top-green-dark-bd-text {
    display: none;
  }
}

.theme-feature-widget {
  border-radius: 0.938rem;
  background: url(/public/images/bg-particles/bg-icons-reduced-opacity.svg) no-repeat center;
  background-color: var(--text-dark);
  display: flex;
  flex-flow: column nowrap;
  padding: 0.5rem 1.25rem 1.75rem;
  gap: 0.65rem;
}
.theme-feature-widget .right-out-canvas {
  width: 111%;
  max-width: unset;
  margin-top: -1rem;
  margin-right: -2rem;
}
.theme-feature-widget > p {
  opacity: 0.4;
  margin-block: 0.15rem 0.5rem;
  color: var(--caribbean-light-green);
}

ul.avatars {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.31rem;
}
ul.avatars img {
  width: 1.5625rem;
  height: 1.5625rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.light-green-theme-block {
  border-radius: 0.938rem;
  background: url(/public/images/bg-particles/light-green-theme-bg.svg) no-repeat center;
  background-color: #ACE1D9;
  padding: 1.8rem 1.25rem 1.75rem;
}
.light-green-theme-block__inner {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
}
.light-green-theme-block__inner h4 {
  line-height: 1.4;
}
.light-green-theme-block__inner .upload-docs {
  padding-left: 3.88rem;
}
.light-green-theme-block__inner .upload-docs .btn {
  background-color: #106456;
}
.light-green-theme-block__inner .upload-docs span {
  color: #106456;
}

.access-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: var(--text-dark);
  min-height: 4.4375rem;
}
.access-block .access-ques {
  padding: 0.8rem;
  max-width: min(60%, 33rem);
  flex: 0 0 min(60%, 33rem);
  text-align: center;
  background: url(/public/images/bg-particles/aside-active-bg-reverted.svg) no-repeat left;
  background-position-x: -7%;
}
.access-block .access-ques::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat right;
  background-position-x: 2%;
}
.access-block .upload-docs {
  max-width: 19.5rem;
  padding-left: 3.65rem;
  margin-right: 0.8rem;
}

.preview-blocks {
  display: flex;
  gap: 1.45rem;
  margin-top: 1.5rem;
}
.preview-blocks > div {
  flex: 1;
  padding: 12px;
  border-radius: 10px;
}
.preview-blocks__left {
  padding: 12px;
}
.preview-blocks .preview-title-bar {
  padding-inline: 1rem;
  height: 3.375rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat right center;
  background-color: var(--caribbean-light-green);
  background-position: 105%;
}
.preview-blocks .preview-info {
  display: flex;
  flex-flow: column wrap;
  gap: 1.2vh 2vw;
  margin: 1.8rem 1rem 2.2rem;
  max-height: 20rem;
}
.preview-blocks .preview-info > li {
  min-height: 4rem;
}
.preview-blocks .preview-info > li:first-child, .preview-blocks .preview-info > li:nth-child(2), .preview-blocks .preview-info > li:nth-child(3), .preview-blocks .preview-info > li:nth-child(4) {
  flex: 1;
}
.preview-blocks .preview-info .label {
  color: var(--text-theme-100);
  letter-spacing: 0.0375rem;
}
.preview-blocks .preview-info .value--masked {
  display: inline-flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  gap: 0.25rem;
}
.preview-blocks .preview-info .value--masked .btn {
  padding: 0.25rem;
}
.preview-blocks .preview-info .value--tags {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}
.preview-blocks .preview-info .value--tags .tag-type {
  min-height: 1.5rem;
  border-radius: 0.3125rem;
  background-color: var(--text-dark);
  padding-inline: 0.4rem;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.preview-blocks .preview-info .value--withImg {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
.preview-blocks .preview-info .value--withImg img {
  flex-shrink: 0;
}
.preview-blocks .preview-info .value--withImg span {
  max-width: min(12vw, 10rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.preview-blocks .preview-info--member {
  max-height: 14rem;
  margin: 1.8rem 1rem 3rem;
}
.preview-blocks .upload-docs {
  margin-top: auto;
  margin-left: 1rem;
  padding-inline: 3.75rem 1.5rem;
  background-color: var(--caribbean-light-green);
}
.preview-blocks .upload-docs span {
  color: var(--text-dark);
}

.flag-dd {
  display: inline-flex;
  align-items: center;
  gap: 0.81rem;
  height: 100%;
  padding-left: 6px;
}
.flag-dd__ff {
  position: relative;
  background: transparent;
}
.flag-dd__ff:focus {
  outline: 0;
}
.flag-dd__ff-inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3.00756rem;
  padding: 5px;
  border-radius: 6px;
  background-color: var(--caribbean-light-green);
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}
.flag-dd__ff-inner img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -o-object-fit: cover;
     object-fit: cover;
}
.flag-dd__ff b.arrow {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  right: -0.8rem;
  padding: 0.1rem 0 0.1rem 0.12rem;
  transform: translateY(-50%);
  border-radius: 0 3rem 3rem 0;
  width: 0.8rem;
  height: 1.6rem;
  background-color: var(--caribbean-light-green);
}
.flag-dd__ff b.arrow img {
  width: 0.4rem;
}
.flag-dd .dropdown-toggle::after {
  display: none;
  margin: 0;
}
.flag-dd .dropdown-menu {
  margin-top: 0.275rem !important;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}
.flag-dd .dropdown-menu a {
  color: var(--text-theme);
  padding: 0.6rem 1rem;
}
.flag-dd__name {
  padding-top: 0.65rem;
}
.flag-dd__name p {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
}

.grid-side-section {
  display: flex;
  padding-block: 1.75rem;
}
.grid-side-section__left {
  padding: 0 2.4rem;
  flex: 1;
  display: inline-flex;
  flex-flow: column nowrap;
}
.grid-side-section__left .title-row {
  margin-inline: auto;
  display: inline-block;
}
.grid-side-section__left .title-row .step-num-label {
  letter-spacing: 0.25rem;
  text-align: left;
}
.grid-side-section__left > p {
  max-width: min(35vw, 30rem);
  margin-inline: auto;
}
.grid-side-section__right {
  flex: 0 0 20.75rem;
  max-width: 20.75rem;
  padding: 0 1.5rem 0 1.25rem;
}

.radial-divider {
  width: 1px;
  height: 100%;
  background: radial-gradient(#e6e6e6, transparent 70%);
}

.right-steps {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}
.right-steps .steps {
  padding: 0.65rem;
}
.right-steps .steps__clickable {
  background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat center;
  background-position: 113% 24%;
  background-color: var(--caribbean-light-green);
  box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.03);
  padding: 1rem 0.94rem 1.2rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;
}
.right-steps .steps__clickable .num {
  letter-spacing: 0.25rem;
}
.right-steps .steps__clickable > h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right-steps .steps__clickable > h6 img {
  margin-block: -0.2rem;
}
.right-steps .steps > p {
  padding: 0.65rem 1rem 0.25rem;
}
.right-steps .steps--done .steps__clickable {
  background-image: none;
}
.right-steps .steps--current .steps__clickable {
  background-color: var(--text-dark);
}
.right-steps .steps--current .steps__clickable .num {
  color: #FF7169;
}
.right-steps .steps--current .steps__clickable h6 {
  color: var(--caribbean-light-green);
}
.right-steps .steps--current .steps__clickable h6 img {
  display: none;
}
.right-steps .steps--upcoming .steps__clickable h6 img {
  display: none;
}
.right-steps .btn-solid--dark {
  height: 2.375rem;
}

.dashboard-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.15rem;
}

.line-ch-row {
  display: flex;
  gap: 1.5rem;
}
.line-ch-row__chart-box {
  flex: 1;
  padding: 1.4rem 1.5rem 1.2rem;
  display: flex;
  flex-flow: column nowrap;
}
.line-ch-row__chart-box .top-left,
.line-ch-row__chart-box .bottom-right {
  position: absolute;
}
.line-ch-row__chart-box .top-left {
  top: 0.5rem;
  left: -1rem;
}
.line-ch-row__chart-box .bottom-right {
  bottom: 0.85rem;
  right: -1rem;
}
.line-ch-row__chart-box .double-line-chart {
  flex: 1;
  margin-right: -0.5rem;
  width: calc(100% + 0.5rem);
  max-width: calc(100% + 0.5rem);
  max-height: 20rem;
}
.line-ch-row .theme-feature-widget {
  flex: 0 0 17.1875rem;
  max-width: 17.1875rem;
  background-position: 50% 5%;
  background-size: 122%;
}
.line-ch-row .sparkline-line-charts {
  display: flex;
  flex: 1;
  gap: 0.5rem;
  margin-top: 2rem;
}
.line-ch-row .sparkline-line-charts .chart-area {
  max-width: 100%;
  flex: 1;
}
.line-ch-row .sparkline-line-charts .sparks-box {
  flex: 0 0 14.5625rem;
  width: 14.5625rem;
  padding: 1.8rem 1.25rem;
  margin-bottom: 0.35rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .line-ch-row .sparkline-line-charts .sparks-box {
    flex: 0 0 10.5625rem;
    width: 10.5625rem;
  }
}
.line-ch-row .sparkline-line-charts .sparks-box ul {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
  gap: 1rem;
}
.line-ch-row .background-none {
  background: none;
}

.s-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}
.s-stats__left h4 {
  color: #4E5969;
}
.s-stats__right {
  flex: 1;
  max-width: 60%;
  max-height: 2.5rem;
  min-height: 2.5rem;
}
@media (max-width: 1450px), (max-height: 760px) {
  .s-stats__right {
    max-width: 55%;
    max-height: 2.5rem;
    min-height: 2.5rem;
    margin-right: -0.35rem;
  }
}

.widget-title-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.widget-title-top__right {
  display: inline-flex;
  align-items: center;
  gap: 1.2rem;
}
.widget-title-top__right .anchor-tag-list {
  display: inline-flex;
  align-items: center;
  gap: 0.38rem;
}
.widget-title-top__right .anchor-tag-list .lightgreenThemeText {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.45rem;
  height: 1.5625rem;
  gap: 0.25rem;
  font-size: 8px;
  border-radius: 6.25rem;
  background-color: var(--text-dark);
}
.widget-title-top__right .filter-reverse .dot {
  top: -0.3rem;
  right: -0.55rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #FF5E48;
}

.chart-stats {
  display: flex;
  gap: 1rem;
}
.chart-stats .num {
  flex: 0 0 48%;
  min-width: 9rem;
  display: inline-flex;
  flex-flow: column nowrap;
  margin-block: 1rem 2rem;
}
.chart-stats .num span {
  position: relative;
  padding-top: 0.55rem;
  width: -moz-max-content;
  width: max-content;
}
.chart-stats .num span::after {
  content: "";
  position: absolute;
  width: 94%;
  top: 0;
  left: 0;
  border-top: 1px solid #FF5E48;
}
.chart-stats .num--red span {
  color: #FF5E48;
}
.chart-stats .num--green span::after {
  border-color: var(--caribbean-green);
}

ul.widget-stats {
  display: flex;
  justify-content: space-between;
  gap: min(2.5vw, 1.5rem);
}
ul.widget-stats li {
  flex: 1;
  max-width: 12rem;
}
ul.widget-stats .stats {
  height: 6.4375rem;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-inline: min(6vw, 2.2rem) 1rem;
}
ul.widget-stats .stats--yellow {
  background: url(/public/images/bg-particles/yellow-side-lines.svg) no-repeat left;
  background-color: #FFEBBD;
}
ul.widget-stats .stats--green {
  background: url(/public/images/bg-particles/green-dotted-circles.svg) no-repeat left;
  background-color: rgba(54, 184, 163, 0.41);
  background-position: -20%;
}
ul.widget-stats .stats--blue {
  background: url(/public/images/bg-particles/blue-cross-lines.svg) no-repeat left;
  background-color: #D3F4FF;
  background-position: 7% 85%;
}
ul.widget-stats .stats--gray {
  background: url(/public/images/bg-particles/gray-circle-lines.svg) no-repeat left top;
  background-color: var(--neutral-02);
}
ul.widget-stats .stats--peach {
  background: url(/public/images/bg-particles/peach-asterisk-lines.svg) no-repeat left;
  background-color: rgba(255, 186, 176, 0.51);
}

.api-docs-row {
  display: flex;
  gap: 1rem;
}
.api-docs-row__left {
  padding: 1.8rem 1.4rem;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 2rem;
  flex: 0 0 17.8125rem;
  max-width: 17.8125rem;
}
.api-docs-row__left .doughnut-chart {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 9.48931rem;
  height: 9.48931rem;
  background-color: var(--caribbean-light-green);
  box-shadow: inset 0px 0px 9.335px 0px rgba(0, 0, 0, 0.1), 0px 11.41px 49.789px rgba(54, 184, 163, 0.47);
}
.api-docs-row__left .doughnut-chart .bg-white {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 10%;
  padding: 1.125rem;
}
.api-docs-row__left .doughnut-chart .marker-dot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  position: absolute;
  top: 0.125rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 2.2rem;
  height: 2.2rem;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2.6px);
          backdrop-filter: blur(2.6px);
}
.api-docs-row__left .doughnut-chart .marker-dot span {
  width: 100%;
  height: 100%;
  background-color: var(--caribbean-green);
}
.api-docs-row__left .doughnut-chart #apiDoughnut {
  position: relative;
  z-index: 1;
  margin-top: -0.55rem;
  width: 8.15rem !important;
  height: 8.15rem !important;
}
.api-docs-row__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1.125rem 2rem;
}
.api-docs-row__list .api-info .square {
  width: 2.0625rem;
  height: 2.0625rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.api-docs-row__list .api-info .square--gray {
  background-color: var(--neutral-01);
}
.api-docs-row__list .api-info .square--green {
  background-color: var(--caribbean-green);
}
.api-docs-row__list .api-info .square--mustard {
  background-color: #ED9600;
}
.api-docs-row__right {
  flex: 1;
}
.api-docs-row__right .widget-title-top {
  padding: 1.8rem 1.4rem 1.2rem;
}

.table-status-area {
  margin-right: 0.1rem;
  padding: 0 1.3rem 0 1.4rem;
  max-height: 19rem;
  overflow-y: auto;
}

.doc-listing-widget .widget-title-top {
  padding: 0.8rem 1.75rem 0.69rem;
}
.doc-listing-widget .table-area {
  margin: 0;
  padding: 0 1.6rem 1rem;
}

.dropdown-menu--modal .dropdown-item {
  color: var(--text-theme);
  padding: 0.6rem 1rem;
}
.dropdown-menu--modal .dropdown-item:hover, .dropdown-menu--modal .dropdown-item:focus {
  background-color: var(--caribbean-light-green);
}
.dropdown-menu--modal .dropdown-item:active, .dropdown-menu--modal .dropdown-item.active {
  color: white;
  background-color: var(--caribbean-green);
}

.msg-text-area {
  margin-top: 0.85rem;
  border-radius: 0.9375rem;
  border: 1px solid #EAEBEB;
  padding: 1rem 0.75rem 0.75rem;
}
.msg-text-area .title-msg {
  margin-bottom: 0.938rem;
}
.msg-text-area textarea {
  border: 0;
  height: 9.75rem;
  border-top: 1px solid var(--neutral-02);
  border-bottom: 1px solid var(--neutral-02);
  padding: 0.75rem 0.1rem;
  margin-bottom: 0.68rem;
}
.msg-text-area textarea:focus {
  outline: 0;
  border-color: var(--neutral-02);
}

.mydoc1-modal-wrapper.width40rem {
  width: 41.0625rem;
}

.restricted-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.44rem 0.5rem 0.44rem 1.38rem;
  height: 3.8125rem;
  margin-block: 1.2rem 0;
  border-radius: 0.9375rem;
}
.restricted-area .label {
  padding-top: 1px;
  letter-spacing: 0.045rem;
}
.restricted-area .right {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
}
.restricted-area .right .form-control {
  height: 3.25rem;
  padding-left: 1.13rem;
  border-radius: 0.625rem;
  height: 2.8125rem;
  width: 8.9375rem;
  padding-left: 3.25rem;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
  letter-spacing: 0.6px;
  text-align: center;
}
@media (max-height: 760px), (max-width: 1450px) {
  .restricted-area .right .form-control {
    height: 3rem;
    border-radius: 0.575rem;
  }
}
@media (max-width: 991.98px) {
  .restricted-area .right .form-control {
    padding-left: 1rem;
    height: 2.8rem;
    border-radius: 0.475rem;
  }
}
@media (max-width: 767.98px) {
  .restricted-area .right .form-control {
    padding-left: 0.8rem;
    height: 2.6875rem;
  }
}
.restricted-area .right .form-control::-moz-placeholder {
  font-weight: 400;
  font-style: italic;
  font-size: 0.75rem;
}
.restricted-area .right .form-control::placeholder {
  font-weight: 400;
  font-style: italic;
  font-size: 0.75rem;
}
.restricted-area .right .form-control::-moz-placeholder {
  font-size: 0.875rem;
  color: #BCBCBC;
  font-style: normal;
}
.restricted-area .right .form-control::placeholder {
  font-size: 0.875rem;
  color: #BCBCBC;
  font-style: normal;
}
.restricted-area .right span.fab {
  left: 0;
  top: 0;
}
.restricted-area .right span {
  padding: 0 0.25rem;
  letter-spacing: 0.045rem;
}

.docur-form .blurred-widget-block {
  position: relative;
  -webkit-backdrop-filter: unset;
          backdrop-filter: unset;
}
.docur-form .dropdown-menu {
  border: 0;
  margin-top: 0.25rem !important;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
}
.docur-form .dropdown-menu .typr-pair {
  cursor: pointer;
  display: flex;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.docur-form .dropdown-menu .typr-pair:hover {
  background-color: var(--neutral-06);
}

.input-wrapper,
.form-control--link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-wrapper .btn-fab-dropdown,
.form-control--link .btn-fab-dropdown {
  right: 9px;
  top: -2px;
  bottom: -1px;
}
.input-wrapper .btn-fab-dropdown + .dropdown-menu,
.form-control--link .btn-fab-dropdown + .dropdown-menu {
  right: -0.6rem !important;
}

.form-control--link {
  padding-right: 0;
}

.mydoc1-modal-wrapper {
  width: 36.313rem;
  min-height: 22.313rem;
  border-radius: 1rem;
  background: #FFF;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 1.25rem;
}

.mydoc1-modal-content {
  min-height: inherit;
  position: relative;
  padding-inline: 1.25rem;
  padding-top: 2.75rem;
  padding-bottom: 1rem;
  border-radius: 10px;
  border: 1px dashed #DADFDF;
  background: #FFF;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mydoc1-modal-content .mydoc1-modal-header h3 {
  color: var(--Heading-Black, #20272C);
  text-align: center;
  font-family: Playfair Display;
  font-size: 32px;
  font-weight: 800;
  text-transform: capitalize;
}
.mydoc1-modal-content .details {
  color: var(--Body-text, #63727E);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 117%;
  /* 14.04px */
  text-transform: capitalize;
}
.mydoc1-modal-content input[type=text]:focus, .mydoc1-modal-content input[type=text]:focus-visible,
.mydoc1-modal-content input[type=email]:focus,
.mydoc1-modal-content input[type=email]:focus-visible {
  outline: 0;
}
.mydoc1-modal-content .folder-input {
  border-radius: 12px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
  width: 326px;
  height: 45px;
  flex-shrink: 0;
  padding-inline: 1.25rem;
}
.mydoc1-modal-content .folder-input::-moz-placeholder {
  color: var(--Body-text, #63727E);
  font-family: Poppins;
}
.mydoc1-modal-content .folder-input::placeholder {
  color: var(--Body-text, #63727E);
  font-family: Poppins;
}
.mydoc1-modal-content .custom-input-width {
  width: 100%;
  padding-right: 9rem;
}
.mydoc1-modal-content .custom-model-dropdown {
  position: absolute;
  top: 0;
  right: 0;
}
.mydoc1-modal-content .custom-model-dropdown .btn-solid--dark {
  min-width: 8.8125rem;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
}
.mydoc1-modal-content .custom-model-dropdown .btn-solid--dark i {
  font-size: 1rem;
  margin-right: 0.2rem;
}
.mydoc1-modal-content .custom-model-dropdown .btn-solid--dark:hover, .mydoc1-modal-content .custom-model-dropdown .btn-solid--dark:focus, .mydoc1-modal-content .custom-model-dropdown .btn-solid--dark:active {
  border-color: var(--text-dark);
  background-color: var(--text-dark);
  color: var(--caribbean-light-green);
  box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
}
.mydoc1-modal-content .modal-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}
.mydoc1-modal-content .modal-form .modal-dropdown {
  flex: 1;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 0.75rem;
  border-radius: 12px;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
  flex-shrink: 0;
}
.mydoc1-modal-content .create-btn-dark {
  width: 100%;
  margin-top: 1.6rem;
  display: flex;
  height: 2.3rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--Heading-Black, #20272C);
}
.mydoc1-modal-content .create-btn-dark span {
  color: var(--Carriben-Light-Green, #E3FAF6);
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 100%;
  /* 10px */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.mydoc1-modal-content .cut-icon {
  background-image: url("/public/images/bg-particles/cut-icon-circle-bg.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1.75rem;
  height: 1.75rem;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}
.mydoc1-modal-content .cut-icon::before {
  position: relative;
}
.mydoc1-modal-content .cut-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mydoc1-modal-content .orange-wave {
  position: absolute;
  top: 0;
  left: -5rem;
}
.mydoc1-modal-content .blue-wave {
  position: absolute;
  bottom: 0;
  right: -3.6rem;
}
.mydoc1-modal-content .dropdown-toggle::after {
  display: none;
}

.body-profile {
  flex: 1;
  position: relative;
}

.spiral-bottom-corner {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.profile-nav {
  position: relative;
  margin: auto;
  min-height: 8rem;
  border-radius: 0.9rem;
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  padding-inline: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-nav.greetings,
.profile-nav .happy-img {
  width: 50%;
}
.profile-nav.greetings .user-info__dp-inner,
.profile-nav .happy-img .user-info__dp-inner {
  width: 5.375rem;
  height: 5.375rem;
  padding: 8px;
}
.profile-nav .happy-img {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.profile-nav .happy-img img {
  height: -moz-fit-content;
  height: fit-content;
}
.profile-nav .happy-img span {
  margin-right: 1.8rem;
}
.profile-nav .wave2 {
  top: 0.25rem;
  left: -1.25rem;
}
.profile-nav .red-square {
  top: 0.25rem;
  right: 10.8rem;
}
.profile-nav .red-star {
  bottom: -0.1rem;
  right: 0rem;
}
.profile-nav .black-cross {
  bottom: 0.85rem;
  left: 23rem;
}
.profile-nav .black-minus {
  bottom: 50%;
  right: 13rem;
}

.body-content {
  margin: auto;
  margin-top: 1.8rem;
  max-width: 61.3rem;
}
.body-content .heading-b {
  color: var(--dark-text-green, #106456);
  text-align: center;
  font-family: Playfair Display;
  font-size: 20px;
  font-weight: 800;
  line-height: 117%;
  /* 23.4px */
  text-transform: capitalize;
}
.body-content .details {
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
.body-content .cards-wrapper {
  margin-top: 2rem;
  margin: auto;
  display: flex;
  max-width: 49.25rem;
  -moz-column-gap: 2.5rem;
       column-gap: 2.5rem;
  justify-content: center;
}
.body-content .cards-wrapper .cards {
  z-index: 1;
  width: 14.75rem;
  height: 17rem;
  border-radius: 0.625rem;
  background: var(--Bg-White, #FFF);
  box-shadow: 0px 34px 34px 0px rgba(0, 0, 0, 0.14);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  margin: auto;
  padding: 11px;
}
.body-content .cards-wrapper .cards > a {
  cursor: pointer;
}
.body-content .cards-wrapper .cards .cards-img-wrapper {
  position: relative;
  width: 100%;
  min-height: 10.375rem;
  border-radius: 8px;
  background: #F5F5F5;
  box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.03);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-content .cards-wrapper .cards .cards-img-wrapper img {
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.body-content .cards-wrapper .cards .cards-img-wrapper .selected-tag {
  display: none;
}
.body-content .cards-wrapper .cards .cards-heading {
  color: var(--dark-text-green, #106456);
  text-align: center;
  font-family: Playfair Display;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}
.body-content .cards-wrapper .cards .cards-heading img {
  display: none;
}
.body-content .cards-wrapper .cards .cards-details {
  color: var(--Body-text, #63727E);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  text-transform: capitalize;
  max-width: 12.5rem;
  margin: auto;
}
.body-content .cards-wrapper .cards .cards-context {
  margin-top: 0.75rem;
}
.body-content .cards-wrapper .cards:hover {
  /* Add your hover styles here */
}
.body-content .cards-wrapper .cards:hover .cards-img-wrapper img {
  /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}
.body-content .cards-wrapper .selected-card {
  background: var(--text-dark);
}
.body-content .cards-wrapper .selected-card .cards-img-wrapper {
  background: var(--caribbean-light-green);
}
.body-content .cards-wrapper .selected-card .cards-img-wrapper img {
  /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}
.body-content .cards-wrapper .selected-card .cards-img-wrapper .selected-tag {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  width: 3.75rem;
  height: 1.18rem;
  flex-shrink: 0;
  border-radius: 3px;
  background-color: var(--caribbean-green);
  color: #FFF;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-content .cards-wrapper .selected-card .cards-heading {
  color: var(--caribbean-light-green);
}
.body-content .cards-wrapper .selected-card .cards-heading img {
  width: 20px;
  height: 10px;
  margin-right: 0.2rem;
  display: inline;
}
.body-content .cards-wrapper .selected-card .cards-details {
  color: var(--neutral);
}

.title {
  display: flex;
  align-items: center;
}
.title .h-title-teal {
  color: var(--Caribbean-Green, #36B8A3);
  font-family: Playfair Display;
  margin-left: 0.15rem;
  font-size: 20px;
  font-weight: 800;
  line-height: 117%;
  /* 23.4px */
  text-transform: capitalize;
}
.title .h-title-black {
  margin-left: 0.2rem;
  color: var(--Heading-Black, #20272C);
  font-family: Playfair Display;
  font-size: 20px;
  font-weight: 800;
  line-height: 117%;
  text-transform: capitalize;
}

.body-context {
  display: flex;
  min-width: 61.375rem;
  height: 100%;
  border-radius: 15px;
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.body-context .single-step {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.body-context .stepper {
  width: 1rem;
  height: 100%;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.body-context .stepper .stepper-divider {
  position: absolute;
  top: 49.85%;
  right: 22%;
  z-index: 1;
}
.body-context .inner-body-context {
  flex: 1;
  padding-top: 3rem;
  display: flex;
  flex-flow: column nowrap;
}

.rotate-text {
  color: var(--Bg-White, #FFF);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  rotate: -90deg;
  white-space: nowrap;
}

.bg-c-green {
  background: var(--Caribbean-Green, #36B8A3);
}

.bg-grey {
  background: var(--neutral);
}

.active-stepper-steps {
  height: 50%;
  border-top-right-radius: inherit;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.stepper-steps {
  height: 50%;
  border-bottom-right-radius: inherit;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.docur-form {
  block-size: -moz-fit-content;
  block-size: fit-content;
  max-width: 39.5rem;
  margin: auto;
  margin-top: 1rem;
}
.docur-form .form-level {
  color: var(--text-dark);
  font-size: 14px;
  font-weight: 400;
  min-width: 14.2rem;
}
.docur-form .form-inputs {
  width: 25rem;
  height: 3.8rem;
  border-radius: 10px;
  border: 1px solid var(--Grey-Stroke, #E5EAEE);
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.heading-profiles {
  color: var(--Caribbean-Green, #36B8A3);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 117%;
  /* 23.4px */
  text-transform: capitalize;
}

.details-grey {
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
}

.body-profile2 {
  flex: 1;
  margin-top: 1.75rem;
}
.body-profile2 .title img {
  margin-right: 0.5rem;
}

.profile-status {
  width: 100%;
  background: var(--Heading-Black, #20272C);
  border-radius: 0.6rem;
}
.profile-status .tilte {
  color: var(--Lighter-Grey, #F3F6F8);
  font-family: Playfair Display;
  line-height: 34px;
}
.profile-status .tilte-percentage {
  color: var(--Caribbean-Green, #36B8A3);
  font-family: Bebas Neue;
  line-height: 34px;
  /* 242.857% */
}
.profile-status .inner-ps {
  height: 4.75rem;
  -moz-column-gap: 0.3rem;
       column-gap: 0.3rem;
}

.inner-div-sidebar {
  padding: 0.6rem;
  min-height: 25.063rem;
}

.white-box-inner {
  text-align: center;
  padding-left: 1.8rem;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #EBEBEB;
  background: #FFF;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  min-height: 4.75rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;
}
.white-box-inner .inner-title {
  color: var(--Heading-Black, #20272C);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}

.white-box-unchecked {
  margin-top: 1rem;
  text-align: center;
}

.white-box-checked {
  margin-top: 1rem;
  text-align: left;
}
.white-box-checked .checked-content {
  display: block;
  width: 9.8rem;
  margin-left: 2rem;
  margin-top: 0.5rem;
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  text-transform: capitalize;
}
.white-box-checked .white-box-inner {
  padding-left: 1.5rem;
  background: var(--Carriben-Light-Green, #E3FAF6);
  min-height: auto;
  height: 3.6rem;
}
.white-box-checked .blurred-costomised-div {
  min-height: 8.5rem;
  padding: 0.375rem;
}

.form-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.6rem;
}
.form-row .span-optional {
  margin-left: 0.25rem;
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
}

.input-wrapper {
  width: 25rem;
  height: 3.8rem;
  border: 1px solid var(--Grey-Stroke, #E5EAEE);
  border-radius: inherit;
  padding: 0.5rem;
}
.input-wrapper input {
  padding-left: 1.1rem;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
  width: 100%;
  height: 100%;
  border: none;
}
.input-wrapper input:focus {
  outline: none;
}
.input-wrapper input::-moz-placeholder {
  color: var(--neutral-01);
  font-style: italic;
  font-weight: 400;
}
.input-wrapper input::placeholder {
  color: var(--neutral-01);
  font-style: italic;
  font-weight: 400;
}
.input-wrapper .dropdown-inputs {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
  width: 100%;
  height: 100%;
  border: none;
}
.input-wrapper .options-wrapper {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: #FFF;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 7rem;
       column-gap: 7rem;
}
.input-wrapper .inner-div-wapper {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: #FFF;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding-inline: 0.25rem;
}
.input-wrapper .inner-div-wapper .custom-inputs {
  width: auto;
  box-shadow: none;
}

.height-adjust-context {
  height: 94%;
  position: relative;
}

.btn-wrapper {
  position: absolute;
  display: flex;
  border: 0px solid red;
  bottom: 1.75rem;
  right: 2.5rem;
}
.btn-wrapper .custom-btn {
  min-height: 2.8rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  min-width: 9.3rem;
  padding: 7px 10px;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-family: Poppins;
}
.btn-wrapper .btn-dark {
  background: var(--Heading-Black, #20272C);
  color: var(--Carriben-Light-Green, #E3FAF6);
}
.btn-wrapper .btn-light {
  background: inherit;
  color: var(--Heading-Black, #20272C);
}

.textarea-input {
  min-height: 7rem;
}

.btn-black {
  height: 2.7rem;
  z-index: 1;
  width: 2.7rem;
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: var(--Heading-Black, #20272C);
  position: absolute;
  border-radius: 0.75rem;
  right: 0.5rem;
}

.btn-fab-dropdown {
  right: -1px;
  top: -1px;
  bottom: -1px;
  height: 2.8rem;
  padding-top: 2px;
  cursor: pointer;
  pointer-events: auto;
  width: 2.8rem;
  background-image: url("/public/images/bg-particles/dropdown-btn-bg.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.options-wrapper-checkbox {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: #FFF;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 4.2rem;
       column-gap: 4.2rem;
}
.options-wrapper-checkbox input[type=checkbox] {
  border-radius: 0px;
}

.model-custom-centered {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}
.model-custom-centered .custom-details-width {
  max-width: 85%;
}

.modal-table-wrapper {
  padding: 18px 12px 12px;
  width: 100%;
  max-height: 19.5rem;
  overflow-y: auto;
  border-radius: 15px;
  border: 1px solid #EAEBEB;
  background: #FFF;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  margin-top: 1rem;
}
.modal-table-wrapper::-webkit-scrollbar {
  display: none;
}
.modal-table-wrapper .table-heading {
  color: var(--Heading-Black, #20272C);
  font-family: Playfair Display;
}

.table-heading {
  color: var(--Heading-Black, #20272C);
  font-family: Playfair Display;
}

textarea {
  outline: 0;
  border: none;
  border-top: 1px solid var(--neutral-02);
  border-bottom: 1px solid var(--neutral-02);
  padding: 0.5rem 0.25rem;
}
textarea::-moz-placeholder {
  color: #AFAFAF;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}
textarea::placeholder {
  color: #AFAFAF;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}

textarea:focus {
  outline: 0;
}

.custom-checkbox {
  display: inline-block;
}
.custom-checkbox input[type=checkbox],
.custom-checkbox input[type=radio] {
  height: 0;
  width: 0;
}
.custom-checkbox input[type=checkbox] + label,
.custom-checkbox input[type=radio] + label {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.custom-checkbox input[type=checkbox] + label > span::before,
.custom-checkbox input[type=radio] + label > span::before {
  content: "";
  inset: auto;
}
.custom-checkbox input[type=checkbox] + label > div,
.custom-checkbox input[type=radio] + label > div {
  padding-top: 1px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.custom-checkbox input[type=checkbox] + label:hover > span, .custom-checkbox input[type=checkbox]:focus + label > span,
.custom-checkbox input[type=radio] + label:hover > span,
.custom-checkbox input[type=radio]:focus + label > span {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
  border-color: var(--text-theme);
}
.custom-checkbox input[type=checkbox]:checked + label .checkbox-label {
  color: var(--Caribbean-Green, #36B8A3);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}

.checkbox-label {
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

.inner-sec {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.inner-sec .main-cards-container {
  flex: 1;
  height: 100%;
}
.inner-sec .side-cards-container {
  max-width: 20.313rem;
  height: 100%;
  padding: 1.5rem;
  border-left: 1px dashed var(--neutral-03);
}

.customised-checked-content {
  width: 11.8rem !important;
  margin-left: 1rem !important;
}

.step-wrapper {
  flex: 1;
}
.step-wrapper .step-number {
  font-family: Poppins;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.step-wrapper .step-title {
  text-align: center;
  font-family: Playfair Display;
  font-size: 18px;
  font-weight: 800;
  text-transform: capitalize;
}

.custom-height {
  height: 5.3rem !important;
}

.bg-img-custom::after {
  background-image: url("/public/images/bg-particles/privacy-settings-card.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.heading-wrapper {
  max-width: 33rem;
  text-align: center;
}
.heading-wrapper .p-title {
  font-size: 32px;
  text-transform: capitalize;
  font-weight: 800;
}
.heading-wrapper .p-details {
  text-transform: capitalize;
  line-height: 18.7px;
}
.heading-wrapper .step-number-text {
  color: var(--neutral-01);
  margin-bottom: -0.3rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-left: 4rem;
}

.next-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.375rem;
  flex-shrink: 0;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

.drag-drop-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 28.8rem;
  height: 13.9rem;
  gap: 1rem;
  margin: auto;
  background: #F3F6F8;
}
.drag-drop-container input[type=file] {
  position: absolute;
  inset: 0;
  opacity: 0;
}

.custom-upload-photo-comp {
  border-radius: 0;
  box-shadow: none;
  max-width: 28.8rem;
  margin: auto;
}

.uploaded-cards-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  max-width: 28.8rem;
  margin: auto;
  gap: 0.5rem;
}
.uploaded-cards-wrapper .upload-cards {
  width: 7.5rem;
  height: 11.1rem;
  flex-shrink: 0;
  position: relative;
}
.uploaded-cards-wrapper .upload-cards .cut-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}
.uploaded-cards-wrapper .upload-cards .image-wrapper {
  width: 100%;
  height: 65%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.uploaded-cards-wrapper .upload-cards .bottom-content {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-left: 1rem;
}
.uploaded-cards-wrapper .cards-count-div {
  flex: 1;
  height: 11.1rem;
  flex-shrink: 0;
  background: #F3F6F8;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.model-custom-centered-microtext {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  height: 70vh;
}
.model-custom-centered-microtext .custom-details-width {
  max-width: 75%;
}

.issuer-select-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  max-width: 30.8rem;
  margin: 1rem auto;
  gap: 0.5rem;
}
.issuer-select-btn .tick-icon {
  padding-left: 0.4rem;
  height: 10px;
}

.word-document-preview {
  overflow-y: scroll;
  max-height: 700px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.a4-page {
  width: 595px; /* Approximate A4 width at 72 DPI */
  height: 842px; /* Approximate A4 height at 72 DPI */
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-wrap: break-word;
}

.microtext-modal-wrapper {
  width: 55.313rem;
  min-height: 35.313rem;
  border-radius: 1rem;
  background: #FFF;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 1.25rem;
}

.microtext-modal-content {
  min-height: inherit;
  position: relative;
  padding-inline: 1.25rem;
  border-radius: 10px;
  border: 1px dashed #DADFDF;
  background: #FFF;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.microtext-modal-content .microtext-modal-header h3 {
  color: var(--Heading-Black, #20272C);
  text-align: center;
  font-family: Playfair Display;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.microtext-modal-content .cut-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}
.microtext-modal-content .orange-wave {
  position: absolute;
  top: 0;
  left: -5rem;
}
.microtext-modal-content .blue-wave {
  position: absolute;
  bottom: 0;
  right: -3.6rem;
}
.microtext-modal-content .dropdown-toggle::after {
  display: none;
}
.microtext-modal-content .tick-icon {
  padding-left: 0.4rem;
  height: 10px;
}
.microtext-modal-content .microtext-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  max-width: 45rem;
  margin: 0.5rem auto;
  padding: 5px;
  gap: 0.5rem;
}

.uploaded-cards-wrapper-issuer-popup .upload-cards {
  width: 100%;
  height: 100%;
  max-height: 33rem;
  flex-shrink: 0;
  margin-top: 0.5rem;
  overflow-y: scroll;
}
.uploaded-cards-wrapper-issuer-popup .upload-cards .bottom-content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploaded-cards-wrapper-issuer-popup .upload-cards .bottom-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.uploaded-cards-wrapper-issuer-popup .upload-cards .bottom-content img {
  max-width: 100%;
  max-height: 100%;
  border: "1px solid green";
  -o-object-fit: contain;
     object-fit: contain; /* Keeps image proportions and fits within the container */
}
.uploaded-cards-wrapper-issuer-popup .upload-cards .image-micotext {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border: "1px solid green";
}

.pdf-scroll-container {
  max-height: 80vh; /* Set a max height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px; /* Optional: add padding */
  border: 1px solid #ccc; /* Optional: add a border for visibility */
  background-color: #fff; /* Optional: set background color */
}

.form-label {
  font-weight: bold;
}

.form-range {
  width: 50%;
}

.watermark-input {
  height: 30px;
  width: 12rem;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  outline: none;
}

.docFooter-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.docFooter-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: #282c34;
  width: 565px;
  height: 68px;
  padding: 10px;
  position: relative;
  border-radius: 8px;
}

.owner-info {
  margin-top: 1rem;
}
.owner-info .owner-field {
  font-size: 10px;
  color: #106456;
}
.owner-info span {
  font-size: 12px;
}

.search-box-card {
  display: flex;
  align-items: center;
  position: relative; /* To position the icon inside */
  padding: 0;
  margin-right: 150px;
}

.search-box-input {
  width: 100%;
  padding: 3px 10px; /* Add right padding to make space for the icon */
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 14px;
  margin: 0;
}

.search-box-icon {
  position: absolute;
  right: 5px; /* Adjust to align icon properly inside */
}

.qr-code-container {
  position: absolute;
  top: -35%;
  right: 9%;
  max-width: 80px;
  max-height: 50px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.docurio-logo {
  writing-mode: vertical-rl; /* Rotates the text vertically */
  transform: rotate(180deg); /* Adjusts the text orientation */
  font-size: 10px;
  color: white;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%) rotate(180deg);
}

.pdf-page-container {
  position: relative;
}

.image-micotext {
  width: 100%; /* Ensure images take full width */
}

.doc-footer {
  margin-bottom: 100px; /* Optional: Add spacing above the footer */
  position: absolute;
  z-index: 1;
}

.custom-progress-bar {
  height: 6px;
}

.dark-theme-block {
  border-radius: 0.938rem;
  background: url(/public/images/bg-particles/dark-theme-bg.svg) no-repeat center;
  background-color: #20272C;
  padding: 1.8rem 1.25rem 1.75rem;
}
.dark-theme-block__inner {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
}
.dark-theme-block__inner h4 {
  line-height: 1.4;
}
.dark-theme-block__inner .upload-docs {
  padding-left: 3.88rem;
}
.dark-theme-block__inner .upload-docs .btn {
  background-color: #36B8A3;
}
.dark-theme-block__inner .upload-docs span {
  color: #36B8A3;
}

.light-orange-theme-block {
  border-radius: 0.938rem;
  background: url(/public/images/bg-particles/light-green-theme-bg.svg) no-repeat center;
  background-color: #ffebbd;
  padding: 1.8rem 1.25rem 1.75rem;
}
.light-orange-theme-block__inner {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
}
.light-orange-theme-block__inner h4 {
  line-height: 1.4;
}
.light-orange-theme-block__inner .upload-docs {
  padding-left: 3.88rem;
}
.light-orange-theme-block__inner .upload-docs .btn {
  background-color: var(--primary-dark);
}
.light-orange-theme-block__inner .upload-docs span {
  color: var(--primary-dark);
}

.my-folder-wrapper .folder-flex-div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: min(3.5vw, 3rem);
}

.folder-img-wrapper {
  position: relative;
}
.folder-img-wrapper .menu-img {
  position: absolute;
  top: 1.5rem;
  right: 0.65rem;
}
.folder-img-wrapper .bt-title {
  white-space: nowrap;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-tabletop {
  border-radius: inherit;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.custom-tabletop div {
  flex: 1;
}

.custom-width {
  width: 2.5rem !important;
}

.table-top-btn-grp {
  display: flex;
  width: 10.4rem;
  background-color: var(--primary-dark);
}
.table-top-btn-grp button {
  height: 100%;
  flex: 1;
  background: inherit;
  border-radius: inherit;
}
.table-top-btn-grp button div {
  display: flex;
  justify-content: center;
  height: 0.85rem;
  border-right: 2px solid grey;
}

.dashboard-table thead tr th {
  vertical-align: middle;
}
.dashboard-table thead tr th img {
  display: inline;
  margin-bottom: 0.1rem;
}
.dashboard-table tr {
  border-top: 1px solid var(--neutral-02);
  border-bottom: 1px solid var(--neutral-02);
}
.dashboard-table tr:last-child {
  border-bottom: 0;
}

.dropdown-custom {
  width: 13.75rem;
  background: rgba(255, 255, 255, 0.9);
}
.dropdown-custom .td-action {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.35rem;
  margin: 0.25rem 0.56rem;
}
.dropdown-custom .td-action__link {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.dropdown-custom .td-action__link span {
  width: 1rem;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.dropdown-custom .td-action__link span svg {
  width: 1rem;
  height: 1rem;
}
.dropdown-custom .td-action__link span svg.fill,
.dropdown-custom .td-action__link span svg .fill {
  transition: all 0.2s ease-in-out;
  fill: #106456;
}
.dropdown-custom .td-action__link span svg.stroke,
.dropdown-custom .td-action__link span svg .stroke {
  transition: all 0.2s ease-in-out;
  stroke: #106456;
}
.dropdown-custom .td-action__link strong {
  transition: all 0.2s ease-in-out;
  color: #135149;
}
.dropdown-custom .td-action__link.active, .dropdown-custom .td-action__link.active:hover, .dropdown-custom .td-action__link:hover {
  background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat center;
  background-color: #20272C;
  background-position: 120%;
  box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.03);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.dropdown-custom .td-action__link.active span svg.fill,
.dropdown-custom .td-action__link.active span svg .fill, .dropdown-custom .td-action__link.active:hover span svg.fill,
.dropdown-custom .td-action__link.active:hover span svg .fill, .dropdown-custom .td-action__link:hover span svg.fill,
.dropdown-custom .td-action__link:hover span svg .fill {
  fill: var(--caribbean-light-green);
}
.dropdown-custom .td-action__link.active span svg.stroke,
.dropdown-custom .td-action__link.active span svg .stroke, .dropdown-custom .td-action__link.active:hover span svg.stroke,
.dropdown-custom .td-action__link.active:hover span svg .stroke, .dropdown-custom .td-action__link:hover span svg.stroke,
.dropdown-custom .td-action__link:hover span svg .stroke {
  stroke: var(--caribbean-light-green);
}
.dropdown-custom .td-action__link.active strong, .dropdown-custom .td-action__link.active:hover strong, .dropdown-custom .td-action__link:hover strong {
  color: var(--caribbean-light-green);
}
.dropdown-custom .td-action__link:hover {
  background-image: none;
}

.text-area-wrapper {
  max-height: 16rem;
}/*# sourceMappingURL=inner-main.css.map */