// Font sizes
.fz {
  &10 {
    font-size: 10px;
  }

  &12 {
    font-size: clamp(0.6875rem, 0.5625rem + 0.1563vw, 0.75rem);
  }

  &14 {
    font-size: clamp(0.775rem, 0.5rem + 0.325vw, 0.875rem);
  }

  &16 {
    font-size: clamp(0.875rem, 0.625rem + 0.3125vw, 1rem);
  }

  &18 {
    font-size: clamp(1rem, 0.75rem + 0.3125vw, 1.125rem);
  }

  &20 {
    font-size: clamp(1rem, 0.5rem + 0.625vw, 1.25rem);
  }

  &22 {
    font-size: clamp(1.05rem, 1rem + 0.3vw, 1.375rem);
  }

  &24 {
    font-size: clamp(1rem, 0.68rem + 0.425vw, 1.5rem);
  }

  &26 {
    font-size: clamp(1.4375rem, 1rem + 0.43vw, 1.625rem);
  }

  &28 {
    font-size: clamp(1.5625rem, 1.1875rem + 0.4688vw, 1.75rem);
  }

  &30 {
    font-size: clamp(1.5rem, 0.75rem + 0.9375vw, 1.875rem);
  }

  &34 {
    font-size: clamp(1.65rem, 0.85rem + 1rem, 2.125rem);
  }

  &40 {
    font-size: clamp(2.1rem, 1.2rem + 1.125vw, 2.5rem);
  }

  &46 {
    font-size: clamp(2.375rem, 1.375rem + 1.25vw, 2.875rem);
  }

  &52 {
    font-size: clamp(3rem, 2.5rem + 0.625vw, 3.25rem);
  }

  &60 {
    font-size: clamp(2.65rem, 0.25rem + 3.125vw, 3.75rem);
  }

  &70 {
    font-size: clamp(3rem, 0.125rem + 3.7vw, 4.375rem);
  }
}

// Font weights //
.fw {
  &300 {
    @include fw(300);
  }

  &400 {
    @include fw(400);
  }

  &500 {
    @include fw(500);
  }

  &600 {
    @include fw(600);
  }

  &700 {
    @include fw(700);
  }

  &800 {
    @include fw(800);
  }
}

// Line heights
.lh {
  &1 {
    line-height: 1;
  }

  &1-2 {
    line-height: 1.2;
  }

  &1-5 {
    line-height: 1.5;
  }

  &1-65 {
    line-height: 1.65;
  }
}

.font-primary {
  font-family: 'Poppins', sans-serif;
}

.font-secondary {
  font-family: 'Playfair Display', serif;
}

.font-tertiary {
  font-family: 'Bebas Neue', sans-serif;
}