.table-area {
  margin-top: 2.2rem;
  padding: 1rem 0.75rem;

  .table-title-bar {
    @include flexBox(flex, null, center, space-between);
    gap: 1rem;
    margin-bottom: 1rem;

    &__right {
      .btn-holder-dd {
        position: relative;
        padding-inline: 0.75rem 3rem;
        min-width: 12.3125rem;
        height: 2.8125rem;

        .fab {
          position: absolute;
          right: 0;

          img {
            max-width: 0.8rem;
          }
        }

        &:hover,
        &:focus,
        &:active {
          border: 1px solid var(--neutral-02);
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        .dropdown-item {
          padding: 0.5rem 1rem;
          @include ts(0.3s);

          &:active,
          &.active,
          &.active:active {
            color: white;
            background: var(--caribbean-green);
          }
        }
      }
    }
  }

  .table-responsive {
    overflow: visible;
  }
}

/* Define a base styling for the table */
.custom-dataTable {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      border-top: 1px solid var(--neutral-02);
      border-bottom: 1px solid var(--neutral-02);
      
    }
  }

  tbody{
    tr{
      td{
        img{
          display: inline-block;
          margin-right: 0.35rem;
        }
      }
    }
  }

  th,
  td {
    border: 0;
    padding: 0.6rem;
    text-align: left;
    background: transparent;
    vertical-align: middle;

    .checkbox-wrapper {

      input[type=checkbox]+label>span,
      input[type=radio]+label>span {
        border-color: var(--caribbean-green);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
      }
    }

    .select-privacy {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.5rem;

      &::after {
        display: none;
      }

      &[aria-expanded="true"] {
        .arrow {
          transform: rotateX(-180deg);
        }
      }
    }
  }

  th {
    &>div {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.25rem;

      img {
        max-height: 1rem;
      }
    }
  }

  /* Style the td elements within tbody */
  tbody {
    td { 
      font-size: 12px;
      white-space: nowrap;
      font-size: clamp(0.6875rem, 0.5625rem + 0.1563vw, 0.75rem);

      .grayText02 {
        color: var(--neutral-04);
      }
    }

    .table-remove-green {
      color: var(--caribbean-green);
      font-size: 10px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .verification-status {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.56rem;
    }
  }
}

.dropdown-menu--privacy {
  padding: 0.5rem;
  border: 1px solid #EFEFEF;
  background: rgba(255, 255, 255, 0.77);

  .blurred-widget-block {
    min-width: 12rem;

    a {
      padding: 0.5rem 1rem;
      @include flexBox(flex, null, center, null);
      gap: 0.35rem;
      color: var(--text-dark);

      b {
        width: 1.25rem;
        @include flexBox(inline-flex, null, center, center);
      }

      span {
        font-weight: 400;
        @include ts(0.3s);
      }

      &:hover {
        span {
          font-weight: 600;
        }
      }

      &.active {
        background: url(/public/images/icons/icon-selected-tickmark.svg) no-repeat center;
        background-position: 92%;
        background-size: 20px;

        span {
          font-weight: 600;
        }
      }

    }
  }

  .btn-add-recipient {
    height: 2.8125rem;
  }

  .form-group--search {
    border-radius: 0.75rem;
    background: #FFF;
    box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);

    .fab {
      top: 0;
      left: 0;
    }

    .form-control {
      padding-left: 3.5rem;
      min-width: unset;
    }
  }

  ul.info-shared {
    padding: 1rem 0.65rem 0.5rem;
    @include flexBox(flex, column nowrap, null, null);
    gap: 0.125rem;

    .title {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.35rem;
    }
  }
}