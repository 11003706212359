.mydoc1-modal-wrapper {
    width: 36.313rem;
    min-height: 22.313rem;
    border-radius: 1rem;
    background: #FFF;
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    backdrop-filter: blur(2px);
    padding: 1.25rem;
}

.mydoc1-modal-content {
    min-height: inherit;
    position: relative;
    padding-inline: 1.25rem;
    padding-top: 2.75rem;
    padding-bottom: 1rem;
    border-radius: 10px;
    border: 1px dashed #DADFDF;
    background: #FFF;
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    backdrop-filter: blur(2px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .mydoc1-modal-header {

        h3 {
            color: var(--Heading-Black, #20272C);
            text-align: center;
            font-family: Playfair Display;
            font-size: 32px;
            font-weight: 800;
            text-transform: capitalize;
        }
    }

    .details {
        color: var(--Body-text, #63727E);
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 117%;
        /* 14.04px */
        text-transform: capitalize;
    }

    input[type="text"],
    input[type="email"] {

        &:focus,
        &:focus-visible {
            outline: 0;
        }
    }

    .folder-input {
        border-radius: 12px;
        border: 1px solid #EAEAEA;
        background: #FFF;
        box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
        width: 326px;
        height: 45px;
        flex-shrink: 0;
        padding-inline: 1.25rem;

        &::placeholder {
            color: var(--Body-text, #63727E);
            font-family: Poppins;
        }
    }

    .custom-input-width {
        width: 100%;
        padding-right: 9rem;
    }

    .custom-model-dropdown {
        position: absolute;
        top: 0;
        right: 0;

        .btn-solid--dark {
            min-width: 8.8125rem;
            height: 45px;
            @include flexBox(inline-flex, null, center, space-between);
            gap: 0.8rem;

            i {
                font-size: 1rem;
                margin-right: 0.2rem;
            }

            &:hover,
            &:focus,
            &:active {
                border-color: var(--text-dark);
                background-color: var(--text-dark);
                color: var(--caribbean-light-green);
                box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
            }
        }
    }

    .modal-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;

        .modal-dropdown {
            flex: 1;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-inline: 0.75rem;
            border-radius: 12px;
            border: 1px solid #EAEAEA;
            box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
            flex-shrink: 0;
        }

    }

    .create-btn-dark {
        width: 100%;
        margin-top: 1.6rem;
        display: flex;
        height: 2.3rem;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--Heading-Black, #20272C);

        span {
            color: var(--Carriben-Light-Green, #E3FAF6);
            font-family: Poppins;
            font-size: 10px;
            font-weight: 600;
            line-height: 100%;
            /* 10px */
            letter-spacing: 0.6px;
            text-transform: uppercase;
        }
    }

    .cut-icon {
        background-image: url('/public/images/bg-particles/cut-icon-circle-bg.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 1.75rem;
        height: 1.75rem;
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;

        &::before {
            position: relative;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .orange-wave {
        position: absolute;
        top: 0;
        left: -5rem;
    }

    .blue-wave {
        position: absolute;
        bottom: 0;
        right: -3.6rem;

    }

    .dropdown-toggle::after {
        display: none;

    }
}

// Signup-S1
.body-profile {
    flex: 1;
    position: relative;

}

.spiral-bottom-corner {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
}

.profile-nav {
    position: relative;
    margin: auto;
    min-height: 8rem;
    border-radius: 0.9rem;
    background: rgba(255, 255, 255, 0.49);
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    padding-inline: 1.5rem;
    @include flexBox(flex, null, center, space-between);

    &.greetings,
    .happy-img {
        width: 50%;

        .user-info__dp-inner {
            width: 5.375rem;
            height: 5.375rem;
            padding: 8px;
        }
    }

    .happy-img {
        img {
            height: fit-content;
        }

        span {
            margin-right: 1.8rem;
        }

        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .wave2 {
        top: 0.25rem;
        left: -1.25rem;
    }

    .red-square {
        top: 0.25rem;
        right: 10.8rem;
    }

    .red-star {
        bottom: -0.10rem;
        right: 0rem;
    }

    .black-cross {
        bottom: 0.85rem;
        left: 23rem;
    }

    .black-minus {
        bottom: 50%;
        right: 13rem;
    }
}

.body-content {
    margin: auto;
    margin-top: 1.8rem;
    max-width: 61.3rem;
    // border: 1px solid red;

    .heading-b {
        color: var(--dark-text-green, #106456);
        text-align: center;
        font-family: Playfair Display;
        font-size: 20px;
        font-weight: 800;
        line-height: 117%;
        /* 23.4px */
        text-transform: capitalize;
    }

    .details {
        color: var(--Body-text, #63727E);
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
    }

    .cards-wrapper {
        margin-top: 2rem;
        margin: auto;
        display: flex;
        max-width: 49.25rem;
        column-gap: 2.5rem;
        justify-content: center;

        .cards {
            z-index: 1;
            width: 14.75rem;
            height: 17rem;
            border-radius: 0.625rem;
            background: var(--Bg-White, #FFF);
            box-shadow: 0px 34px 34px 0px rgba(0, 0, 0, 0.14);
            backdrop-filter: blur(2px);
            margin: auto;
            padding: 11px;

            &>a {
                cursor: pointer;
            }

            .cards-img-wrapper {
                position: relative;

                img {
                    -webkit-filter: grayscale(100%);
                    /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                }

                width: 100%;
                min-height: 10.375rem;
                border-radius: 8px;
                background: #F5F5F5;
                box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.03);
                backdrop-filter: blur(2px);
                display: flex;
                align-items: center;
                justify-content: center;

                .selected-tag {
                    display: none;
                }
            }

            .cards-heading {
                color: var(--dark-text-green, #106456);
                text-align: center;
                font-family: Playfair Display;
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                text-transform: capitalize;

                img {
                    display: none;
                }
            }

            .cards-details {
                color: var(--Body-text, #63727E);
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-weight: 400;
                text-transform: capitalize;
                max-width: 12.5rem;
                margin: auto;
            }

            .cards-context {
                margin-top: 0.75rem;
            }

            &:hover {

                /* Add your hover styles here */
                .cards-img-wrapper {
                    img {
                        -webkit-filter: grayscale(100%);
                        /* Safari 6.0 - 9.0 */
                        filter: grayscale(0%);
                    }
                }
            }
        }

        .selected-card {
            background: var(--text-dark);

            .cards-img-wrapper {
                background: var(--caribbean-light-green);

                img {
                    -webkit-filter: grayscale(100%);
                    /* Safari 6.0 - 9.0 */
                    filter: grayscale(0%);
                }

                .selected-tag {
                    position: absolute;
                    right: 0.3rem;
                    top: 0.3rem;
                    width: 3.75rem;
                    height: 1.18rem;
                    flex-shrink: 0;
                    border-radius: 3px;
                    background-color: var(--caribbean-green);
                    color: #FFF;
                    text-transform: uppercase;
                    @include flexBox(flex, null, center, center);

                }
            }

            .cards-heading {
                color: var(--caribbean-light-green);

                img {
                    width: 20px;
                    height: 10px;
                    margin-right: 0.2rem;
                    display: inline;
                }
            }

            .cards-details {
                color: var(--neutral);
            }
        }
    }
}


//Signup-S2

.title {
    display: flex;
    align-items: center;

    .h-title-teal {
        color: var(--Caribbean-Green, #36B8A3);
        font-family: Playfair Display;
        margin-left: 0.15rem;
        font-size: 20px;
        font-weight: 800;
        line-height: 117%;
        /* 23.4px */
        text-transform: capitalize;
    }

    .h-title-black {
        margin-left: 0.2rem;
        color: var(--Heading-Black, #20272C);
        font-family: Playfair Display;
        font-size: 20px;
        font-weight: 800;
        line-height: 117%;
        text-transform: capitalize;
    }
}

.body-context {
    display: flex;
    min-width: 61.375rem;
    height: 100%;
    border-radius: 15px;
    margin-top: 1rem;
    background: rgba(255, 255, 255, 0.49);
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    backdrop-filter: blur(2px);

    .single-step {
        @include flexBox(flex, column nowrap, center, center);
    }




    .stepper {
        width: 1rem;
        height: 100%;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
        backdrop-filter: blur(2px);

        .stepper-divider {
            position: absolute;
            top: 49.85%;
            right: 22%;
            z-index: 1;
        }

    }

    .inner-body-context {
        flex: 1;
        padding-top: 3rem;
        @include flexBox(flex, column nowrap, null, null);
    }
}

.rotate-text {
    color: var(--Bg-White, #FFF);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    rotate: -90deg;
    white-space: nowrap;
}

.bg-c-green {
    background: var(--Caribbean-Green, #36B8A3);
    //border: 1px solid #36B8A3;
}

.bg-grey {
    background: var(--neutral);
    //border: 1px solid var(--neutral);
}

.active-stepper-steps {
    height: 50%;
    border-top-right-radius: inherit;
    @include flexBox(flex, row nowrap, center, center);
}

.stepper-steps {
    height: 50%;
    border-bottom-right-radius: inherit;
    @include flexBox(flex, row nowrap, center, center);
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    backdrop-filter: blur(2px);
}

.docur-form {
    block-size: fit-content;
    max-width: 39.5rem;
    margin: auto;
    margin-top: 1rem;

    .form-level {
        color: var(--text-dark);
        font-size: 14px;
        font-weight: 400;
        min-width: 14.2rem;
    }

    .form-inputs {
        width: 25rem;
        height: 3.8rem;
        border-radius: 10px;
        border: 1px solid var(--Grey-Stroke, #E5EAEE);
        background: rgba(255, 255, 255, 0.49);
        box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
        backdrop-filter: blur(2px);
    }
}

.heading-profiles {
    color: var(--Caribbean-Green, #36B8A3);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 117%;
    /* 23.4px */
    text-transform: capitalize;

}

.details-grey {
    color: var(--Body-text, #63727E);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;
}

.body-profile2 {
    flex: 1;
    margin-top: 1.75rem;

    .title {
        img {
            margin-right: 0.5rem;
        }
    }
}

.profile-status {
    width: 100%;
    background: var(--Heading-Black, #20272C);
    border-radius: 0.6rem;

    .tilte {
        color: var(--Lighter-Grey, #F3F6F8);
        font-family: Playfair Display;
        line-height: 34px;

    }

    .tilte-percentage {
        color: var(--Caribbean-Green, #36B8A3);
        font-family: Bebas Neue;
        line-height: 34px;
        /* 242.857% */
    }

    .inner-ps {
        height: 4.75rem;
        column-gap: 0.3rem;
    }
}

.inner-div-sidebar {
    padding: 0.6rem;
    min-height: 25.063rem;
}

.white-box-inner {
    text-align: center;
    padding-left: 1.8rem;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #EBEBEB;
    background: #FFF;
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    backdrop-filter: blur(2px);
    min-height: 4.75rem;
    column-gap: 0.5rem;
    @include flexBox(flex, row nowrap, center, left);

    .inner-title {
        color: var(--Heading-Black, #20272C);
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
    }


}

.white-box-unchecked {
    margin-top: 1rem;
    text-align: center;

}

.white-box-checked {
    margin-top: 1rem;
    text-align: left;

    .checked-content {
        display: block;
        width: 9.8rem;
        margin-left: 2rem;
        margin-top: 0.5rem;
        color: var(--Body-text, #63727E);
        font-family: Poppins;
        font-size: 10px;
        font-weight: 400;
        text-transform: capitalize;
    }

    .white-box-inner {
        padding-left: 1.5rem;
        background: var(--Carriben-Light-Green, #E3FAF6);
        min-height: auto;
        height: 3.6rem;
    }

    .blurred-costomised-div {
        min-height: 8.5rem;
        padding: 0.375rem;
    }
}

.form-row {
    // border: 1px solid red;
    @include flexBox(flex, row nowrap, center, space-between);
    margin-top: 1.6rem;

    .span-optional {
        margin-left: 0.25rem;
        color: var(--Body-text, #63727E);
        font-family: Poppins;
        font-size: 10px;
        font-weight: 400;
    }
}

.input-wrapper {
    width: 25rem;
    height: 3.8rem;
    border: 1px solid var(--Grey-Stroke, #E5EAEE);
    border-radius: inherit;
    padding: 0.5rem;

    input {
        padding-left: 1.1rem;
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
        width: 100%;
        height: 100%;
        border: none;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: var(--neutral-01);
            font-style: italic;
            font-weight: 400;
        }
    }

    .dropdown-inputs {
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
        width: 100%;
        height: 100%;
        border: none;
    }

    .options-wrapper {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: #FFF;
        @include flexBox(flex, row nowrap, center, center);
        column-gap: 7rem;
    }

    .inner-div-wapper {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: #FFF;
        @include flexBox(flex, row nowrap, center, center);
        padding-inline: 0.25rem;

        .custom-inputs {
            width: auto;
            box-shadow: none;
        }
    }
}


.height-adjust-context {
    height: 94%;
    position: relative;
}


//Skip & Next buttons
.btn-wrapper {
    position: absolute;
    display: flex;
    border: 0px solid red;
    bottom: 1.75rem;
    right: 2.5rem;

    .custom-btn {
        min-height: 2.8rem;
        @include flexBox(flex, row nowrap, center, center);
        min-width: 9.3rem;
        padding: 7px 10px;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        font-family: Poppins;
    }

    .btn-dark {
        background: var(--Heading-Black, #20272C);
        color: var(--Carriben-Light-Green, #E3FAF6);
    }

    .btn-light {
        background: inherit;
        color: var(--Heading-Black, #20272C);
    }
}


.textarea-input {
    min-height: 7rem;
}


.btn-black {
    height: 2.7rem;
    z-index: 1;
    width: 2.7rem;
    pointer-events: auto;
    cursor: pointer;
    @include flexBox(flex, row nowrap, center, center);
    background: var(--Heading-Black, #20272C);
    position: absolute;
    border-radius: 0.75rem;
    right: 0.5rem;

}




.btn-fab-dropdown {
    right: -1px;
    top: -1px;
    bottom: -1px;
    height: 2.8rem;
    padding-top: 2px;
    cursor: pointer;
    pointer-events: auto;
    width: 2.8rem;
    background-image: url('/public/images/bg-particles/dropdown-btn-bg.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.options-wrapper-checkbox {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: #FFF;
    @include flexBox(flex, row nowrap, center, center);
    column-gap: 4.2rem;

    input[type=checkbox] {
        border-radius: 0px;

    }
}

.model-custom-centered {
    max-width: fit-content !important;

    .custom-details-width {
        max-width: 85%;
    }
}

.modal-table-wrapper {
    padding: 18px 12px 12px;
    width: 100%;
    max-height: 19.5rem;
    overflow-y: auto;
    border-radius: 15px;
    border: 1px solid #EAEBEB;
    background: #FFF;
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    backdrop-filter: blur(2px);
    margin-top: 1rem;

    &::-webkit-scrollbar {
        display: none;
    }

    .table-heading {
        color: var(--Heading-Black, #20272C);
        font-family: Playfair Display;
    }
}

.table-heading {
    color: var(--Heading-Black, #20272C);
    font-family: Playfair Display;
}

textarea {
    outline: 0;
    border: none;
    border-top: 1px solid var(--neutral-02);
    border-bottom: 1px solid var(--neutral-02);
    padding: 0.5rem 0.25rem;

    &::placeholder {
        color: #AFAFAF;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;

    }
}




//On focus border None textarea
textarea:focus {
    outline: 0;
}

.custom-checkbox {}

.custom-checkbox {
    display: inline-block;

    input[type="checkbox"],
    input[type="radio"] {
        height: 0;
        width: 0;
    }

    input[type="checkbox"]+label,
    input[type="radio"]+label {
        position: relative;
        @include flexBox(inline-flex, null, center, null);

        &>span {


            &::before {
                content: "";

                inset: auto;

            }
        }

        &>div {
            padding-top: 1px;
            cursor: pointer;
            @include flexBox(inline-flex, null, center, null);
        }
    }

    input[type="checkbox"],
    input[type="radio"] {

        &+label:hover>span,
        &:focus+label>span {
            box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
            border-color: var(--text-theme);
        }
    }

    input[type="checkbox"]:checked+label .checkbox-label {
        color: var(--Caribbean-Green, #36B8A3);
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
    }
}

.checkbox-label {
    color: var(--Body-text, #63727E);
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
}


.inner-sec {
    @include flexBox(flex, null, center, null);
    height: 100%;
    width: 100%;

    .main-cards-container {
        flex: 1;
        height: 100%;

    }


    .side-cards-container {
        max-width: 20.313rem;
        height: 100%;
        padding: 1.5rem;
        border-left: 1px dashed var(--neutral-03);
    }
}

.customised-checked-content {
    width: 11.8rem !important;
    margin-left: 1rem !important;
}

.step-wrapper {
    flex: 1;

    .step-number {
        font-family: Poppins;
        letter-spacing: 4px;
        text-transform: uppercase;
    }

    .step-title {

        text-align: center;
        font-family: Playfair Display;
        font-size: 18px;
        font-weight: 800;
        text-transform: capitalize;
    }
}

.custom-height {
    height: 5.3rem !important;
}

.bg-img-custom {
    &::after {
        background-image: url('/public/images/bg-particles/privacy-settings-card.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.heading-wrapper {
    max-width: 33rem;
    text-align: center;

    .p-title {
        font-size: 32px;
        text-transform: capitalize;
        font-weight: 800;
    }

    .p-details {
        text-transform: capitalize;
        line-height: 18.7px;
    }

    .step-number-text {
        color: var(--neutral-01);
        margin-bottom: -0.3rem;
        letter-spacing: 4px;
        text-transform: uppercase;
        margin-left: 4rem;
    }
}

.next-btn {
    @include flexBox(flex, null, center, center);
    min-height: 2.375rem;
    flex-shrink: 0;
    text-transform: uppercase;
    letter-spacing: 0.6px;
}

.drag-drop-container {
    @include flexBox(flex, column nowrap, center, center);
    width: 28.8rem;
    height: 13.9rem;
    gap: 1rem;
    margin: auto;
    background: #F3F6F8;

    input[type="file"] {
        position: absolute;
        inset: 0;
        opacity: 0;
    }

}

.custom-upload-photo-comp {
    border-radius: 0;
    box-shadow: none;
    max-width: 28.8rem;
    margin: auto;
}

.uploaded-cards-wrapper {
    @include flexBox(flex, row nowrap, center, center);
    max-width: 28.8rem;
    margin: auto;
    gap: 0.5rem;

    .upload-cards {
        width: 7.5rem;
        height: 11.1rem;
        flex-shrink: 0;

        position: relative;

        .cut-icon {
            position: absolute;
            top: 12px;
            right: 12px;
        }

        .image-wrapper {
            width: 100%;
            height: 65%;
            @include flexBox(flex, row nowrap, center, center);
        }

        .bottom-content {
            @include flexBox(inline-flex, row nowrap, center, center);
            gap: 0.25rem;
            margin-left: 1rem;
        }
    }

    .cards-count-div {
        flex: 1;
        height: 11.1rem;
        flex-shrink: 0;
        background: #F3F6F8;
        @include flexBox(inline-flex, row nowrap, center, center);
    }
}

// document styling in issuer component
.model-custom-centered-microtext{
    max-width: fit-content !important;
    height: 70vh;

    .custom-details-width {
        max-width: 75%;
    }
}
.issuer-select-btn{
    @include flexBox(flex, row nowrap, center, center);
    max-width: 30.8rem;
    margin: 1rem auto;
    gap: 0.5rem;
    .tick-icon{
        padding-left: 0.4rem;
        height: 10px;
    }
}
.word-document-preview {
    overflow-y: scroll;
    max-height: 700px; /* Adjust as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px;
  }
  
  .a4-page {
    width: 595px; /* Approximate A4 width at 72 DPI */
    height: 842px; /* Approximate A4 height at 72 DPI */
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow-wrap: break-word;
  }
  

// document syling in issuer popup
.microtext-modal-wrapper {
    width: 55.313rem;
    min-height: 35.313rem;
    border-radius: 1rem;
    background: #FFF;
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    backdrop-filter: blur(2px);
    padding: 1.25rem;
}

.microtext-modal-content {
    min-height: inherit;
    position: relative;
    padding-inline: 1.25rem;
    border-radius: 10px;
    border: 1px dashed #DADFDF;
    background: #FFF;
    box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
    backdrop-filter: blur(2px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .microtext-modal-header {

        h3 {
            color: var(--Heading-Black, #20272C);
            text-align: center;
            font-family: Playfair Display;
            font-size: 20px;
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 1rem;
        }
    }
      .cut-icon {
            position: absolute;
            top: 12px;
            right: 12px;
        }

    .orange-wave {
        position: absolute;
        top: 0;
        left: -5rem;
    }

    .blue-wave {
        position: absolute;
        bottom: 0;
        right: -3.6rem;

    }

    .dropdown-toggle::after {
        display: none;

    }
    .tick-icon{
        padding-left: 0.4rem;
        height: 10px;
    }
    .microtext-btn{
        @include flexBox(flex, row nowrap, center, center);
        max-width: 45rem;
        margin: 0.5rem auto;
        padding: 5px;
        gap: 0.5rem;
    }
}

.uploaded-cards-wrapper-issuer-popup {
    .upload-cards {
        width: 100%;
        height: 100%;
        max-height: 33rem;
        flex-shrink: 0;
        margin-top: 0.5rem;
        overflow-y: scroll;

        .bottom-content-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            
          }
          
          .bottom-content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            
          }
          
          .bottom-content img {
            max-width: 100%;
            max-height: 100%;
             border:"1px solid green";
            object-fit: contain; /* Keeps image proportions and fits within the container */
          }
          .image-micotext{
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
             border:"1px solid green"
          }
          
    }
}
.pdf-scroll-container {
    max-height: 80vh; /* Set a max height for the container */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 10px; /* Optional: add padding */
    border: 1px solid #ccc; /* Optional: add a border for visibility */
    background-color: #fff; /* Optional: set background color */
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .form-range {
    width: 50%;
  }
    
  .watermark-input{
    height: 30px;
    width: 12rem;
    padding:5px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    outline: none;
  }
//   doc footer starts here
.docFooter-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .docFooter-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #282c34;
    width: 565px;
    height: 68px;
    padding: 10px;
    position: relative;
    border-radius: 8px;
  }
  .owner-info{
    margin-top: 1rem;
    .owner-field{
        font-size: 10px;
        color:#106456;
    }
    span{
        font-size: 12px;
    }
  }
  
  .search-box-card {
    display: flex;
    align-items: center;
    position: relative; /* To position the icon inside */
    padding: 0;
    margin-right: 150px; 
  }
  
  .search-box-input {
    width: 100%;
    padding: 3px 10px; /* Add right padding to make space for the icon */
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 14px;
    margin: 0;
    // box-sizing: border-box;
  }
  
  .search-box-icon {
    position: absolute;
    right: 5px; /* Adjust to align icon properly inside */
  }
  
  
  .qr-code-container {
    position: absolute;
    top: -35%;
    right: 9%;
    max-width: 80px;
    max-height: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .docurio-logo {
    writing-mode: vertical-rl; /* Rotates the text vertically */
    transform: rotate(180deg); /* Adjusts the text orientation */
    font-size: 10px;
    color: white;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%) rotate(180deg);
  }

  .pdf-page-container {
    position: relative;
    // margin-bottom: 20px; /* Adjust spacing between pages and footers */
  }
  
  .image-micotext {
    width: 100%; /* Ensure images take full width */
  }
  
  .doc-footer {
    margin-bottom: 100px; /* Optional: Add spacing above the footer */
    position:absolute;
    z-index: 1;
  }

//   document footer ends here
  

.custom-progress-bar {
    height: 6px;
}

.dark-theme-block {
    border-radius: 0.938rem;
    background: url(/public/images/bg-particles/dark-theme-bg.svg) no-repeat center;
    background-color: #20272C;
    padding: 1.8rem 1.25rem 1.75rem;

    &__inner {
        @include flexBox(flex, column nowrap, null, null);
        gap: 0.5rem;

        h4 {
            line-height: 1.4;
        }

        .upload-docs {
            padding-left: 3.88rem;

            .btn {
                background-color: #36B8A3;
            }

            span {
                color: #36B8A3;
            }
        }
    }
}

.light-orange-theme-block {
    border-radius: 0.938rem;
    background: url(/public/images/bg-particles/light-green-theme-bg.svg) no-repeat center;
    background-color: #ffebbd;
    padding: 1.8rem 1.25rem 1.75rem;

    &__inner {
        @include flexBox(flex, column nowrap, null, null);
        gap: 0.5rem;

        h4 {
            line-height: 1.4;
        }

        .upload-docs {
            padding-left: 3.88rem;

            .btn {
                background-color: var(--primary-dark)
            }

            span {
                color: var(--primary-dark)
            }
        }
    }
}

.my-folder-wrapper {

    .folder-flex-div {
        @include flexBox(flex, row nowrap, center, space-between);
        gap: min(3.5vw, 3rem);

    }
}

.folder-img-wrapper {

    position: relative;

    .menu-img {
        position: absolute;
        top: 1.5rem;
        right: 0.65rem;
    }

    .bt-title {
        white-space: nowrap;
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.custom-tabletop {
    border-radius: inherit;
    column-gap: 1rem;

    div {
        flex: 1;
    }
}

.custom-width {
    width: 2.5rem !important;
}

.table-top-btn-grp {
    display: flex;
    width: 10.4rem;
    background-color: var(--primary-dark);

    button {
        height: 100%;
        flex: 1;
        background: inherit;
        border-radius: inherit;

        div {
            display: flex;
            justify-content: center;
            height: 0.85rem;
            border-right: 2px solid grey;
        }
    }
}


.dashboard-table {

    thead {
        tr {
            th {
                vertical-align: middle;

                img {
                    display: inline;
                    margin-bottom: 0.1rem;
                }
            }
        }
    }


    tr {
        border-top: 1px solid var(--neutral-02);
        border-bottom: 1px solid var(--neutral-02);

        &:last-child {
            border-bottom: 0;
        }
    }
}

// Dropdown Actions
.dropdown-custom {
    width: 13.75rem;
    background: rgba(255, 255, 255, 0.9);

    .td-action {
        @include flexBox(flex, column nowrap, null, null);
        gap: 0.35rem;
        margin: 0.25rem 0.56rem;

        &__link {
            @include flexBox(flex, null, center, null);
            gap: 0.6rem;
            padding: 1rem 1.25rem;
            border-radius: 0.5rem;
            @include ts(0.3s);

            span {
                width: 1rem;
                flex-shrink: 0;
                @include flexBox(inline-flex, null, center, center);

                svg {
                    width: 1rem;
                    height: 1rem;

                    &.fill,
                    .fill {
                        @include ts(0.2s);
                        fill: #106456;
                    }

                    &.stroke,
                    .stroke {
                        @include ts(0.2s);
                        stroke: #106456;
                    }
                }
            }

            strong {
                @include ts(0.2s);
                color: #135149;
            }

            &.active,
            &.active:hover,
            &:hover {
                background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat center;
                background-color: #20272C;
                background-position: 120%;
                box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.03);
                backdrop-filter: blur(2px);

                span {
                    svg {

                        &.fill,
                        .fill {
                            fill: var(--caribbean-light-green);
                        }

                        &.stroke,
                        .stroke {
                            stroke: var(--caribbean-light-green);
                        }
                    }
                }

                strong {
                    color: var(--caribbean-light-green);
                }
            }

            &:hover {
                background-image: none;
            }
        }
    }
}

.text-area-wrapper {
    max-height: 16rem;
}