/* devSec section */
.devSec {
  background: url(/public/landing_images/stories/bg.png) no-repeat center center;
  background-size: 100% auto;
  min-height: calc(695px * var(--resp));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: calc(100px * var(--resp));

  .section-desc {
    max-width: 80%;
  }

  .row {
    justify-content: center;
    align-items: center;
  }

  &__img1 {
    width: 85%;
    height: auto !important;
  }

  .section-greentxt {
    margin-bottom: calc(20px * var(--resp));
  }

  &__deco1 {
    width: calc(106px * var(--resp));
  }

  &__deco2 {
    width: calc(55px * var(--resp));
    position: absolute;
    bottom: 17.5%;
    right: 7.5%;
  }

  &__buttons {
    margin-bottom: calc(20px * var(--resp));
  }

  &__deco4 {
    width: calc(61px * var(--resp));
  }

  &__deco4-wrapper {
    padding-left: calc(20px * var(--resp));
  }
}

@media (max-width:1800px) {
  .section-head1 {
    font-size: calc(55px * var(--resp));
  }
}

@media (max-width:1600px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {}

@media (max-width: 1200px) {
  .devSec {
    min-height: auto;
  }
}

@media (max-width:991.98px) {}

@media (max-width:767.98px) {
  .devSec {
    margin-bottom: 2rem;
    .section-head1 {
      margin-top: -1.25rem;
      font-size: calc(30px * var(--resp)) !important;
    }

    .section-greentxt,
    &__buttons {
      margin-left: 0.6rem;
    }
  }

  .devSec__deco2 {
    right: 10%;
    left: auto;
    top: 35%;
    bottom: auto;
  }

  .devSec__deco1 {
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: calc(85px* var(--resp));
  }

  .devSec__img1 {
    width: 70%;
    height: auto !important;
    display: block;
    margin-inline: auto;
  }

  .devSec {
    background-size: auto calc(100%);
    padding-bottom: 50px;
    background-position: left 20% center;
  }
}