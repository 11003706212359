/* giveSec section */
.giveSec {
  position: relative;
  padding-block: calc(100px * var(--resp));

  .container {
    position: relative;
  }

  .section-head1 {
    max-width: 60%;
    margin-inline: auto;
    text-align: center;
  }

  .section-desc {
    max-width: 50%;
    margin-inline: auto;
    text-align: center;
  }

  .section-greentxt {
    max-width: 50%;
    margin-inline: auto;
    text-align: center;
  }


  &__bg {
    width: 110%;
  }

  &__code {
    width: 94%;
    margin-bottom: 1.5%;
    margin-left: 4.2%;
    margin-top: 3%;
  }

  &__head1 {
    width: 94%;
    margin-left: 4.2%;
    text-align: center;
    font-family: var(--ff2);
    font-weight: 600;
    font-size: calc(22px * var(--resp));
  }

  &__content {
    position: absolute;
    top: 9%;
    left: 4%;
    right: 0;
  }

  &__star {
    position: absolute;
    left: 0%;
    top: -7.5%;
    width: calc(67px * var(--resp)) !important;
  }

  &__box {
    border-radius: calc(14px * var(--resp));
    background-color: #FEEDC2;
    width: 100%;
    background-image: url(/public/landing_images/freedom/bg2.svg);
    background-repeat: no-repeat;
    background-size: 28% auto;
    background-position: right bottom 20%;
    min-height: calc(195px * var(--resp));
    padding: calc(25px * var(--resp)) calc(25px * var(--resp)) calc(25px * var(--resp)) calc(15px * var(--resp));
    margin-bottom: calc(40px * var(--resp));
    position: relative;

    h3 {
      margin-top: calc(20px * var(--resp));
      font-family: var(--ff2);
      font-size: calc(26px * var(--resp));
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 26.4px */
      text-transform: capitalize;
    }

    p {
      text-transform: capitalize;
      font-size: calc(18px * var(--resp));
    }

    .flex-shrink-0 {
      width: 7%;
      text-align: center;

      img {
        width: 100px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(-35%, -35%, 0);
      }
    }

    &-1 {
      background-color: #FEEDC2;
      margin-top: 18%;
      transform: translateX(-17%);
    }

    &-2 {
      background-color: #C3F3E7;
      transform: translateX(-8%);
    }

    &-3 {
      background-color: #FDD2C1;
      transform: translateX(-18%);
    }
  }

  &__deco2 {
    position: absolute;
    right: 20%;
    top: -5%;
    width: calc(78px * var(--resp)) !important;
  }

  &__deco3 {
    position: absolute;
    right: 10%;
    top: 10%;
    width: calc(93px * var(--resp)) !important;
  }
}

















@media (max-width:1800px) {
  .giveSec__box {
    width: 110%;
    min-height: calc(150px * var(--resp));
    padding: calc(25px * var(--resp)) calc(25px * var(--resp)) calc(25px * var(--resp)) calc(15px * var(--resp));
    margin-bottom: calc(30px * var(--resp));

  }

  .giveSec__box h3 {
    font-size: calc(22px * var(--resp));
  }

  .giveSec__box p {
    font-size: calc(15px * var(--resp));
  }
}

@media (max-width:1600px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {
  .giveSec__deco2 {
    top: -7.5%;
  }
}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {}

@media (max-width:991.98px) {
  .giveSec__box-1 {
    margin-top: 40px;
  }

  .giveSec {
    position: relative;
    padding-block: 0;
  }

  .giveSec .section-head1 {
    max-width: 100%;
  }

  .giveSec .section-desc {
    max-width: 100%;
  }

  .giveSec .section-greentxt {
    max-width: 100%;
  }

  .giveSec__deco3 {
    display: none;
  }

  .giveSec__box {
    min-height: 70px;

    h3 {
      margin-block: 0 0.15rem;
    }

    p {
      margin-bottom: 0;
    }
  }

  .giveSec__box-1,
  .giveSec__box-2,
  .giveSec__box-3 {
    transform: translateX(0);
    width: 90%;
    margin-left: 5%;
    padding: 1rem 10px;
    margin-bottom: 35px;
  }

  .giveSec__bg {
    width: 100%;
  }

  .giveSec__code {
    width: 83%;
  }

  .giveSec__head1 {
    margin-left: 0;
  }

  .giveSec__box-2 {}

  .giveSec__box-3 {}
}