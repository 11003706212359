/* Hero section */
.heroSec {
  padding-top: calc(30px * var(--resp));
  position: relative;
  margin-bottom: calc(50px * var(--resp));

  &::before {
    content: '';
    position: absolute;
    width: calc(284px * var(--resp));
    height: calc(284px * var(--resp));
    border-radius: 100%;
    background: rgba(54, 184, 163, 0.5);
    filter: blur(100px);
  }

  &__inner {
    background: url('/public/landing_images/hero/bg.png') no-repeat top left;
    background-size: 100% auto;
    backdrop-filter: blur(26px);
    min-height: 100vh;
    max-width: 85%;
    margin-inline: auto;
    padding-top: calc(150px * var(--resp));
  }

  &__left {
    max-width: 60%;
    margin-top: calc(40px * var(--resp));
  }

  .section-head1 {
    max-width: 90%;
  }

  .section-desc {
    max-width: 50%;
  }

  .section-greentxt {
    margin-bottom: calc(30px * var(--resp));
  }

  &__buttons {
    margin-bottom: calc(30px * var(--resp));
  }

  &__deco0 {
    position: absolute;
    right: 0;
    top: 8%;
    z-index: 1;
    width: 45%;
    user-select: none;
  }

  &__deco4-wrapper {
    margin-top: calc(20px * var(--resp));
    padding-left: calc(75px * var(--resp));

    @media (max-width: 767.98px) {
      img {
        max-width: 2rem;
      }
    }
  }

  &__deco2 {
    position: absolute;
    left: 6.5%;
    z-index: 1;
    top: 17%;
    width: 8%;
  }


  &__stats {
    position: absolute;
    z-index: 1;
    right: 7%;
    bottom: 6.25%;
    width: 20%;

    &-bg {
      width: 100%;
    }
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 1.5%;
    width: 100%;
    padding: 10%;

    &>span {
      width: 27.5%;

      img {
        width: 100%;
      }

      &>div {
        text-align: center;
        margin-top: calc(5px * var(--resp));
        font-weight: bold;

        span {
          background-color: #cecece;
          color: #fff;
          padding: 2px 7.5px;
          line-height: 1;
          border-radius: 5px;
          font-size: calc(13px * var(--resp));
        }
      }
    }

    &-csv {
      span {
        background-color: #36B8A3 !important;
      }
    }

    &-pdf {
      span {
        background-color: #FF7169 !important;
      }
    }

    &-doc {
      span {
        background-color: #0072FF !important;
      }
    }
  }

  &__count {
    display: flex;
    justify-content: space-between;

    >div {
      >div:first-child {
        color: #090335;
        font-family: var(--ff2);
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 97%;
        text-transform: capitalize;
        margin-bottom: calc(10px * var(--resp));
      }

      >div:last-child {
        color: #888;
        font-size: calc(16px * var(--resp));
      }
    }
  }

  &__stats-line {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
  }

  &__count {
    position: absolute;
    bottom: 20%;
    width: 100%;
    padding: 0 11%;
  }
}


@media (min-width:2200px) {
  .heroSec__stats {
    bottom: 9%;
  }
}

@media (max-width:1800px) {
  .heroSec__left {
    --resp: 0.75;
  }
}

@media (max-width:1600px) {
  .heroSec__deco2 {
    top: 23%;
    width: 8%;
  }
}

@media (max-width: 1440px) {
  .heroSec__inner {
    max-width: 87.5%;
  }

  .heroSec__deco2 {
    left: 3.5%;
  }
}

@media (max-width: 1280px) {
  .heroSec__inner {
    max-width: 90.5%;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1100px;
  }

  .heroSec__deco2 {
    left: 2.5%;
  }
}

@media (max-width: 1200px) {
  .heroSec__inner {
    min-height: auto;
    max-width: calc(100% - 20px);
  }

  .heroSec__stats {
    bottom: 9.28%;
    right: 9%;
  }

  .heroSec__deco0 {
    top: 15%;
  }

  .headerSec__logo {
    margin-left: 6px;
  }
}

@media (max-width:991.98px) {
  .heroSec__inner {
    min-height: auto;
    background-size: auto 100%;
    max-width: calc(100% - 20px);
  }

  .heroSec__left {
    max-width: 100%;
  }

  .heroSec__columns {
    display: flex;
    flex-direction: column;
  }

  .heroSec__deco0 {
    position: relative;
    inset: auto;
    width: 100%;
  }

  .heroSec__stats {
    width: 50%;
    bottom: 0;
    right: 15.75%;
    transform: translateX(6%);
  }

  .heroSec .section-desc {
    max-width: 75%;
  }

  .heroSec__icons {
    padding: 5%;
  }

  .heroSec__inner {
    padding-top: calc(100px * var(--resp));
  }

  .heroSec__inner {
    margin-bottom: -225px;
  }

  .heroSec {
    margin-bottom: 0;

    .section-head1,
    .section-desc,
    .section-greentxt,
    &__buttons {
      padding-left: 0.4rem;
    }

    .section-greentxt {
      margin-bottom: 1rem;
    }
  }
}

@media (max-width:767.98px) {
  .heroSec {
    &__count {
      padding: 0 5%;
      flex-flow: column nowrap;
      gap: 0.12rem;
      bottom: 0.5rem;

      &>div>div {
        &:first-child {
          margin-bottom: 0;
          font-size: 20px;
        }

        &:last-child {
          font-size: 10px;
        }
      }
    }

    &__deco2 {
      top: 12%;
      width: 18%;
      left: 6%;
    }

    &__inner {
      padding-top: calc(100px * var(--resp));
      max-width: calc(100% - 1.5rem);
      margin-bottom: 0;
    }

    &__left,
    .section-head1 {
      max-width: 100%;
    }

    .section-desc {
      max-width: 85%;
      font-size: calc(16px * var(--resp));
    }

    .section-greentxt {
      font-size: calc(18px * var(--resp));
    }

    &__deco4-wrapper {
      max-width: 25%;
      padding-left: 3rem;
    }

    &__deco0 {
      top: unset;
      bottom: 5rem;
      width: 100%;
      margin-right: -1rem;
    }

    // &__stats {
    //   right: 12.5%;
    // }

    &__stats {
      right: 18.5%;
      bottom: 10.25%;
      width: 44%;

      &-line {
        bottom: 7%;
        left: unset;
        transform: unset;
        width: 2rem;
        right: 0.1rem;
      }
    }

    &__icons {
      top: 0%;
      padding: 7% 9%;

      &>span>div {
        margin-top: 2px;

        span {
          padding: 1px 3px 0;
          border-radius: 2px;
          font-size: 7.5px;
        }
      }
    }

    .button {
      font-size: calc(14px * var(--resp));
      min-width: calc(200px * var(--resp));
    }
  }
}