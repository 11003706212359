/* happySec section */
.happySec {
  text-align: center;
  position: relative;
  padding-block: 100px;

  &__img1 {
    width: calc(106px * var(--resp));
  }

  &__deco2 {
    position: absolute;
    left: 18%;
    top: 5%;
  }

  .section-head1 {
    max-width: 90%;
    margin-inline: auto;
  }


}

@media (max-width:1800px) {}

@media (max-width:1600px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {}

@media (max-width:767.98px) {
  .happySec {
    padding-block: 50px;

     &__deco2{
      display: none;
     }

    .section-head1 {
      font-size: 26.5px !important;
    }

    &__img1 {
      margin-inline: auto;
      width: calc(85px * var(--resp));
    }

    .section-desc {
      margin-top: 0.5rem;
      font-family: "Playfair Display";
    }
  }

  .happySec .section-head1 {
    max-width: 100%;
  }
}