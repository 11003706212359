/* Menu section */
.menuSec {
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 111;
    opacity: 0;
    visibility: hidden;
    z-index: -111;
    pointer-events: none;
    transition: 0.5s ease;
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    background: rgba(255, 255, 255, 0.5);

    &__inner {
        background-color: var(--col2);
        position: absolute;
        top: 10px;
        right: 10px;
        left: 10px;
        bottom: 10px;
        overflow-y: auto;
        transition-timing-function: cubic-bezier(1, 0, 1, 1);
        transition-duration: 0.65s;
        transition-delay: 0s;
        transform: translateX(100vw);
        border-radius: 14px;
        width: calc(100vw - 20px);
        height: calc(100vh - 20px);
        padding: 15px;

        a {
            color: var(--col2);
        }

        background-image: url('/public/landing_images/menu/bg1.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    &.active {
        background: rgba(255, 255, 255, 0.5);

    }

    &.active,
    &.animating {
        opacity: 1;
        visibility: visible;
        z-index: 1111;
        pointer-events: all;
    }

    &.active &__inner {
        transition-timing-function: cubic-bezier(0, 1, 1, 1);
        transition-duration: 0.65s;
        transition-delay: 0s;
        transform: translateX(0);
    }

    @media (min-width: 992px) {
        display: none;
    }

    &__head {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        padding: 20px 20px 0;
        text-align: center;
        font-size: 14px;
        display: flex;
        justify-content: space-between;

        img {
            display: inline-block;
        }

        .menu-btn {
            display: inline-flex;
            height: 30px;
            padding: 10px;
            align-items: center;
            gap: 5px;
            font-weight: 700;
            font-size: 14px;
        }

        &-logo svg {
            height: calc(47.5px * var(--resp));
            width: calc(174px * var(--resp));
        }

        &-desc {
            text-transform: capitalize;
        }
    }

    &__links {
        position: relative;
        list-style-type: none;
        margin: 0;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        li {
            margin-bottom: 12.5px;
            position: relative;
            padding: 0 0 0 0;

            a {
                display: block;
                position: relative;
                font-weight: 400;
                color: #fff;
                text-transform: uppercase;
                font-size: 12px;

                &.active {
                    color: var(--col3);
                }
            }
        }
    }

    &__deco1 {
        margin-top: 20px;
        margin-bottom: 10px;

        img {
            height: 65px;
            display: block;
            margin-inline: auto;
        }



    }

    &__logo-wrap {
        text-align: center;
        margin-bottom: 25px;

        img {
            margin-bottom: 10px;
            display: inline-block;
            height: 43px;
        }
    }

    &__head-desc {
        color: #63727E;
        font-size: 10px;
    }

    &__buttons {
        display: flex;
        gap: 10px;

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            min-width: calc(161px * var(--resp));
            min-height: calc(49px * var(--resp));
            line-height: 1;
            border: 1px solid transparent;
            border-radius: calc(2px * var(--resp));
            font-size: calc(12px * var(--resp));
            font-family: var(--ff1);
            font-weight: 600;
            letter-spacing: calc(1px * var(--resp));
            text-transform: uppercase;
            padding-inline: calc(15px * var(--resp));
            color: var(--col3);

            &.headerSec__buttons--green {
                background-color: var(--col3);
                color: var(--col1);
            }
        }


    }

    &__footer {
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 15px;
        text-align: center;
        border-top: 1px solid #63727E;
        padding-top: 20px;

        &::before {
            content: '';
            position: absolute;
            right: -10px;
            top: 0;
            width: 51px;
            height: 52px;
            background: url('/public/landing_images/menu/bg2.png') no-repeat center center;
            background-size: contain;
            opacity: 0.25;
        }

        &-title {
            color: #fff;
            font-family: var(--ff2);
            font-size: 29px;
            font-weight: bold;
            line-height: 1.1;
            margin-bottom: 15px;
            text-transform: capitalize;
        }

        &-hands {
            margin-bottom: 10px;
        }

        &-follow {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-bottom: 15px;
        }

        &-btns {
            text-align: center;


            .menuSec__buttons {
                display: inline-flex;
                border: 1px solid #fff;
                padding: 4px;
                border-radius: 2px;

                &--green {
                    background-color: #36B8A3;
                }

                a {
                    color: #fff;
                }
            }
        }
    }

    &__links {
        ul {
            li:first-child {
                position: relative;
                padding-top: 15px;

                &::before {
                    content: '';
                    height: 1px;
                    width: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: #F3F6F8;
                }
            }
        }
    }
}



.menu-btn--hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 30px;
    height: 30px;
    padding-block: 3.5px;
}

.menu-btn--cross {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    width: 30px;
    height: 30px;
}

.no-scroll {
    overflow: hidden;
}

@media (min-height:700px) {
    .menuSec__links ul {
        padding-inline: 10%;
    }

    .menuSec__links ul li:first-child {
        padding-top: 25px;
    }

    .menuSec__links li {
        margin-bottom: 20px;
    }

    .menuSec__footer {
        padding-top: 25px;
    }

    .menuSec__footer-hands {
        margin-bottom: 15px;
    }

    .menuSec__footer-btns {
        margin-bottom: 10px;
    }
}

@media (min-height:800px) {
    .menuSec__links ul li:first-child {
        padding-top: 30px;
    }

    .menuSec__links li {
        margin-bottom: 30px;
    }

    .menuSec__footer {
        padding-top: 30px;
    }

    .menuSec__footer-hands {
        margin-bottom: 25px;
    }

    .menuSec__footer-btns {
        margin-bottom: 20px;
    }

    .menuSec__footer-title {
        margin-bottom: 25px;
    }

    .menuSec__footer-follow {
        margin-bottom: 25px;
    }

    .menuSec__deco1 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .menuSec__head-desc {
        margin-top: 10px;
    }

    .menuSec__deco1 img {
        height: 80px;
    }
}

@media (max-width:991.98px) {
    .menuSec__head .menu-btn {
        padding: 4px;
        margin-right: -0.3rem !important;
    }

    .menuSec__footer-hands {
        img {
            margin-inline: auto;
        }
    }

    .menuSec__links li a.active {
        font-weight: 600;
    }
}