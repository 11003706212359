/* Variables */
.ff1 {
  font-family: 'Poppins', sans-serif;
}

.ff2 {
  font-family: 'Playfair Display', serif;
}

.ff3 {
  font-family: 'Bebas Neue', sans-serif;
}

:root {

  /* Fonts */
  --ff1: 'Poppins', sans-serif;
  --ff2: 'Playfair Display', serif;
  --ff3: 'Bebas Neue', sans-serif;


  /* Colors */
  --col0: #000;
  --col1: #fff;
  --col2: #20272c;
  --col3: #36b8a3;
  --col4: #5c5c5c;


  // Multiple for responsive
  --resp: 1;

  @media (min-width: 2200px) {
    // 2k screen
    --resp: 1.3;
  }

  @media (min-width: 3000px) {
    // 4k screen
    --resp: 2;
  }

  @media (max-width: 1800px) {
    --resp: 0.95;
  }

  @media (max-width: 1400px) {
    --resp: 0.85;
  }

  @media (max-width: 1300px) {
    --resp: 0.8;
  }

  @media (max-width: 767.98px) {}

  /* Font sizes */
  --fs9: 9px;
  --fs10: 10px;
  --fs11: 11px;
  --fs12: 12px;
  --fs13: 13px;
  --fs14: calc(14px * var(--resp));
  --fs15: calc(15px * var(--resp));
  --fs16: calc(16px * var(--resp));
  --fs18: calc(18px * var(--resp));
  --fs20: calc(20px * var(--resp));
  --fs22: calc(22px * var(--resp));
  --fs24: calc(24px * var(--resp));
  --fs26: calc(26px * var(--resp));
  --fs28: calc(28px * var(--resp));
  --fs30: calc(30px * var(--resp));
  --fs32: calc(32px * var(--resp));
  --fs38: calc(38px * var(--resp));
  --fs40: calc(40px * var(--resp));
  --fs45: calc(45px * var(--resp));
  --fs46: calc(46px * var(--resp));
  --fs50: calc(50px * var(--resp));
  --fs55: calc(55px * var(--resp));
  --fs59: calc(59px * var(--resp));
  --fs70: calc(70px * var(--resp));
  --fs72: calc(72px * var(--resp));
  --fs76: calc(76px * var(--resp));

  @media (max-width: 1800px) {
    --fs72: calc(72px * 0.75);
    --fs76: calc(76px * 0.75);
  }

  @media (max-width: 1300px) {
    --fs72: calc(72px * 0.65);
    --fs76: calc(76px * 0.65);
  }

  @media (max-width: 991.98px) {
    --fs9: 8px;
    --fs10: 9px;
    --fs11: 10px;
    --fs12: 11px;
    --fs14: 13px;
    --fs16: 14px;
    --fs18: 16px;
    --fs20: 18px;
  }
}