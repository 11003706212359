/* Header */
.headerSec {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    transition: height 0.5s;
    z-index: 11;
    display: flex;
    align-items: center;
    height: calc(165px * var(--resp));
    background: transparent;

    &__buttons {
        display: flex;
        gap: 10px;

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            min-width: calc(161px * var(--resp));
            min-height: calc(49px * var(--resp));
            line-height: 1;
            border: 1px solid transparent;
            border-radius: calc(2px * var(--resp));
            font-size: calc(12px * var(--resp));
            font-family: var(--ff1);
            font-weight: 600;
            letter-spacing: calc(1px * var(--resp));
            text-transform: uppercase;
            padding-inline: calc(15px * var(--resp));
            color: var(--col3);

            &.headerSec__buttons--green {
                background-color: var(--col3);
                color: var(--col1);

                &:hover {
                    border-color: #389787;
                    background-color: #389787;
                }
            }

            &:hover {
                border-color: #389787;
            }
        }


    }

    &.scrolling {
        height: calc(80px * var(--resp));
        -webkit-backdrop-filter: blur(7.5px);
        backdrop-filter: blur(7.5px);
        background: rgba(255, 255, 255, 0.5);
    }

    &__logo {
        height: calc(47.5px * var(--resp));
        padding-inline: 0px;
        margin-left: -0.5%;

        img,
        svg {
            height: calc(47.5px * var(--resp));
            width: calc(174px * var(--resp));
            position: relative;
            z-index: 1;
            user-select: none;
        }
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
    }

    ul {
        list-style-type: none;

        margin: 0;
        display: flex;
        align-items: center;
        gap: 50px;

        li {
            margin: 0;

            a {
                line-height: 0;
                margin: 0;
                transition: 0.23s ease-out;
                height: calc(45px * var(--resp));
                display: flex;
                align-items: center;
                justify-content: center;

                &.active {
                    color: var(--col3);
                }


                &:hover {
                    color: var(--col3);
                }
            }
        }
    }


    .menu-btn {
        min-width: auto;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon1 {
            width: 26px;
        }
    }

}


@media (max-width: 1200px) {
    .headerSec ul {
        gap: 15px;
    }
}

@media (max-width:991.98px) {
    .headerSec .menu-btn {
        padding: 0;
        margin-top: 1rem;
    }
}

@media (max-width: 767.98px) {
    .headerSec__logo {
        height: calc(45px * var(--resp));
        margin-left: 7px;
    }

    .headerSec__logo img,
    .headerSec__logo svg {
        height: calc(45px * var(--resp));
    }
}