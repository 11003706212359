/* Footer */
.footerSec {
  margin-bottom: calc(100px * var(--resp));

  &__inner {
    background-color: var(--col2);
    border-radius: calc(14px * var(--resp));
    padding: calc(50px * var(--resp)) calc(50px * var(--resp)) calc(100px * var(--resp));
    color: var(--col1);
    background-image: url('/public/landing_images/footer/deco4.svg'), url('/public/landing_images/footer/deco3.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: top right, bottom left;
    background-size: calc(569px * var(--resp)) auto, calc(536px * var(--resp)) auto;

    a {
      color: var(--col1);
    }

    position: relative;
  }

  &__buttons {
    display: flex;
    width: calc(338px * var(--resp));
    border: 1px solid #FFFFFF;
    padding: calc(5px * var(--resp));
    justify-content: center;
    gap: 5px;
    border-radius: 2px;
    margin-bottom: calc(30px * var(--resp));
  }

  &__logo {
    img {
      width: calc(203px * var(--resp));
    }

    margin-bottom: calc(20px * var(--resp));
    display: inline-block;
  }

  &__desc {
    color: #63727e;
    text-transform: capitalize;
    max-width: 70%;
    border-bottom: 1px solid #63727e;
    padding-bottom: calc(10px * var(--resp));
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: calc(14px * var(--resp));
    position: relative;

    li {
      margin-bottom: calc(20px * var(--resp));
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;

      &:last-child {
        padding-bottom: 20px;

        &::after {
          content: '';
          position: absolute;
          width: 100px;
          height: 2px;
          background-color: #fff;
          bottom: 0;
          left: 0;
        }
      }
    }

    h3 {
      font-size: calc(14px * var(--resp));
      color: var(--col3);
      margin-bottom: calc(20px * var(--resp));
      letter-spacing: 1px;
    }
  }

  &__copy {
    color: #63727E;
    position: absolute;
    right: 2.5%;
    bottom: 5%;
    display: flex;
    align-items: end;
    gap: calc(10px * var(--resp));
    text-transform: capitalize;
    font-size: calc(14px * var(--resp));

    img {
      user-select: none;
      pointer-events: none;
    }
  }

  &__follow {
    color: #FF5E48;
    display: flex;
    align-items: center;
    gap: calc(15px * var(--resp));
    margin-top: calc(30px * var(--resp));
    margin-bottom: calc(20px * var(--resp));

    img {
      width: calc(35px * var(--resp));
    }
  }
}

@media (max-width:991.98px) {
  .footerSec__desc {
    max-width: 100%;
  }

  .footerSec__inner {
    padding: 20px;
  }

  .footerSec__copy {
    right: 2.5%;
    bottom: 5px;
  }

  .footerSec__botMob {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerSec__logo {
    img {
      width: calc(203px * var(--resp));
    }
  }

  .footerSec__copy img {
    opacity: 0.5;
  }
}

@media (max-width:767.98px) {
  .footerSec {
    margin-bottom: calc(20px * var(--resp));

    &__inner {
      padding-block: 60px 10px;

      .col-6 {
        width: 44%;

        &:nth-of-type(2) {
          width: 56%;
        }
      }

      &__desc {
        font-size: 0.725rem;
      }
    }

    &__copy {
      min-width: 100%;
      position: relative;
      justify-content: center;
      align-items: center;

      img {
        position: absolute;
        right: -0.8rem;
        top: -2rem;
      }
    }

    ul li:last-child {
      margin-bottom: 3rem;

      &::after {
        width: 70px;
        height: 1px;
      }
    }

    &__follow {
      margin-top: 0;
    }
  }
}