/* Buttons */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: calc(161px * var(--resp));
  min-height: calc(49px * var(--resp));
  line-height: 1;
  border: 1px solid #090335;
  background-color: var(--col1);
  border-radius: calc(2px * var(--resp));
  font-size: calc(12px * var(--resp));
  font-family: var(--ff1);
  font-weight: 600;
  letter-spacing: calc(1px * var(--resp));
  text-transform: uppercase;
  padding-inline: calc(15px * var(--resp));

  &-dark {
    background-color: #090335;
    color: var(--col1);
    transition: 0.23s ease-out;

    &:hover {
      background-color: #150d52;
      color: var(--col1);
    }
  }

  &-green {
    background-color: #36B8A3;
    color: var(--col1);
    transition: 0.23s ease-out;

    &:hover {
      background-color: #30ceb3;
      color: var(--col1);
    }
  }

  &-transparent {
    background-color: transparent;
    border-color: transparent;
    color: var(--col1);
    transition: 0.23s ease-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
      color: var(--col1);
    }
  }

}

// Toggle switch button
.switch {
  position: relative;
  display: inline-block;
  width: 2.8125rem;
  height: 1.375rem;
  border-radius: 0.9375rem;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--Carriben-Light-Green, #E3FAF6);
    box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.10);
    backdrop-filter: blur(2px);
    -webkit-transition: .4s;
    transition: .4s;

  }

  .slider:before {
    position: absolute;
    content: "";
    width: 1rem;
    height: 1rem;
    left: 0.18rem;
    bottom: 0.18rem;
    border-radius: 0.9375rem;
    background: var(--Caribbean-Green, #36B8A3);
    box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.10), 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider:before {
    background: #FF584B;
  }

  input:checked+.slider {
    border-radius: 0.9375rem;
    background: rgba(255, 88, 75, 0.16);


  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(1.35rem);
    -ms-transform: translateX(1.35rem);
    transform: translateX(1.35rem);
  }

  /*------ ADDED CSS ---------*/
  .on {
    display: none;
    right: 35%;
  }

  .off {
    left: 65%;
  }

  .on,
  .off {

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    user-select: none;
    color: var(--Body-text, #63727E);
    font-family: Poppins;
    font-size: 0.5625rem;
    opacity: 0.3;
    font-weight: 500;

  }

  input:checked+.slider .on {
    display: block;
  }

  input:checked+.slider .off {
    display: none;
  }

  /*--------- END --------*/

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

}




@media (max-width: 1800px) {}

@media (max-width: 1400px) {}

@media (max-width: 1300px) {}

@media (max-width: 991.98px) {}