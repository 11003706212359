/* howSec section */
.howSec {
  position: relative;

  .container {
    position: relative;
  }

  &__clock-wrap {
    text-align: center;
  }

  &__clock {
    width: calc(122px * var(--resp));
    user-select: none;
    pointer-events: none;
  }

  &__star1 {
    position: absolute;
    left: 0%;
    top: 0%;
    width: calc(55px * var(--resp));
    user-select: none;
    pointer-events: none;
  }

  &__star2 {
    position: absolute;
    left: 58%;
    top: calc(55px * var(--resp));
    width: calc(30px * var(--resp));
    user-select: none;
    pointer-events: none;
  }

  .section-head1 {
    text-align: center;
    max-width: 50%;
    margin-inline: auto;
  }

  &__buttons {
    text-align: center;
  }

  &__img1 {
    width: calc(405px * var(--resp) * 1.3);
    margin-bottom: calc(25px * var(--resp));
  }

  &__img2 {
    width: calc(480px * var(--resp) * 1.3);
    margin-bottom: calc(25px * var(--resp));
  }

  &__img3 {
    width: calc(395px * var(--resp) * 1.3);
    margin-bottom: calc(25px * var(--resp));
  }

  &__img4 {
    width: calc(401px * var(--resp) * 1.3);
    margin-bottom: calc(25px * var(--resp));
  }

  h3 {
    font-family: var(--ff2);
    font-size: calc(22px * var(--resp));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  &__text {
    padding-left: 12.5%;
    width: 60%;
  }

  &__box {
    text-align: left;
    position: relative;
  }

  &__box-2 {
    margin-top: 45%;
    text-align: right;

    .howSec__text {
      margin-left: auto;
      text-align: left;
      margin-right: 20%;
    }
  } 

  &__box-4 {
    margin-top: 45%;
    text-align: right;

    .howSec__img4 {
      margin-right: calc(100px * var(--resp));
    }

    .howSec__text {
      margin-left: auto;
      text-align: left;
      margin-right: 20%;
    }
  }

  &__row1 {
    position: relative;
    z-index: 1;
  }

  &__row2 {
    margin-top: -15%;
  }

  &__arrow1 {
    position: absolute;
    right: -18%;
    bottom: 31%;
    width: calc(190px * var(--resp) * 1.3);
  }

  &__arrow2 {
    position: absolute;
    left: -10%;
    bottom: 0%;
    width: calc(152px * var(--resp) * 1.3);
  }

  &__arrow3 {
    position: absolute;
    right: 0%;
    bottom: -10%;
    width: calc(171px * var(--resp) * 1.3);
  }
} 

@media (max-width:1800px) {
  .howSec {
    --resp: 0.8;
  }
}

@media (max-width:1400px) {
  .howSec {
    --resp: 0.75;
  }
}

@media (max-width:1300px) {
  .howSec {
    --resp: 0.7;
  }
}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {
  .howSec__img1 {
    width: calc(355px * var(--resp) * 1.3);
  }

  .howSec__img2 {
    width: calc(420px * var(--resp) * 1.3);
  }

  .howSec__img3 {
    width: calc(345px * var(--resp) * 1.3);
  }

  .howSec__img4 {
    width: calc(351px * var(--resp) * 1.3);
  }

  .howSec {
    &__clock {
      margin: auto;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .howSec__arrow1 {
    right: -29%;
    bottom: 50%;
    width: calc(160px * var(--resp) * 1.3);
  }
}

@media (max-width:767.98px) {
  .howSec__box {
    margin-top: 50px;

    &.howSec__box-1 {
      margin-top: 30px;
    }
  }

  .howSec {
    padding-bottom: 60px;

    .section-head1 {
      max-width: 100%;
    }

    &__buttons {
      margin-bottom: 20px;
    }

    &__img1,
    &__img2,
    &__img3,
    &__img4 {
      width: 78%;
      margin-inline: 0.6rem;
    } 

    &__img2,
    &__img4 {
      margin-inline: auto 0rem;
    }

    &__box-2,
    &__box-4 {
      margin-top: 30px;

      .howSec__text {
        padding-left: 0;
      }
    }

    &__box-3 {
      margin-top: 65px;
    }

    h3 {
      font-size: 1.2rem;
    }

    &__text {
      padding-left: 1rem;
      width: 75%;
    }
  }

  .howSec__row2 {
    margin-top: 0;
  }

  .howSec__arrow1 {
    bottom: 0;
    right: 0;
    width: calc(125px* var(--resp)* 1.3);
    transform: rotate(65deg);
  }

  .howSec__arrow2 {
    width: calc(120px* var(--resp)* 1.3);
    transform: rotate(285deg);
    left: 0rem;
    bottom: -1.5rem;
  }

  .howSec__arrow3 {
    right: 1%;
    bottom: -7%;
    width: calc(120px* var(--resp)* 1.3);
    transform: rotateX(180deg) rotateZ(280deg);
  }

  .howSec__box-4 .howSec__img4 {
    margin-right: 0;
  }

  .howSec__box-4 .howSec__text,
  .howSec__box-2 .howSec__text {
    margin-left: 30%;
  }

  .howSec__star2 {
    left: 90%;
    top: calc(380px* var(--resp));
  }
}