// Common Properties //

// Transitions //
.ts {
  &02 {
    @include ts(0.2s);
  }

  &03 {
    @include ts(0.3s);
  }

  &04 {
    @include ts(0.4s);
  }

  &05 {
    @include ts(0.5s);
  }

  &06 {
    @include ts(0.6s);
  }
}

.form {

  &-control,
  &-select {
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

a {
  text-decoration: none;
}

button {
  border: 0;

  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
}

.greenText {}

.redText {
  color: var(--fail);
}

.blueText {
  color: #3276E8;
}

.whiteText {
  color: var(--neutral-02);
}

.grayText {
  color: var(--neutral-01);
}

.lightGrey {
  color: #BFC4C7;
}

.primaryText {
  color: var(--text-theme);
}

.primaryDarkText {
  color: var(--text-dark);
}

.greenThemeText {
  color: var(--caribbean-green);
}

.lightgreenThemeText {
  color: var(--caribbean-light-green);
}

.mustardText {
  color: var(--mustard);
}

.cursor-pointer {
  cursor: pointer !important;
}


// .gradientText {
//   @extend %gradientText !optional;
// }


// Common borders //
.bdr-gray {
  border: 1px solid var(--neutral-02);
}

// Blur filter //
.blur-2 {
  backdrop-filter: blur(2px);
}

.blur-7 {
  backdrop-filter: blur(7px);
}

// Common shadows //
.shadow-34-05 {
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
}

.shadow-15-11-08 {
  box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
}


// Common bd-radii //
.bdrs10 {
  border-radius: 0.625rem;
}

.bdrs12 {
  border-radius: 0.75rem;
}

// Common backgrounds //
.bg-w-a49 {
  background: rgba(255, 255, 255, 0.49);
}

.bg-lightGrey {
  background: #F4F3F3
}

.bg-lightPink {
  background: #FFDDDA;
}

.bg-lightGreen {
  background: #E3FAF6
}

// Common blocks //
.blurred-widget-block {
  border-radius: 0.9375rem;
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
}

/* Chrome/Safari and web-kit-based browsers (if it doesn't work, try maybe also on the parent/wrapper) */
::-webkit-file-upload-button {
  cursor: pointer;
}

/* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
input[type="file"] {
  cursor: pointer;
}

// Scrollbar - custom //
::-webkit-scrollbar {
  width: 3px;
  z-index: 999999;
  position: relative;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
  margin-block: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: var(--text-dark);
}

/* Handle on hover and active */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: var(--primary-dark);
}

/* End of scrollbar style */