// Inner screens' layout
.page-layout {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 2rem 2.37rem 1rem;
  background: url("/public/images/layout-blob-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .header {
    @include flexBox(flex, null, center, null);
    gap: 1.25rem;

    &__left {
      width: 220px;
      flex: 0 0 220px;
      @include flexBox(inline-flex, column nowrap, center, null);
      gap: 0.5rem;

      text-align: center;
      color: var(--neutral-01);
    }

    &__right {
      @include flexBox(flex, null, center, space-between);
      gap: 1rem;
      flex: 1;

      .form-control {
        padding-left: 4.2rem;

        &::placeholder {
          color: var(--neutral-03);
        }
      }

      &--with-link {
        padding-inline: 0.56rem;

        .form-group--search {
          background: transparent;
          box-shadow: none;
          padding-inline: 0;
        }
      }
    }
  }

  .content-layout {
    padding: 1rem;

    &__inner {
      @include flexBox(flex, null, null, null);
      gap: 1.5rem;
    }
  }
}

.user-info {
  @include flexBox(inline-flex, null, center, null);
  gap: 0.81rem;

  &__dp {
    position: relative;
    background: transparent;

    &:focus {
      outline: 0;
    }

    &-inner {
      @include flexBox(inline-flex, null, center, center);
      width: 3.5625rem;
      height: 3.5625rem;
      padding: 5px;
      border-radius: 0.9375rem;
      box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);


    }

    img.dp-img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
      width: 3.5625rem;
      height: 3.5625rem;
    }

    span {
      width: 100%;
      height: 100%;

      &.initials {
        background: url(/public/images/user-dp-bg.svg) no-repeat center;
        background-size: cover;
        padding-top: 1px;
        @include flexBox(inline-flex, null, center, center);
      }
    }

    b.arrow {
      @include flexBox(inline-flex, null, flex-end, center);

      position: absolute;
      left: 50%;
      bottom: -0.6rem;
      padding: 0.1rem 0 0.25rem;
      transform: translateX(-50%);

      border-radius: 0 0 3rem 3rem;
      width: 1.6rem;
      height: 0.8rem;
    }
  }

  .dropdown-toggle::after {
    display: none;
    margin: 0;
  }

  .dropdown-menu {
    margin-top: 0.65rem !important;
    box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);

    a {
      color: var(--text-theme);
      padding: 0.6rem 1rem;
    }
  }

  &__name {
    padding-top: 0.65rem;
    @include flexBox(inline-flex, column nowrap, null, null);

    h6 {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.25rem;

      img {
        margin: -0.8rem -0.35rem;
      }
    }

    p {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.3rem;

      span {
        line-height: 1;
      }
    }
  }
}

.link-to-previous {
  height: 2.8125rem;
  display: flex;
  min-width: 12.4375rem;
  background-color: var(--text-dark);
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
  @include flexBox(inline-flex, null, center, null);

  .icon-wrap {
    height: 100%;
    width: 2.86825rem;
    background-color: var(--caribbean-green);
    @include flexBox(inline-flex, null, center, center);
  }

  span {
    padding-inline: 0.575rem;
  }

  &:hover,
  &:focus,
  &:active {
    .icon-wrap {
      img {
        scale: 1.1;
      }
    }
  }
}

aside {
  @include flexBox(flex, column nowrap, null, null);
  gap: 1.2rem;
  margin-top: 0.85rem;

  width: 13.75rem;
  flex: 0 0 13.75rem;

  position: sticky;
  top: -0.8rem;
  max-height: calc(100vh - 7.2rem);

  nav.navigation {
    @include flexBox(flex, column nowrap, null, null);
    gap: 0.1rem;

    padding: 1rem 0.75rem;

    .nav-links {
      padding: 1rem 0.8rem 1rem 1.125rem;
      position: relative;
      display: flex;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        @include ts(0.3s);
        border-radius: 0.625rem;
        background-position: 135%;
      }

      &__inner {
        @include flexBox(flex, null, center, null);
        gap: 0.6rem;
        position: relative;
        z-index: 2;
      }

      span {
        width: 1.5rem;
        height: 1.5rem;
        @include flexBox(inline-flex, null, center, center);

        img {
          scale: 1;
          @include ts(0.3s);
        }
      }

      strong {
        font-weight: 400;
        color: var(--text-theme);
      }

      &--active {
        &::after {
          background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat right center;
          background-color: #FFF;
          background-position: 120%;
          box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.03);
          backdrop-filter: blur(2px);
          z-index: 1;
        }

        span {
          img {
            scale: 1.2;
          }
        }

        strong {
          font-weight: 500;
        }
      }
    }
  }

  .green-theme-block {
    width: 100%;
    min-height: 15.875rem;
    background: url(/public/images/bg-particles/below-aside-navigation-theme-block-bg.svg) no-repeat center;
    background-size: contain;

    @include flexBox(flex, column nowrap, center, center);
    gap: 0.5rem;

    h2 {
      font-size: 1rem;
    }

    p {
      color: var(--neutral-01);
      text-align: center;
      padding: 0 1.5rem;
      font-size: 10px;
    }

    .btn {
      width: 9.5625rem;
      height: 2.625rem;
      margin-block: 0.4rem 0.8rem;

      span {
        margin-top: 1px;
      }
    }


  }

  &.no-nav-feature-block {
    width: 17.1875rem;
    flex: 0 0 17.1875rem;
    position: static;
  }
}

// Auth screens' layout 
.auth-layout {
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  @include flexBox(flex, null, null, null);

  background: url("/public/images/auth-blob-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &__left {
    max-width: 45%;
    flex: 0 0 45%;
  }

  &__right {
    max-width: 55%;
    flex: 0 0 55%;

    border: 3px solid #FFF;
    background: rgba(255, 255, 255, 0.68);
    box-shadow: -8px -14px 45px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(26px);
  }
}

@media (max-width: 991.98px) {
  .auth-layout {
    background-image: url("/public/images/bg-auth-form-screen.svg");

    &__left {
      flex: 1;
      max-width: 100%;
    }

    &__right {
      display: none;
    }

    &--splash {
      overflow-y: auto;

      background: url("/public/images/bg-splash-screen.svg") no-repeat center center fixed;
      background-size: cover;

      .container {
        height: 100vh;
        padding-inline: 1.5rem;
        @include flexBox(flex, column nowrap, null, null);
      }

      .tnc-pp-links {
        width: 100%;
        margin-block: 1.125rem 2rem;
        @include flexBox(flex, null, center, space-between);
      }

      .logo {
        max-width: 7.896rem;
        margin: 2.25rem auto;
      }

      .splash-inner {
        flex: 1;
        padding-bottom: min(12vh, 8rem);

        @include flexBox(flex, column nowrap, center, center);
      }

      .sub-help-info {
        padding-left: 0;
        width: 100%;
      }

      .to-auth-btn {
        position: absolute;
        bottom: 6.85rem;
        right: 1.5rem;
        width: 53px;
        height: 53px;
        border-radius: 8px;
        padding: 0.5rem;
        @include flexBox(inline-flex, null, center, center);

        span {
          @include flexBox(inline-flex, null, center, center);
          border-radius: 5px;
          width: 100%;
          height: 100%;
          background-color: #fff;
        }
      }
    }
  }
}