/* Partials and Commons */
/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
/* Variables */
.ff1 {
  font-family: "Poppins", sans-serif;
}

.ff2 {
  font-family: "Playfair Display", serif;
}

.ff3 {
  font-family: "Bebas Neue", sans-serif;
}

:root {
  /* Fonts */
  --ff1: "Poppins", sans-serif;
  --ff2: "Playfair Display", serif;
  --ff3: "Bebas Neue", sans-serif;
  /* Colors */
  --col0: #000;
  --col1: #fff;
  --col2: #20272c;
  --col3: #36b8a3;
  --col4: #5c5c5c;
  --resp: 1;
  /* Font sizes */
  --fs9: 9px;
  --fs10: 10px;
  --fs11: 11px;
  --fs12: 12px;
  --fs13: 13px;
  --fs14: calc(14px * var(--resp));
  --fs15: calc(15px * var(--resp));
  --fs16: calc(16px * var(--resp));
  --fs18: calc(18px * var(--resp));
  --fs20: calc(20px * var(--resp));
  --fs22: calc(22px * var(--resp));
  --fs24: calc(24px * var(--resp));
  --fs26: calc(26px * var(--resp));
  --fs28: calc(28px * var(--resp));
  --fs30: calc(30px * var(--resp));
  --fs32: calc(32px * var(--resp));
  --fs38: calc(38px * var(--resp));
  --fs40: calc(40px * var(--resp));
  --fs45: calc(45px * var(--resp));
  --fs46: calc(46px * var(--resp));
  --fs50: calc(50px * var(--resp));
  --fs55: calc(55px * var(--resp));
  --fs59: calc(59px * var(--resp));
  --fs70: calc(70px * var(--resp));
  --fs72: calc(72px * var(--resp));
  --fs76: calc(76px * var(--resp));
}
@media (min-width: 2200px) {
  :root {
    --resp: 1.3;
  }
}
@media (min-width: 3000px) {
  :root {
    --resp: 2;
  }
}
@media (max-width: 1800px) {
  :root {
    --resp: 0.95;
  }
}
@media (max-width: 1400px) {
  :root {
    --resp: 0.85;
  }
}
@media (max-width: 1300px) {
  :root {
    --resp: 0.8;
  }
}
@media (max-width: 1800px) {
  :root {
    --fs72: calc(72px * 0.75);
    --fs76: calc(76px * 0.75);
  }
}
@media (max-width: 1300px) {
  :root {
    --fs72: calc(72px * 0.65);
    --fs76: calc(76px * 0.65);
  }
}
@media (max-width: 991.98px) {
  :root {
    --fs9: 8px;
    --fs10: 9px;
    --fs11: 10px;
    --fs12: 11px;
    --fs14: 13px;
    --fs16: 14px;
    --fs18: 16px;
    --fs20: 18px;
  }
}

/* Common */
.fade-in {
  opacity: 0;
}

html {
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  background-color: var(--col1);
  color: var(--col2);
  min-height: 100vh;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: var(--fs15);
  font-family: var(--ff1);
  overflow-x: hidden;
}
body a {
  text-decoration: none;
  color: var(--col2);
  transition: 0.23s ease-out;
}
body a:hover {
  color: var(--col3);
}
body .landing {
  position: relative;
  z-index: 1;
}
body .landing .section-head1 img {
  display: inline-block;
}

.color-green {
  color: var(--col3);
}

.mobile-only {
  display: none !important;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 80vw;
  }
}
@media (max-width: 991.98px) {
  .mobile-only {
    display: block !important;
  }
  .desk-only {
    display: none;
  }
  .landing .button-dark {
    min-height: 2.65rem;
    padding: 1rem 1.2rem;
    font-size: 10px;
  }
}
/* Keyframes */
@keyframes fadeInAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes showUpwards {
  0% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@keyframes showDownwards {
  0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@keyframes showLeftwards {
  0% {
    -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
            clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@keyframes showRightwards {
  0% {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
@keyframes animToRight {
  0% {
    opacity: 0;
    transform: translateX(-5vw);
  }
  15% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 0;
    transform: translateX(5vw);
  }
  100% {
    opacity: 0;
    transform: translateX(5vw);
  }
}
.anim-to-right {
  animation-play-state: paused;
  opacity: 0;
  animation: animToRight 10s linear infinite forwards;
}

.section--active .anim-to-right {
  animation-play-state: play;
}

@keyframes animZoomIn {
  0% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  30% {
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
  75% {
    opacity: 1;
    transform: scale(1) rotate(360deg);
  }
  90% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
}
.anim-zoom-in {
  animation-play-state: paused;
  opacity: 0;
  animation: animZoomIn 10s linear infinite forwards;
}

.section--active .anim-zoom-in {
  animation-play-state: play;
}

@keyframes animClickArrow {
  0%, 2.5%, 97.5%, 100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  45%, 50% {
    transform: rotate(-10deg) translate3d(20px, -20px, 0);
  }
}
.anim-click-arrow {
  transform-origin: top right;
  animation-play-state: paused;
  animation: animClickArrow 5s ease-in-out infinite forwards;
}

.section--active .anim-click-arrow {
  animation-play-state: play;
}

@keyframes animUpDown {
  0%, 100% {
    transform: translateY(25px);
  }
  50% {
    transform: translateY(-25px);
  }
}
.anim-up-down {
  transform: translateY(25px);
  animation: animUpDown 5s ease-in-out forwards infinite;
  animation-play-state: paused;
}

.section--active .servicesSec__tooltip-up-down {
  animation-play-state: running;
}

/* Typos */
.section-head1 {
  font-family: var(--ff2);
  font-weight: bold;
  font-size: calc(65px * var(--resp));
  text-transform: capitalize;
  line-height: 1.1;
  margin-bottom: calc(20px * var(--resp));
}
.section-head1 img {
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  height: calc(70px * var(--resp));
}

.storiesSec .section-head1 img {
  margin-top: -15px;
}

.section-desc {
  font-size: calc(20px * var(--resp));
  line-height: 117%;
  text-transform: capitalize;
  margin-bottom: calc(20px * var(--resp));
}

.section-greentxt {
  font-size: calc(22px * var(--resp));
  color: var(--col3);
  font-weight: 600;
  line-height: 120%;
  text-transform: capitalize;
}

@media (max-width: 991.98px) {
  .section-head1 {
    padding-inline: 0.6rem;
    display: inline-block !important;
  }
  .storiesSec .section-head1 img {
    margin-top: -10px;
    margin-bottom: -0.5rem;
  }
  .storiesSec .section-greentxt {
    padding-inline: 0.65rem;
  }
  .storiesSec__img {
    margin: auto;
  }
  .section-greentxt {
    font-size: calc(18px * var(--resp));
  }
}
@media (max-width: 767.98px) {
  .section-desc {
    font-size: calc(15px * var(--resp));
    padding-inline: 10px;
  }
  .section-head1 {
    font-size: 29px !important;
    line-height: 1.3;
  }
  .section-head1 img {
    height: calc(50px * var(--resp));
  }
}
/* Components */
/* Buttons */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: calc(161px * var(--resp));
  min-height: calc(49px * var(--resp));
  line-height: 1;
  border: 1px solid #090335;
  background-color: var(--col1);
  border-radius: calc(2px * var(--resp));
  font-size: calc(12px * var(--resp));
  font-family: var(--ff1);
  font-weight: 600;
  letter-spacing: calc(1px * var(--resp));
  text-transform: uppercase;
  padding-inline: calc(15px * var(--resp));
}
.button-dark {
  background-color: #090335;
  color: var(--col1);
  transition: 0.23s ease-out;
}
.button-dark:hover {
  background-color: #150d52;
  color: var(--col1);
}
.button-green {
  background-color: #36B8A3;
  color: var(--col1);
  transition: 0.23s ease-out;
}
.button-green:hover {
  background-color: #30ceb3;
  color: var(--col1);
}
.button-transparent {
  background-color: transparent;
  border-color: transparent;
  color: var(--col1);
  transition: 0.23s ease-out;
}
.button-transparent:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--col1);
}

.switch {
  position: relative;
  display: inline-block;
  width: 2.8125rem;
  height: 1.375rem;
  border-radius: 0.9375rem;
  /*------ ADDED CSS ---------*/
  /*--------- END --------*/
  /* Rounded sliders */
}
.switch input {
  display: none;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--Carriben-Light-Green, #E3FAF6);
  box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.1);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  transition: 0.4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  width: 1rem;
  height: 1rem;
  left: 0.18rem;
  bottom: 0.18rem;
  border-radius: 0.9375rem;
  background: var(--Caribbean-Green, #36B8A3);
  box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.1), 0px 4px 4px 0px rgba(255, 255, 255, 0.2) inset;
  transition: 0.4s;
}
.switch input:checked + .slider:before {
  background: #FF584B;
}
.switch input:checked + .slider {
  border-radius: 0.9375rem;
  background: rgba(255, 88, 75, 0.16);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.switch input:checked + .slider:before {
  transform: translateX(1.35rem);
}
.switch .on {
  display: none;
  right: 35%;
}
.switch .off {
  left: 65%;
}
.switch .on,
.switch .off {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: var(--Body-text, #63727E);
  font-family: Poppins;
  font-size: 0.5625rem;
  opacity: 0.3;
  font-weight: 500;
}
.switch input:checked + .slider .on {
  display: block;
}
.switch input:checked + .slider .off {
  display: none;
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}

/* Sections */
/* Header */
.headerSec {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: height 0.5s;
  z-index: 11;
  display: flex;
  align-items: center;
  height: calc(165px * var(--resp));
  background: transparent;
}
.headerSec__buttons {
  display: flex;
  gap: 10px;
}
.headerSec__buttons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: calc(161px * var(--resp));
  min-height: calc(49px * var(--resp));
  line-height: 1;
  border: 1px solid transparent;
  border-radius: calc(2px * var(--resp));
  font-size: calc(12px * var(--resp));
  font-family: var(--ff1);
  font-weight: 600;
  letter-spacing: calc(1px * var(--resp));
  text-transform: uppercase;
  padding-inline: calc(15px * var(--resp));
  color: var(--col3);
}
.headerSec__buttons a.headerSec__buttons--green {
  background-color: var(--col3);
  color: var(--col1);
}
.headerSec__buttons a.headerSec__buttons--green:hover {
  border-color: #389787;
  background-color: #389787;
}
.headerSec__buttons a:hover {
  border-color: #389787;
}
.headerSec.scrolling {
  height: calc(80px * var(--resp));
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  background: rgba(255, 255, 255, 0.5);
}
.headerSec__logo {
  height: calc(47.5px * var(--resp));
  padding-inline: 0px;
  margin-left: -0.5%;
}
.headerSec__logo img,
.headerSec__logo svg {
  height: calc(47.5px * var(--resp));
  width: calc(174px * var(--resp));
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.headerSec nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.headerSec ul {
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 50px;
}
.headerSec ul li {
  margin: 0;
}
.headerSec ul li a {
  line-height: 0;
  margin: 0;
  transition: 0.23s ease-out;
  height: calc(45px * var(--resp));
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerSec ul li a.active {
  color: var(--col3);
}
.headerSec ul li a:hover {
  color: var(--col3);
}
.headerSec .menu-btn {
  min-width: auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerSec .menu-btn__icon1 {
  width: 26px;
}

@media (max-width: 1200px) {
  .headerSec ul {
    gap: 15px;
  }
}
@media (max-width: 991.98px) {
  .headerSec .menu-btn {
    padding: 0;
    margin-top: 1rem;
  }
}
@media (max-width: 767.98px) {
  .headerSec__logo {
    height: calc(45px * var(--resp));
    margin-left: 7px;
  }
  .headerSec__logo img,
  .headerSec__logo svg {
    height: calc(45px * var(--resp));
  }
}
/* Menu section */
.menuSec {
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 111;
  opacity: 0;
  visibility: hidden;
  z-index: -111;
  pointer-events: none;
  transition: 0.5s ease;
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  background: rgba(255, 255, 255, 0.5);
}
.menuSec__inner {
  background-color: var(--col2);
  position: absolute;
  top: 10px;
  right: 10px;
  left: 10px;
  bottom: 10px;
  overflow-y: auto;
  transition-timing-function: cubic-bezier(1, 0, 1, 1);
  transition-duration: 0.65s;
  transition-delay: 0s;
  transform: translateX(100vw);
  border-radius: 14px;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  padding: 15px;
  background-image: url("/public/landing_images/menu/bg1.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.menuSec__inner a {
  color: var(--col2);
}
.menuSec.active {
  background: rgba(255, 255, 255, 0.5);
}
.menuSec.active, .menuSec.animating {
  opacity: 1;
  visibility: visible;
  z-index: 1111;
  pointer-events: all;
}
.menuSec.active .menuSec__inner {
  transition-timing-function: cubic-bezier(0, 1, 1, 1);
  transition-duration: 0.65s;
  transition-delay: 0s;
  transform: translateX(0);
}
@media (min-width: 992px) {
  .menuSec {
    display: none;
  }
}
.menuSec__head {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 20px 20px 0;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.menuSec__head img {
  display: inline-block;
}
.menuSec__head .menu-btn {
  display: inline-flex;
  height: 30px;
  padding: 10px;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  font-size: 14px;
}
.menuSec__head-logo svg {
  height: calc(47.5px * var(--resp));
  width: calc(174px * var(--resp));
}
.menuSec__head-desc {
  text-transform: capitalize;
}
.menuSec__links {
  position: relative;
  list-style-type: none;
  margin: 0;
}
.menuSec__links ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.menuSec__links li {
  margin-bottom: 12.5px;
  position: relative;
  padding: 0 0 0 0;
}
.menuSec__links li a {
  display: block;
  position: relative;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
}
.menuSec__links li a.active {
  color: var(--col3);
}
.menuSec__deco1 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.menuSec__deco1 img {
  height: 65px;
  display: block;
  margin-inline: auto;
}
.menuSec__logo-wrap {
  text-align: center;
  margin-bottom: 25px;
}
.menuSec__logo-wrap img {
  margin-bottom: 10px;
  display: inline-block;
  height: 43px;
}
.menuSec__head-desc {
  color: #63727E;
  font-size: 10px;
}
.menuSec__buttons {
  display: flex;
  gap: 10px;
}
.menuSec__buttons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: calc(161px * var(--resp));
  min-height: calc(49px * var(--resp));
  line-height: 1;
  border: 1px solid transparent;
  border-radius: calc(2px * var(--resp));
  font-size: calc(12px * var(--resp));
  font-family: var(--ff1);
  font-weight: 600;
  letter-spacing: calc(1px * var(--resp));
  text-transform: uppercase;
  padding-inline: calc(15px * var(--resp));
  color: var(--col3);
}
.menuSec__buttons a.headerSec__buttons--green {
  background-color: var(--col3);
  color: var(--col1);
}
.menuSec__footer {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  text-align: center;
  border-top: 1px solid #63727E;
  padding-top: 20px;
}
.menuSec__footer::before {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  width: 51px;
  height: 52px;
  background: url("/public/landing_images/menu/bg2.png") no-repeat center center;
  background-size: contain;
  opacity: 0.25;
}
.menuSec__footer-title {
  color: #fff;
  font-family: var(--ff2);
  font-size: 29px;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.menuSec__footer-hands {
  margin-bottom: 10px;
}
.menuSec__footer-follow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
}
.menuSec__footer-btns {
  text-align: center;
}
.menuSec__footer-btns .menuSec__buttons {
  display: inline-flex;
  border: 1px solid #fff;
  padding: 4px;
  border-radius: 2px;
}
.menuSec__footer-btns .menuSec__buttons--green {
  background-color: #36B8A3;
}
.menuSec__footer-btns .menuSec__buttons a {
  color: #fff;
}
.menuSec__links ul li:first-child {
  position: relative;
  padding-top: 15px;
}
.menuSec__links ul li:first-child::before {
  content: "";
  height: 1px;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #F3F6F8;
}

.menu-btn--hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 30px;
  height: 30px;
  padding-block: 3.5px;
}

.menu-btn--cross {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 30px;
  height: 30px;
}

.no-scroll {
  overflow: hidden;
}

@media (min-height: 700px) {
  .menuSec__links ul {
    padding-inline: 10%;
  }
  .menuSec__links ul li:first-child {
    padding-top: 25px;
  }
  .menuSec__links li {
    margin-bottom: 20px;
  }
  .menuSec__footer {
    padding-top: 25px;
  }
  .menuSec__footer-hands {
    margin-bottom: 15px;
  }
  .menuSec__footer-btns {
    margin-bottom: 10px;
  }
}
@media (min-height: 800px) {
  .menuSec__links ul li:first-child {
    padding-top: 30px;
  }
  .menuSec__links li {
    margin-bottom: 30px;
  }
  .menuSec__footer {
    padding-top: 30px;
  }
  .menuSec__footer-hands {
    margin-bottom: 25px;
  }
  .menuSec__footer-btns {
    margin-bottom: 20px;
  }
  .menuSec__footer-title {
    margin-bottom: 25px;
  }
  .menuSec__footer-follow {
    margin-bottom: 25px;
  }
  .menuSec__deco1 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .menuSec__head-desc {
    margin-top: 10px;
  }
  .menuSec__deco1 img {
    height: 80px;
  }
}
@media (max-width: 991.98px) {
  .menuSec__head .menu-btn {
    padding: 4px;
    margin-right: -0.3rem !important;
  }
  .menuSec__footer-hands img {
    margin-inline: auto;
  }
  .menuSec__links li a.active {
    font-weight: 600;
  }
}
/* Footer */
.footerSec {
  margin-bottom: calc(100px * var(--resp));
}
.footerSec__inner {
  background-color: var(--col2);
  border-radius: calc(14px * var(--resp));
  padding: calc(50px * var(--resp)) calc(50px * var(--resp)) calc(100px * var(--resp));
  color: var(--col1);
  background-image: url("/public/landing_images/footer/deco4.svg"), url("/public/landing_images/footer/deco3.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  background-size: calc(569px * var(--resp)) auto, calc(536px * var(--resp)) auto;
  position: relative;
}
.footerSec__inner a {
  color: var(--col1);
}
.footerSec__buttons {
  display: flex;
  width: calc(338px * var(--resp));
  border: 1px solid #FFFFFF;
  padding: calc(5px * var(--resp));
  justify-content: center;
  gap: 5px;
  border-radius: 2px;
  margin-bottom: calc(30px * var(--resp));
}
.footerSec__logo {
  margin-bottom: calc(20px * var(--resp));
  display: inline-block;
}
.footerSec__logo img {
  width: calc(203px * var(--resp));
}
.footerSec__desc {
  color: #63727e;
  text-transform: capitalize;
  max-width: 70%;
  border-bottom: 1px solid #63727e;
  padding-bottom: calc(10px * var(--resp));
}
.footerSec ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: calc(14px * var(--resp));
  position: relative;
}
.footerSec ul li {
  margin-bottom: calc(20px * var(--resp));
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}
.footerSec ul li:last-child {
  padding-bottom: 20px;
}
.footerSec ul li:last-child::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 2px;
  background-color: #fff;
  bottom: 0;
  left: 0;
}
.footerSec ul h3 {
  font-size: calc(14px * var(--resp));
  color: var(--col3);
  margin-bottom: calc(20px * var(--resp));
  letter-spacing: 1px;
}
.footerSec__copy {
  color: #63727E;
  position: absolute;
  right: 2.5%;
  bottom: 5%;
  display: flex;
  align-items: end;
  gap: calc(10px * var(--resp));
  text-transform: capitalize;
  font-size: calc(14px * var(--resp));
}
.footerSec__copy img {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.footerSec__follow {
  color: #FF5E48;
  display: flex;
  align-items: center;
  gap: calc(15px * var(--resp));
  margin-top: calc(30px * var(--resp));
  margin-bottom: calc(20px * var(--resp));
}
.footerSec__follow img {
  width: calc(35px * var(--resp));
}

@media (max-width: 991.98px) {
  .footerSec__desc {
    max-width: 100%;
  }
  .footerSec__inner {
    padding: 20px;
  }
  .footerSec__copy {
    right: 2.5%;
    bottom: 5px;
  }
  .footerSec__botMob {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footerSec__logo img {
    width: calc(203px * var(--resp));
  }
  .footerSec__copy img {
    opacity: 0.5;
  }
}
@media (max-width: 767.98px) {
  .footerSec {
    margin-bottom: calc(20px * var(--resp));
  }
  .footerSec__inner {
    padding-block: 60px 10px;
  }
  .footerSec__inner .col-6 {
    width: 44%;
  }
  .footerSec__inner .col-6:nth-of-type(2) {
    width: 56%;
  }
  .footerSec__inner__desc {
    font-size: 0.725rem;
  }
  .footerSec__copy {
    min-width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .footerSec__copy img {
    position: absolute;
    right: -0.8rem;
    top: -2rem;
  }
  .footerSec ul li:last-child {
    margin-bottom: 3rem;
  }
  .footerSec ul li:last-child::after {
    width: 70px;
    height: 1px;
  }
  .footerSec__follow {
    margin-top: 0;
  }
}
/* Hero section */
.heroSec {
  padding-top: calc(30px * var(--resp));
  position: relative;
  margin-bottom: calc(50px * var(--resp));
}
.heroSec::before {
  content: "";
  position: absolute;
  width: calc(284px * var(--resp));
  height: calc(284px * var(--resp));
  border-radius: 100%;
  background: rgba(54, 184, 163, 0.5);
  filter: blur(100px);
}
.heroSec__inner {
  background: url("/public/landing_images/hero/bg.png") no-repeat top left;
  background-size: 100% auto;
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
  min-height: 100vh;
  max-width: 85%;
  margin-inline: auto;
  padding-top: calc(150px * var(--resp));
}
.heroSec__left {
  max-width: 60%;
  margin-top: calc(40px * var(--resp));
}
.heroSec .section-head1 {
  max-width: 90%;
}
.heroSec .section-desc {
  max-width: 50%;
}
.heroSec .section-greentxt {
  margin-bottom: calc(30px * var(--resp));
}
.heroSec__buttons {
  margin-bottom: calc(30px * var(--resp));
}
.heroSec__deco0 {
  position: absolute;
  right: 0;
  top: 8%;
  z-index: 1;
  width: 45%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.heroSec__deco4-wrapper {
  margin-top: calc(20px * var(--resp));
  padding-left: calc(75px * var(--resp));
}
@media (max-width: 767.98px) {
  .heroSec__deco4-wrapper img {
    max-width: 2rem;
  }
}
.heroSec__deco2 {
  position: absolute;
  left: 6.5%;
  z-index: 1;
  top: 17%;
  width: 8%;
}
.heroSec__stats {
  position: absolute;
  z-index: 1;
  right: 7%;
  bottom: 6.25%;
  width: 20%;
}
.heroSec__stats-bg {
  width: 100%;
}
.heroSec__icons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 1.5%;
  width: 100%;
  padding: 10%;
}
.heroSec__icons > span {
  width: 27.5%;
}
.heroSec__icons > span img {
  width: 100%;
}
.heroSec__icons > span > div {
  text-align: center;
  margin-top: calc(5px * var(--resp));
  font-weight: bold;
}
.heroSec__icons > span > div span {
  background-color: #cecece;
  color: #fff;
  padding: 2px 7.5px;
  line-height: 1;
  border-radius: 5px;
  font-size: calc(13px * var(--resp));
}
.heroSec__icons-csv span {
  background-color: #36B8A3 !important;
}
.heroSec__icons-pdf span {
  background-color: #FF7169 !important;
}
.heroSec__icons-doc span {
  background-color: #0072FF !important;
}
.heroSec__count {
  display: flex;
  justify-content: space-between;
}
.heroSec__count > div > div:first-child {
  color: #090335;
  font-family: var(--ff2);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 97%;
  text-transform: capitalize;
  margin-bottom: calc(10px * var(--resp));
}
.heroSec__count > div > div:last-child {
  color: #888;
  font-size: calc(16px * var(--resp));
}
.heroSec__stats-line {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}
.heroSec__count {
  position: absolute;
  bottom: 20%;
  width: 100%;
  padding: 0 11%;
}

@media (min-width: 2200px) {
  .heroSec__stats {
    bottom: 9%;
  }
}
@media (max-width: 1800px) {
  .heroSec__left {
    --resp: 0.75;
  }
}
@media (max-width: 1600px) {
  .heroSec__deco2 {
    top: 23%;
    width: 8%;
  }
}
@media (max-width: 1440px) {
  .heroSec__inner {
    max-width: 87.5%;
  }
  .heroSec__deco2 {
    left: 3.5%;
  }
}
@media (max-width: 1280px) {
  .heroSec__inner {
    max-width: 90.5%;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1100px;
  }
  .heroSec__deco2 {
    left: 2.5%;
  }
}
@media (max-width: 1200px) {
  .heroSec__inner {
    min-height: auto;
    max-width: calc(100% - 20px);
  }
  .heroSec__stats {
    bottom: 9.28%;
    right: 9%;
  }
  .heroSec__deco0 {
    top: 15%;
  }
  .headerSec__logo {
    margin-left: 6px;
  }
}
@media (max-width: 991.98px) {
  .heroSec__inner {
    min-height: auto;
    background-size: auto 100%;
    max-width: calc(100% - 20px);
  }
  .heroSec__left {
    max-width: 100%;
  }
  .heroSec__columns {
    display: flex;
    flex-direction: column;
  }
  .heroSec__deco0 {
    position: relative;
    inset: auto;
    width: 100%;
  }
  .heroSec__stats {
    width: 50%;
    bottom: 0;
    right: 15.75%;
    transform: translateX(6%);
  }
  .heroSec .section-desc {
    max-width: 75%;
  }
  .heroSec__icons {
    padding: 5%;
  }
  .heroSec__inner {
    padding-top: calc(100px * var(--resp));
  }
  .heroSec__inner {
    margin-bottom: -225px;
  }
  .heroSec {
    margin-bottom: 0;
  }
  .heroSec .section-head1,
  .heroSec .section-desc,
  .heroSec .section-greentxt, .heroSec__buttons {
    padding-left: 0.4rem;
  }
  .heroSec .section-greentxt {
    margin-bottom: 1rem;
  }
}
@media (max-width: 767.98px) {
  .heroSec__count {
    padding: 0 5%;
    flex-flow: column nowrap;
    gap: 0.12rem;
    bottom: 0.5rem;
  }
  .heroSec__count > div > div:first-child {
    margin-bottom: 0;
    font-size: 20px;
  }
  .heroSec__count > div > div:last-child {
    font-size: 10px;
  }
  .heroSec__deco2 {
    top: 12%;
    width: 18%;
    left: 6%;
  }
  .heroSec__inner {
    padding-top: calc(100px * var(--resp));
    max-width: calc(100% - 1.5rem);
    margin-bottom: 0;
  }
  .heroSec__left,
  .heroSec .section-head1 {
    max-width: 100%;
  }
  .heroSec .section-desc {
    max-width: 85%;
    font-size: calc(16px * var(--resp));
  }
  .heroSec .section-greentxt {
    font-size: calc(18px * var(--resp));
  }
  .heroSec__deco4-wrapper {
    max-width: 25%;
    padding-left: 3rem;
  }
  .heroSec__deco0 {
    top: unset;
    bottom: 5rem;
    width: 100%;
    margin-right: -1rem;
  }
  .heroSec__stats {
    right: 18.5%;
    bottom: 10.25%;
    width: 44%;
  }
  .heroSec__stats-line {
    bottom: 7%;
    left: unset;
    transform: unset;
    width: 2rem;
    right: 0.1rem;
  }
  .heroSec__icons {
    top: 0%;
    padding: 7% 9%;
  }
  .heroSec__icons > span > div {
    margin-top: 2px;
  }
  .heroSec__icons > span > div span {
    padding: 1px 3px 0;
    border-radius: 2px;
    font-size: 7.5px;
  }
  .heroSec .button {
    font-size: calc(14px * var(--resp));
    min-width: calc(200px * var(--resp));
  }
}
/* clientsSec section */
.clientsSec {
  text-align: center;
  padding: calc(50px * var(--resp)) 0;
  position: relative;
}
.clientsSec__title {
  font-size: calc(22px * var(--resp));
  margin-bottom: calc(30px * var(--resp));
}
.clientsSec__title img {
  display: inline-block;
  vertical-align: middle;
}
.clientsSec__single img {
  width: auto !important;
}
.clientsSec__deco1 {
  position: absolute;
  left: 5%;
  top: 10%;
}
.clientsSec__deco2 {
  position: absolute;
  right: 15%;
  top: 0%;
}
.clientsSec__deco3 {
  position: absolute;
  right: 5%;
  top: 20%;
}
.clientsSec__deco4 {
  position: absolute;
  right: 45%;
  bottom: 15%;
}
.clientsSec__all .owl-item {
  padding-block: 50px;
}
.clientsSec__single img {
  transform: scale(var(--resp));
}

@media (max-width: 767.98px) {
  .clientsSec__title {
    margin-bottom: 0;
  }
  .clientsSec__title img {
    height: 30px;
  }
}
/* storiesSec section */
.storiesSec {
  background: url("/public/landing_images/stories/bg.png") no-repeat center center;
  background-size: 100% auto;
  min-height: calc(695px * var(--resp));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: calc(50px * var(--resp));
}
.storiesSec .row {
  justify-content: center;
  align-items: center;
}
.storiesSec__img {
  width: 85%;
}
.storiesSec__deco1 {
  position: absolute;
  right: 20%;
  bottom: 20%;
  width: 6%;
}
.storiesSec__deco2 {
  position: absolute;
  left: 10%;
  top: 10%;
  width: 6%;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

@media (max-width: 767.98px) {
  .storiesSec {
    background-size: auto 100%;
    background-position: left 0% center;
  }
  .storiesSec .row {
    flex-direction: column-reverse;
  }
}
/* freedomSec section */
.freedomSec {
  margin-top: calc(50px * var(--resp));
}
.freedomSec__bg {
  width: 110%;
  max-width: unset;
}
.freedomSec__code {
  width: 97%;
  margin-bottom: 1.5%;
}
.freedomSec .section-head1 {
  width: 60%;
}
.freedomSec__content {
  position: absolute;
  top: 9%;
  left: 4%;
  right: 0;
}
.freedomSec__star {
  position: absolute;
  right: 0%;
  bottom: 0%;
}
.freedomSec__box {
  border-radius: calc(14px * var(--resp));
  background-color: #FEEDC2;
  width: 100%;
  background-image: url("/public/landing_images/freedom/bg2.svg");
  background-repeat: no-repeat;
  background-size: 28% auto;
  background-position: right bottom 20%;
  min-height: calc(210px * var(--resp));
  padding: calc(35px * var(--resp)) calc(25px * var(--resp)) calc(35px * var(--resp)) calc(15px * var(--resp));
  margin-bottom: calc(50px * var(--resp));
}
.freedomSec__box h3 {
  margin-top: calc(20px * var(--resp));
  font-family: var(--ff2);
  font-size: calc(26px * var(--resp));
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 26.4px */
  text-transform: capitalize;
}
.freedomSec__box p {
  text-transform: capitalize;
  font-size: calc(18px * var(--resp));
}
.freedomSec__box .flex-shrink-0 {
  width: 30%;
  text-align: center;
}
.freedomSec__box .flex-shrink-0 img {
  width: 80%;
}
.freedomSec__box-1 {
  background-color: #FEEDC2;
  margin-top: 22%;
  transform: translateX(-25%);
}
.freedomSec__box-2 {
  background-color: #C3F3E7;
  transform: translateX(-15%);
}
.freedomSec__box-3 {
  background-color: #FDD2C1;
  transform: translateX(-25%);
}
.freedomSec__curvy {
  height: calc(30px * var(--resp)) !important;
}
.freedomSec .hljs {
  background: transparent;
}
.freedomSec__code {
  position: relative;
  display: flex;
  background: #292a2b;
  font-size: calc(18px * var(--resp));
  line-height: 1.5;
  font-family: var(--ff1);
  padding: calc(20px * var(--resp));
  padding-top: calc(40px * var(--resp));
}
.freedomSec__code pre,
.freedomSec__code code {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.freedomSec__code pre code {
  font-size: calc(18px * var(--resp));
}
.freedomSec__code pre code.hljs {
  padding-top: 0;
}
.freedomSec__numberLine {
  color: #5c6370;
  font-family: var(--ff1);
}
.freedomSec__type {
  position: absolute;
  top: 10px;
  font-family: var(--ff1);
  display: flex;
  white-space: nowrap;
}
@keyframes cursorAnim {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.freedomSec.section--active #cursor {
  animation: cursorAnim 1s ease-in-out infinite;
}

@media (max-width: 1800px) {
  .freedomSec__box {
    width: 110%;
    min-height: calc(150px * var(--resp));
    padding: calc(25px * var(--resp)) calc(25px * var(--resp)) calc(25px * var(--resp)) calc(15px * var(--resp));
    margin-bottom: calc(30px * var(--resp));
  }
  .freedomSec__box h3 {
    font-size: calc(22px * var(--resp));
  }
  .freedomSec__box p {
    font-size: calc(15px * var(--resp));
  }
}
@media (max-width: 1600px) {
  .freedomSec .section-head1 {
    font-size: calc(35px * var(--resp));
  }
  .freedomSec__content {
    top: 8%;
  }
  .freedomSec__bg {
    width: 112.5%;
  }
}
@media (max-width: 1400px) {
  .freedomSec .section-head1 {
    font-size: calc(40px * var(--resp));
  }
}
@media (max-width: 991.98px) {
  .freedomSec__bg {
    width: 100%;
    min-height: 500px;
    margin: -0.5rem;
  }
  .freedomSec .container {
    padding-inline: 1.25rem;
  }
  .freedomSec .freedomSec__box-1 {
    margin-top: 10rem;
  }
  .freedomSec__curvy {
    height: calc(18px * var(--resp)) !important;
  }
  .freedomSec__box {
    margin-block: 0.8rem;
    padding: 0.8rem 0.65rem 0.8rem 0.5rem;
    min-height: calc(120px * var(--resp));
  }
  .freedomSec__box p {
    margin-bottom: 0;
  }
  .freedomSec__box h3 {
    margin-top: 0;
    margin-bottom: 0.25rem;
  }
  .freedomSec__box .flex-shrink-0 {
    width: 4rem;
  }
  .freedomSec__box .flex-shrink-0 img {
    width: 100%;
  }
  .freedomSec .freedomSec__box-2 {
    margin-left: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .freedomSec {
    overflow: hidden;
  }
  .freedomSec__content {
    top: 5.6%;
  }
  .freedomSec__box {
    width: 100%;
    transform: translate3d(0, 0, 0);
  }
}
/* commitSec section */
.commitSec {
  padding-block: 50px;
}
.commitSec__row {
  margin-bottom: calc(30px * var(--resp));
  --bs-gutter-x: calc(30px * var(--resp));
}
.commitSec__box {
  padding: calc(25px * var(--resp));
  border-radius: calc(14px * var(--resp));
  min-height: calc(250px * var(--resp));
}
.commitSec__box .flex-shrink-0 {
  width: calc(90px * var(--resp));
  text-align: center;
}
.commitSec__box .flex-shrink-0 img {
  width: 90%;
}
.commitSec__box h3 {
  font-size: calc(26px * var(--resp));
  font-family: var(--ff2);
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.commitSec__box .d-flex {
  margin-bottom: calc(15px * var(--resp));
}
.commitSec__box-1 {
  background: #FEEDC2;
}
.commitSec__box-2 {
  background: #C3F3E7;
}
.commitSec__box-3 {
  background: #FDD2C1;
}
.commitSec__box-4 {
  background: #C4F0FF;
}
.commitSec__cloud {
  width: calc(93px * var(--resp));
}
.commitSec__cloudWrap {
  padding: calc(30px * var(--resp));
}
.commitSec__deco1 {
  margin-left: 10%;
}
.commitSec .section-desc {
  max-width: 80%;
  margin-bottom: calc(25px * var(--resp));
}
.commitSec__deco2-wrap {
  margin-bottom: calc(30px * var(--resp));
}
.commitSec__deco2 {
  margin-left: calc(-50px * var(--resp));
}

@media (max-width: 991.98px) {
  .commitSec {
    padding-bottom: 0;
  }
  .commitSec__deco2-wrap {
    max-width: 5.5rem;
    margin-left: 1rem;
  }
  .commitSec__box {
    padding: 0.8rem 1rem 1rem;
  }
  .commitSec__box h3 {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .commitSec__box .flex-shrink-0 {
    width: calc(60px * var(--resp));
  }
  .commitSec__box .flex-shrink-0 img {
    width: 110%;
    max-width: unset;
  }
  .commitSec__box p:last-of-type {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .commitSec__box {
    min-height: auto;
    margin-bottom: 10px;
  }
  .commitSec__row {
    margin-bottom: calc(0px * var(--resp));
  }
  .commitSec__deco1 {
    display: none;
  }
}
/* devSec section */
.devSec {
  background: url(/public/landing_images/stories/bg.png) no-repeat center center;
  background-size: 100% auto;
  min-height: calc(695px * var(--resp));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: calc(100px * var(--resp));
}
.devSec .section-desc {
  max-width: 80%;
}
.devSec .row {
  justify-content: center;
  align-items: center;
}
.devSec__img1 {
  width: 85%;
  height: auto !important;
}
.devSec .section-greentxt {
  margin-bottom: calc(20px * var(--resp));
}
.devSec__deco1 {
  width: calc(106px * var(--resp));
}
.devSec__deco2 {
  width: calc(55px * var(--resp));
  position: absolute;
  bottom: 17.5%;
  right: 7.5%;
}
.devSec__buttons {
  margin-bottom: calc(20px * var(--resp));
}
.devSec__deco4 {
  width: calc(61px * var(--resp));
}
.devSec__deco4-wrapper {
  padding-left: calc(20px * var(--resp));
}

@media (max-width: 1800px) {
  .section-head1 {
    font-size: calc(55px * var(--resp));
  }
}
@media (max-width: 1200px) {
  .devSec {
    min-height: auto;
  }
}
@media (max-width: 767.98px) {
  .devSec {
    margin-bottom: 2rem;
  }
  .devSec .section-head1 {
    margin-top: -1.25rem;
    font-size: calc(30px * var(--resp)) !important;
  }
  .devSec .section-greentxt, .devSec__buttons {
    margin-left: 0.6rem;
  }
  .devSec__deco2 {
    right: 10%;
    left: auto;
    top: 35%;
    bottom: auto;
  }
  .devSec__deco1 {
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: calc(85px * var(--resp));
  }
  .devSec__img1 {
    width: 70%;
    height: auto !important;
    display: block;
    margin-inline: auto;
  }
  .devSec {
    background-size: auto 100%;
    padding-bottom: 50px;
    background-position: left 20% center;
  }
}
/* verifySec section */
.verifySec {
  padding-bottom: calc(100px * var(--resp));
}
.verifySec__inner {
  background-color: #20272C;
  color: #F3F6F8;
  border-radius: calc(14px * var(--resp));
  padding: calc(50px * var(--resp));
  background-image: url("/public/landing_images/verify/deco4.svg"), url("/public/landing_images/verify/deco3.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  background-size: calc(569px * var(--resp)) auto, calc(536px * var(--resp)) auto;
  text-align: center;
  position: relative;
}
.verifySec__deco1 {
  width: calc(569px * var(--resp));
  margin-bottom: calc(20px * var(--resp));
}
.verifySec__deco3 {
  width: calc(66px * var(--resp));
  margin-left: calc(130px * var(--resp));
}
.verifySec h2 {
  color: #E3FAF6;
}
.verifySec .section-desc {
  max-width: 47%;
  margin-inline: auto;
  font-size: calc(24px * var(--resp));
  margin-bottom: calc(30px * var(--resp));
}
.verifySec .verifySec__buttons {
  display: flex;
  margin-inline: auto;
  width: calc(338px * var(--resp));
  border: 1px solid #FFFFFF;
  padding: calc(5px * var(--resp));
  justify-content: center;
  gap: 5px;
  border-radius: 2px;
  margin-bottom: calc(30px * var(--resp));
}
.verifySec__deco2 {
  position: absolute;
  right: 2%;
  bottom: 5%;
  width: calc(55px * var(--resp));
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}

@media (max-width: 991.98px) {
  .verifySec__inner .section-head1 {
    padding-inline: 5px;
  }
  .verifySec__deco1 {
    width: 100%;
  }
  .verifySec__inner {
    padding: calc(150px * var(--resp)) calc(25px * var(--resp)) calc(200px * var(--resp));
  }
  .verifySec .section-desc {
    max-width: 100%;
    font-size: calc(18px * var(--resp));
  }
}
/* howSec section */
.howSec {
  position: relative;
}
.howSec .container {
  position: relative;
}
.howSec__clock-wrap {
  text-align: center;
}
.howSec__clock {
  width: calc(122px * var(--resp));
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.howSec__star1 {
  position: absolute;
  left: 0%;
  top: 0%;
  width: calc(55px * var(--resp));
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.howSec__star2 {
  position: absolute;
  left: 58%;
  top: calc(55px * var(--resp));
  width: calc(30px * var(--resp));
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.howSec .section-head1 {
  text-align: center;
  max-width: 50%;
  margin-inline: auto;
}
.howSec__buttons {
  text-align: center;
}
.howSec__img1 {
  width: calc(405px * var(--resp) * 1.3);
  margin-bottom: calc(25px * var(--resp));
}
.howSec__img2 {
  width: calc(480px * var(--resp) * 1.3);
  margin-bottom: calc(25px * var(--resp));
}
.howSec__img3 {
  width: calc(395px * var(--resp) * 1.3);
  margin-bottom: calc(25px * var(--resp));
}
.howSec__img4 {
  width: calc(401px * var(--resp) * 1.3);
  margin-bottom: calc(25px * var(--resp));
}
.howSec h3 {
  font-family: var(--ff2);
  font-size: calc(22px * var(--resp));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.howSec__text {
  padding-left: 12.5%;
  width: 60%;
}
.howSec__box {
  text-align: left;
  position: relative;
}
.howSec__box-2 {
  margin-top: 45%;
  text-align: right;
}
.howSec__box-2 .howSec__text {
  margin-left: auto;
  text-align: left;
  margin-right: 20%;
}
.howSec__box-4 {
  margin-top: 45%;
  text-align: right;
}
.howSec__box-4 .howSec__img4 {
  margin-right: calc(100px * var(--resp));
}
.howSec__box-4 .howSec__text {
  margin-left: auto;
  text-align: left;
  margin-right: 20%;
}
.howSec__row1 {
  position: relative;
  z-index: 1;
}
.howSec__row2 {
  margin-top: -15%;
}
.howSec__arrow1 {
  position: absolute;
  right: -18%;
  bottom: 31%;
  width: calc(190px * var(--resp) * 1.3);
}
.howSec__arrow2 {
  position: absolute;
  left: -10%;
  bottom: 0%;
  width: calc(152px * var(--resp) * 1.3);
}
.howSec__arrow3 {
  position: absolute;
  right: 0%;
  bottom: -10%;
  width: calc(171px * var(--resp) * 1.3);
}

@media (max-width: 1800px) {
  .howSec {
    --resp: 0.8;
  }
}
@media (max-width: 1400px) {
  .howSec {
    --resp: 0.75;
  }
}
@media (max-width: 1300px) {
  .howSec {
    --resp: 0.7;
  }
}
@media (max-width: 991.98px) {
  .howSec__img1 {
    width: calc(355px * var(--resp) * 1.3);
  }
  .howSec__img2 {
    width: calc(420px * var(--resp) * 1.3);
  }
  .howSec__img3 {
    width: calc(345px * var(--resp) * 1.3);
  }
  .howSec__img4 {
    width: calc(351px * var(--resp) * 1.3);
  }
  .howSec__clock {
    margin: auto;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .howSec__arrow1 {
    right: -29%;
    bottom: 50%;
    width: calc(160px * var(--resp) * 1.3);
  }
}
@media (max-width: 767.98px) {
  .howSec__box {
    margin-top: 50px;
  }
  .howSec__box.howSec__box-1 {
    margin-top: 30px;
  }
  .howSec {
    padding-bottom: 60px;
  }
  .howSec .section-head1 {
    max-width: 100%;
  }
  .howSec__buttons {
    margin-bottom: 20px;
  }
  .howSec__img1, .howSec__img2, .howSec__img3, .howSec__img4 {
    width: 78%;
    margin-inline: 0.6rem;
  }
  .howSec__img2, .howSec__img4 {
    margin-inline: auto 0rem;
  }
  .howSec__box-2, .howSec__box-4 {
    margin-top: 30px;
  }
  .howSec__box-2 .howSec__text, .howSec__box-4 .howSec__text {
    padding-left: 0;
  }
  .howSec__box-3 {
    margin-top: 65px;
  }
  .howSec h3 {
    font-size: 1.2rem;
  }
  .howSec__text {
    padding-left: 1rem;
    width: 75%;
  }
  .howSec__row2 {
    margin-top: 0;
  }
  .howSec__arrow1 {
    bottom: 0;
    right: 0;
    width: calc(125px * var(--resp) * 1.3);
    transform: rotate(65deg);
  }
  .howSec__arrow2 {
    width: calc(120px * var(--resp) * 1.3);
    transform: rotate(285deg);
    left: 0rem;
    bottom: -1.5rem;
  }
  .howSec__arrow3 {
    right: 1%;
    bottom: -7%;
    width: calc(120px * var(--resp) * 1.3);
    transform: rotateX(180deg) rotateZ(280deg);
  }
  .howSec__box-4 .howSec__img4 {
    margin-right: 0;
  }
  .howSec__box-4 .howSec__text,
  .howSec__box-2 .howSec__text {
    margin-left: 30%;
  }
  .howSec__star2 {
    left: 90%;
    top: calc(380px * var(--resp));
  }
}
/* servicesSec section */
.servicesSec {
  background: url(/public/landing_images/stories/bg.png) no-repeat center center;
  background-size: 100% auto;
  min-height: calc(695px * var(--resp));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: calc(50px * var(--resp));
}
.servicesSec__deco3 {
  position: absolute;
  left: 5%;
  top: -5%;
}
.servicesSec .section-desc {
  max-width: 80%;
}
.servicesSec .section-greentxt {
  margin-bottom: calc(20px * var(--resp));
}
.servicesSec__buttons {
  margin-bottom: calc(25px * var(--resp));
}
.servicesSec__deco4-wrapper {
  padding-left: 15%;
}
.servicesSec__deco4 {
  width: calc(66px * var(--resp));
}
.servicesSec__tooltip {
  background-color: #fff;
  border-radius: calc(50px * var(--resp));
  padding: calc(10px * var(--resp)) calc(20px * var(--resp));
  box-shadow: 0 0 calc(30px * var(--resp)) rgba(206, 206, 206, 0.75);
  position: absolute;
  top: -20%;
  left: -10%;
  z-index: 1;
  width: 100%;
}
.servicesSec__tooltip-text {
  font-size: calc(12px * var(--resp));
  line-height: 1.2;
  margin-bottom: calc(2px * var(--resp));
}
.servicesSec__tooltip-number {
  font-family: var(--ff3);
  font-size: calc(18px * var(--resp));
  line-height: 1;
}
.servicesSec__tooltip-right {
  border-bottom-right-radius: 15px;
}
.servicesSec__tooltip-right::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border-left: 5.5px solid #ff634f;
  border-top: 5.5px solid transparent;
  border-bottom: 5.5px solid transparent;
  box-shadow: 0 0 calc(30px * var(--resp)) #ff634f;
  border-radius: 2px;
  transform: rotate(45deg) translate3d(150%, 20%, 0);
  transform-origin: center center;
}
.servicesSec__tooltip-left {
  border-bottom-left-radius: 15px;
}
.servicesSec__tooltip-left::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-right: 5.5px solid #ff634f;
  border-top: 5.5px solid transparent;
  border-bottom: 5.5px solid transparent;
  box-shadow: 0 0 calc(30px * var(--resp)) #ff634f;
  border-radius: 2px;
  transform: rotate(-45deg) translate3d(-150%, 11%, 0);
  transform-origin: center center;
}
.servicesSec__blocks {
  margin-top: 20%;
  margin-inline: auto;
}
.servicesSec__block {
  position: relative;
  width: calc(175px * var(--resp));
  height: calc(175px * var(--resp));
}
.servicesSec__block img {
  display: block;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.servicesSec__block-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  max-width: 80%;
  font-family: var(--ff3);
  line-height: 1;
  color: rgba(32, 39, 44, 0.65);
  font-size: calc(16px * var(--resp));
}
.servicesSec__block-row {
  display: flex;
  gap: 0;
  justify-content: end;
}
.servicesSec__block-1 .servicesSec__tooltip-number {
  color: #ba7f75;
}
.servicesSec__block-1 .servicesSec__tooltip {
  left: 70%;
}
.servicesSec__block-2 .servicesSec__tooltip-number {
  color: #beeffe;
}
.servicesSec__block-3 .servicesSec__tooltip-number {
  color: #ffcf67;
}
.servicesSec__block-3 .servicesSec__tooltip {
  left: 97.5%;
}
.servicesSec__block-4 .servicesSec__tooltip-number {
  color: #1f272c;
}
.servicesSec__block-4 .servicesSec__tooltip {
  left: -80%;
  top: 6%;
}
.servicesSec__block-4 .servicesSec__block-text {
  color: rgba(255, 255, 255, 0.75);
}
.servicesSec__block-5 .servicesSec__tooltip-number {
  color: #ff634f;
}
.servicesSec__block-5 .servicesSec__tooltip {
  left: -40%;
  top: -10%;
}
.servicesSec__block-6 .servicesSec__tooltip-number {
  color: #00b8a3;
}
.servicesSec__block-6 .servicesSec__tooltip {
  left: 90%;
  top: 47%;
}
.servicesSec .servicesSec__block-5 .servicesSec__tooltip {
  border-end-end-radius: 0.25rem;
}

@keyframes tooltipAnimUpDown {
  0%, 100% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
}
.servicesSec__tooltip-up-down {
  transform: translateY(25px);
  animation: tooltipAnimUpDown 5s ease-in-out forwards infinite;
  animation-play-state: paused;
}

.section--active .servicesSec__tooltip-up-down {
  animation-play-state: running;
}

@media (max-width: 1800px) {
  .servicesSec__blocks {
    --resp: 0.75;
  }
}
@media (max-width: 1400px) {
  .servicesSec__blocks {
    --resp: 0.7;
  }
}
@media (max-width: 1300px) {
  .servicesSec__blocks {
    --resp: 0.65;
  }
}
@media (max-width: 1200px) {
  .servicesSec {
    min-height: auto;
    background-size: auto 100%;
  }
  .servicesSec__tooltip-left {
    border-radius: calc(50px * var(--resp));
    border-bottom-right-radius: 15px;
  }
  .servicesSec__tooltip-left::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: auto;
    border-right: none;
    border-left: 7px solid #ff634f;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    box-shadow: 0 0 calc(30px * var(--resp)) #ff634f;
    border-radius: 2px;
    transform: rotate(45deg) translate3d(150%, 20%, 0);
    transform-origin: center center;
  }
  .servicesSec__tooltip {
    left: -70% !important;
  }
  .servicesSec__block-6 .servicesSec__tooltip {
    top: 0%;
  }
}
@media (max-width: 991.98px) {
  .servicesSec__blocks {
    margin-top: 30px;
  }
}
@media (max-width: 767.98px) {
  .servicesSec__deco3 {
    top: -10%;
    left: -5%;
  }
  .servicesSec__blocks {
    margin-top: -90px;
  }
  .servicesSec {
    background-size: auto 100%;
  }
  .servicesSec__tooltip-left {
    border-radius: calc(50px * var(--resp));
    border-bottom-right-radius: 15px;
  }
  .servicesSec .section-head1 img {
    height: 50px;
  }
  .servicesSec__deco4 {
    width: 35px;
    rotate: 60deg;
    margin-top: 1.5rem;
  }
  .servicesSec__deco4-wrapper {
    padding-left: 6.8%;
  }
  .servicesSec .button-dark {
    margin-left: 0.6rem;
  }
  .servicesSec__tooltip-left::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: auto;
    border-right: none;
    border-left: 4px solid #ff634f;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    box-shadow: 0 0 calc(30px * var(--resp)) #ff634f;
    border-radius: 2px;
    transform: rotate(45deg) translate3d(150%, 20%, 0);
    transform-origin: center center;
  }
  .servicesSec__tooltip-right::after {
    border-left: 4px solid #ff634f;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
  .servicesSec__tooltip {
    transform: translate3d(-34%, 5%, 0);
    left: -40% !important;
  }
  .servicesSec__block-6 .servicesSec__tooltip {
    top: 0%;
  }
  .servicesSec__block {
    position: relative;
    width: calc(150px * var(--resp));
    height: calc(150px * var(--resp));
  }
  .servicesSec__block-4 .servicesSec__tooltip {
    top: 0;
  }
}
/* giveSec section */
.giveSec {
  position: relative;
  padding-block: calc(100px * var(--resp));
}
.giveSec .container {
  position: relative;
}
.giveSec .section-head1 {
  max-width: 60%;
  margin-inline: auto;
  text-align: center;
}
.giveSec .section-desc {
  max-width: 50%;
  margin-inline: auto;
  text-align: center;
}
.giveSec .section-greentxt {
  max-width: 50%;
  margin-inline: auto;
  text-align: center;
}
.giveSec__bg {
  width: 110%;
}
.giveSec__code {
  width: 94%;
  margin-bottom: 1.5%;
  margin-left: 4.2%;
  margin-top: 3%;
}
.giveSec__head1 {
  width: 94%;
  margin-left: 4.2%;
  text-align: center;
  font-family: var(--ff2);
  font-weight: 600;
  font-size: calc(22px * var(--resp));
}
.giveSec__content {
  position: absolute;
  top: 9%;
  left: 4%;
  right: 0;
}
.giveSec__star {
  position: absolute;
  left: 0%;
  top: -7.5%;
  width: calc(67px * var(--resp)) !important;
}
.giveSec__box {
  border-radius: calc(14px * var(--resp));
  background-color: #FEEDC2;
  width: 100%;
  background-image: url(/public/landing_images/freedom/bg2.svg);
  background-repeat: no-repeat;
  background-size: 28% auto;
  background-position: right bottom 20%;
  min-height: calc(195px * var(--resp));
  padding: calc(25px * var(--resp)) calc(25px * var(--resp)) calc(25px * var(--resp)) calc(15px * var(--resp));
  margin-bottom: calc(40px * var(--resp));
  position: relative;
}
.giveSec__box h3 {
  margin-top: calc(20px * var(--resp));
  font-family: var(--ff2);
  font-size: calc(26px * var(--resp));
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 26.4px */
  text-transform: capitalize;
}
.giveSec__box p {
  text-transform: capitalize;
  font-size: calc(18px * var(--resp));
}
.giveSec__box .flex-shrink-0 {
  width: 7%;
  text-align: center;
}
.giveSec__box .flex-shrink-0 img {
  width: 100px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate3d(-35%, -35%, 0);
}
.giveSec__box-1 {
  background-color: #FEEDC2;
  margin-top: 18%;
  transform: translateX(-17%);
}
.giveSec__box-2 {
  background-color: #C3F3E7;
  transform: translateX(-8%);
}
.giveSec__box-3 {
  background-color: #FDD2C1;
  transform: translateX(-18%);
}
.giveSec__deco2 {
  position: absolute;
  right: 20%;
  top: -5%;
  width: calc(78px * var(--resp)) !important;
}
.giveSec__deco3 {
  position: absolute;
  right: 10%;
  top: 10%;
  width: calc(93px * var(--resp)) !important;
}

@media (max-width: 1800px) {
  .giveSec__box {
    width: 110%;
    min-height: calc(150px * var(--resp));
    padding: calc(25px * var(--resp)) calc(25px * var(--resp)) calc(25px * var(--resp)) calc(15px * var(--resp));
    margin-bottom: calc(30px * var(--resp));
  }
  .giveSec__box h3 {
    font-size: calc(22px * var(--resp));
  }
  .giveSec__box p {
    font-size: calc(15px * var(--resp));
  }
}
@media (max-width: 1300px) {
  .giveSec__deco2 {
    top: -7.5%;
  }
}
@media (max-width: 991.98px) {
  .giveSec__box-1 {
    margin-top: 40px;
  }
  .giveSec {
    position: relative;
    padding-block: 0;
  }
  .giveSec .section-head1 {
    max-width: 100%;
  }
  .giveSec .section-desc {
    max-width: 100%;
  }
  .giveSec .section-greentxt {
    max-width: 100%;
  }
  .giveSec__deco3 {
    display: none;
  }
  .giveSec__box {
    min-height: 70px;
  }
  .giveSec__box h3 {
    margin-block: 0 0.15rem;
  }
  .giveSec__box p {
    margin-bottom: 0;
  }
  .giveSec__box-1,
  .giveSec__box-2,
  .giveSec__box-3 {
    transform: translateX(0);
    width: 90%;
    margin-left: 5%;
    padding: 1rem 10px;
    margin-bottom: 35px;
  }
  .giveSec__bg {
    width: 100%;
  }
  .giveSec__code {
    width: 83%;
  }
  .giveSec__head1 {
    margin-left: 0;
  }
}
/* happySec section */
.happySec {
  text-align: center;
  position: relative;
  padding-block: 100px;
}
.happySec__img1 {
  width: calc(106px * var(--resp));
}
.happySec__deco2 {
  position: absolute;
  left: 18%;
  top: 5%;
}
.happySec .section-head1 {
  max-width: 90%;
  margin-inline: auto;
}

@media (max-width: 767.98px) {
  .happySec {
    padding-block: 50px;
  }
  .happySec__deco2 {
    display: none;
  }
  .happySec .section-head1 {
    font-size: 26.5px !important;
  }
  .happySec__img1 {
    margin-inline: auto;
    width: calc(85px * var(--resp));
  }
  .happySec .section-desc {
    margin-top: 0.5rem;
    font-family: "Playfair Display";
  }
  .happySec .section-head1 {
    max-width: 100%;
  }
}
/* numberSec section */
.numberSec {
  text-align: center;
  padding: calc(100px * var(--resp)) 0;
  position: relative;
}
.numberSec__deco1 {
  position: absolute;
  left: 5%;
  top: 10%;
}
.numberSec__deco2 {
  position: absolute;
  right: 5%;
  top: 20%;
}
.numberSec__deco3 {
  position: absolute;
  right: 45%;
  bottom: 15%;
}
.numberSec__title {
  text-align: center;
  font-family: var(--ff2);
  font-size: calc(22px * var(--resp));
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: capitalize;
  margin-bottom: calc(35px * var(--resp));
}
.numberSec__title img {
  width: calc(63px * var(--resp));
}
.numberSec__inner {
  border-radius: 999px;
  border: 1px solid #E1E1E1;
  padding: calc(20px * var(--resp));
}
.numberSec__single {
  border-radius: 999px;
  padding: calc(20px * var(--resp));
}
.numberSec__single-1 {
  background-color: #F1FCFA;
}
.numberSec__single-1 .numberSec__count {
  color: #36B8A3;
}
.numberSec__single-2 {
  background-color: #FFF8F1;
}
.numberSec__single-2 .numberSec__count {
  color: rgb(237, 150, 0);
}
.numberSec__single-3 {
  background-color: #F0FAFF;
}
.numberSec__single-3 .numberSec__count {
  color: rgb(95, 169, 194);
}
.numberSec__single-4 {
  background-color: #F2F2F2;
}
.numberSec__single-4 .numberSec__count {
  color: rgb(99, 114, 126);
}
.numberSec__count {
  color: #36B8A3;
  font-family: var(--ff3);
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: calc(0px * var(--resp));
}
.numberSec__text {
  font-family: var(--ff2);
  font-size: calc(15px * var(--resp));
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: capitalize;
}

@media (max-width: 767.98px) {
  .numberSec__inner .row > * {
    padding-inline: 0.15rem;
  }
  .numberSec__title {
    font-size: 1rem;
  }
  .numberSec__single {
    border-radius: 2rem;
  }
  .numberSec__inner {
    padding: calc(8px * var(--resp));
  }
  .numberSec__inner .row {
    --bs-gutter-x: 10px;
  }
  .numberSec__single {
    padding: calc(10px * var(--resp));
  }
  .numberSec__count {
    font-size: 28px;
  }
  .numberSec__text {
    font-size: calc(13px * var(--resp));
  }
  .numberSec__title img {
    display: inline-block;
  }
}
.contactSec {
  position: relative;
  padding-block: 0 calc(100px * var(--resp));
}
.contactSec__star {
  position: absolute;
  right: 0%;
  top: 1.8rem;
  width: calc(40px * var(--resp)) !important;
}
.contactSec__copy-deco {
  margin-top: -1rem;
  width: 49px;
}
.contactSec__deco4 {
  position: absolute;
  rotate: 90deg;
  width: calc(66px * var(--resp));
  bottom: calc(-7.5rem * var(--resp));
  right: calc(12.25rem * var(--resp));
}
.contactSec__inner {
  display: flex;
  gap: calc(3.8rem * var(--resp));
}
@media (min-width: 2200px) {
  .contactSec__inner {
    gap: calc(5rem * var(--resp));
  }
}
.contactSec__social {
  display: flex;
  align-items: center;
  gap: calc(20px * var(--resp));
}
.contactSec__form {
  flex: 1;
  margin-top: calc(2rem * var(--resp));
}
.contactSec__form--row {
  flex: 1;
  display: flex;
  gap: 2rem;
  margin-bottom: calc(3.5rem * var(--resp));
}
.contactSec__form--row .form-group {
  flex: 0 0 calc(50% - 1rem);
  max-width: calc(50% - 1rem);
}
.contactSec__form--row .form-group.flex-1 {
  flex: 1;
}
.contactSec__form--row .form-group textarea.form-control {
  font-size: var(--fs14);
  padding: 0.8rem 0 0;
}
.contactSec__form--row .form-group .form-control {
  border: 0;
  padding: 0;
  border-radius: 0;
  color: var(--col3);
  background: transparent;
  font-size: var(--fs14);
  border-bottom: 1px solid #FF5E48;
}
.contactSec__form--row .form-group .form-control::-moz-placeholder {
  color: var(--col1);
}
.contactSec__form--row .form-group .form-control::placeholder {
  color: var(--col1);
}
.contactSec__left {
  flex: 1;
  background-color: var(--col2);
  border-radius: calc(14px * var(--resp));
  padding: calc(50px * var(--resp)) calc(50px * var(--resp)) calc(1.85rem * var(--resp));
  color: var(--col1);
  background-image: url("/public/landing_images/footer/deco4.svg"), url("/public/landing_images/footer/deco3.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  background-size: calc(569px * var(--resp)) auto, calc(536px * var(--resp)) auto;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}
.contactSec__left h3 {
  margin: -1rem auto 0;
  font-size: var(--fs22);
  max-width: calc(75% * var(--resp));
}
@media (min-width: 2200px) {
  .contactSec__left h3 {
    max-width: calc(50% * var(--resp));
  }
}
.contactSec .btn-row {
  padding-top: calc(5rem * var(--resp));
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.contactSec .btn-row hr {
  width: 100%;
  opacity: 1;
  margin-bottom: calc(1.65rem * var(--resp));
  border-color: #FF5E48;
}
.contactSec .btn-row .btn-white {
  background: var(--col1);
  margin-left: auto;
  margin-top: auto;
  padding: 0.45rem calc(30px * var(--resp)) 0.35rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.06px;
  height: calc(46px * var(--resp));
  transition: 0.3s ease-in-out;
}
.contactSec .btn-row .btn-white:hover, .contactSec .btn-row .btn-white:focus, .contactSec .btn-row .btn-white:active {
  background: var(--caribbean-green);
}
@media (min-width: 2200px) {
  .contactSec .btn-row .btn-white {
    font-size: var(--fs13);
  }
}
.contactSec__right {
  flex: 0 0 calc(40% * var(--resp));
  max-width: calc(40% * var(--resp));
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-block: 0 calc(1.2rem * var(--resp));
}
@media (max-width: 1600px) {
  .contactSec__right {
    flex: 0 0 calc(48% * var(--resp));
    max-width: calc(48% * var(--resp));
  }
}
@media (min-width: 2200px) {
  .contactSec__right {
    flex: 0 0 calc(35% * var(--resp));
    max-width: calc(35% * var(--resp));
  }
}
.contactSec__right .section-desc {
  max-width: 90%;
}
.contactSec__right .text-orange {
  color: #FF5E48;
  font-size: var(--fs22);
}
.contactSec__plane {
  max-width: calc(122px * var(--resp));
}
@media (min-width: 2200px) {
  .contactSec__plane {
    width: 100%;
    max-width: calc(130px * var(--resp));
  }
}

@media (max-width: 767.98px) {
  .contactSec__right {
    text-align: center;
    flex-basis: 90%;
    max-width: 90%;
    margin: auto;
  }
  .contactSec__right .text-orange {
    -moz-text-align-last: left;
         text-align-last: left;
    font-size: 12px;
  }
  .contactSec__right .section-desc {
    padding: 0;
    max-width: 100%;
    margin-inline: auto;
  }
  .contactSec__plane {
    margin-inline: auto;
  }
  .contactSec__deco4 {
    rotate: 82deg;
    width: calc(52px * var(--resp));
    bottom: calc(-5.7rem * var(--resp));
    right: calc(11.75rem * var(--resp));
  }
  .contactSec__outer {
    display: flex;
    margin-top: 2rem;
  }
  .contactSec__outer .section-desc {
    margin: 0;
    padding: 0;
  }
  .contactSec__outer .section-desc br {
    display: none;
  }
  .contactSec__pair {
    text-align: left;
    flex: 1;
  }
  .contactSec__pair:first-of-type {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .contactSec__copy-deco {
    margin-top: -3rem;
    width: 30px;
    margin-left: -0.5rem;
  }
  .contactSec .form-control {
    min-height: 2.35rem;
    height: 2.35rem;
  }
  .contactSec__inner {
    gap: 0.6rem;
    flex-flow: column-reverse nowrap;
  }
  .contactSec__form--row {
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .contactSec__form--row .form-group {
    flex-basis: calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  .contactSec .btn-row {
    padding-top: 6rem;
  }
  .contactSec__left {
    padding: 4.5rem 1.2rem 1.2rem;
  }
  .contactSec__left h3 {
    max-width: 90%;
  }
}/*# sourceMappingURL=landing.css.map */