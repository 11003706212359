.fab {
  width: 2.86825rem;
  height: 2.8125rem;
  @include flexBox(inline-flex, null, center, center);

  &--search {
    background: var(--text-dark);

    img {
      width: 1.5rem;
      height: auto;
    }
  }

  &--dark {
    background-color: var(--text-dark);
  }
}

.btn {
  @include flexBox(inline-flex, null, center, center);

  &-solid {

    &:focus,
    &:focus-visible {
      outline: 0;
    }

    &--green {
      color: var(--text-dark);
      letter-spacing: 0.0375rem;
      background-color: var(--caribbean-green);

      &:hover,
      &:focus,
      &:active {
        color: var(--text-dark);
        background-color: var(--caribbean-green);
      }
    }

    &--dark {
      letter-spacing: 0.0375rem;
      background-color: var(--text-dark);
      color: var(--caribbean-light-green);

      &:hover,
      &:focus,
      &:active {
        color: var(--caribbean-light-green);
        background-color: var(--text-dark);
      }
    }

    &--lightgrey {
      color: var(--text-dark);
      letter-spacing: 0.0375rem;
      border: 1px solid var(--neutral-05);
      background-color: var(--neutral-02);
      height: 45px;

      &:hover,
      &:focus,
      &:active {
        color: var(--text-dark);
        border-color: var(--neutral-05);
        background-color: var(--neutral-02);
        box-shadow: 0px 15px 11px 0px rgba(163, 163, 163, 0.08);
      }
    }
  }
}

// Upload button 
.upload-docs {
  position: relative;
  height: 2.8125rem;

  @include flexBox(flex, null, center, null);
  padding-inline: 2.5rem 1rem;
  border-radius: 10px;

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    inset: 0;
  }

  .btn {
    padding: 0;
    @include flexBox(inline-flex, null, center, center);
    position: absolute;
    left: 0;
    width: 2.8125rem;
    height: 100%;
    box-shadow: 2px 8px 31px 0px rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }

  span {
    flex: 1;
    padding-top: 3px;
    text-align: center;
    letter-spacing: 0.0375rem;
  }

  img {
    pointer-events: none;
  }


  /* Chrome/Safari and web-kit-based browsers (if it doesn't work, try maybe also on the parent/wrapper) */
  ::-webkit-file-upload-button {
    cursor: pointer;
  }

  /* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
  input[type="file"] {
    cursor: pointer;
  }
}

// Upload photo component 
.upload-photo-comp {
  height: 2.8125rem;
  @include flexBox(flex, null, center, space-between);
  padding: 0.13rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);

}

.p-instructions {
  @include flexBox(inline-flex, null, center, flex-end);
  gap: 0.25rem;
  padding-right: 0.8rem;

  &__format {
    height: 1.1rem;
    border-radius: 0.24rem;
    padding: 0 0.2rem;
    padding-top: 1px;
    min-width: 2.375rem;
    letter-spacing: 0.45px;
    @include flexBox(inline-flex, null, center, center);

    &--png {
      background-color: #FF7069;
    }

    &--jpg {
      background-color: #00C650;
    }

    &--docx {
      background-color: #0072FF;
    }

    &--pdf {
      background-color: #FF7069;
    }

    &--csv {
      background-color: #36B8A3;
    }

    &--gif {
      background-color: #FF9908;
    }

    &--10mb {
      color: var(--text-dark);
      background-color: white;
      border: 1px solid rgba(34, 34, 34, 0.10);
    }
  }
}

.upload-photo-btn {
  position: relative;
  height: 100%;

  @include flexBox(inline-flex, null, center, null);
  border-radius: 10px;

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    inset: 0;
  }

  .btn {
    padding: 0.15rem;
    @include flexBox(inline-flex, null, center, center);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.4375rem;
    background-color: #F3F6F8;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  span {
    flex: 1;
    padding-top: 3px;
    padding-inline: 0.75rem;
    text-align: center;
  }

  /* Chrome/Safari and web-kit-based browsers (if it doesn't work, try maybe also on the parent/wrapper) */
  ::-webkit-file-upload-button {
    cursor: pointer;
  }

  /* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */
  input[type="file"] {
    cursor: pointer;
  }
}