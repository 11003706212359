/* commitSec section */
.commitSec {
  padding-block: 50px;

  &__row {
    margin-bottom: calc(30px * var(--resp));
    --bs-gutter-x: calc(30px * var(--resp));
  }

  &__box {
    padding: calc(25px * var(--resp));
    border-radius: calc(14px * var(--resp));
    min-height: calc(250px * var(--resp));

    .flex-shrink-0 {
      width: calc(90px * var(--resp));
      ;
      text-align: center;

      img {
        width: 90%;
      }
    }

    h3 {
      font-size: calc(26px * var(--resp));
      font-family: var(--ff2);
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .d-flex {
      margin-bottom: calc(15px * var(--resp));
    }

    &-1 {
      background: #FEEDC2;
    }

    &-2 {
      background: #C3F3E7;
    }

    &-3 {
      background: #FDD2C1;
    }

    &-4 {
      background: #C4F0FF;
    }
  }

  &__cloud {
    width: calc(93px * var(--resp));
  }

  &__cloudWrap {
    padding: calc(30px * var(--resp));
  }

  &__deco1 {
    margin-left: 10%;
  }

  .section-desc {
    max-width: 80%;
    margin-bottom: calc(25px * var(--resp));
  }

  &__deco2-wrap {
    margin-bottom: calc(30px * var(--resp));
  }

  &__deco2 {
    margin-left: calc(-50px * var(--resp));
  }
}

@media (max-width:1800px) {}

@media (max-width:1600px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {
  .commitSec {
    padding-bottom: 0;
    &__deco2-wrap {
      max-width: 5.5rem;
      margin-left: 1rem;
    }

    &__box {
      padding: 0.8rem 1rem 1rem;

      h3 {
        font-size: 1rem;
        margin-bottom: 0;
      }

      .flex-shrink-0 {
        width: calc(60px* var(--resp));

        img {
          width: 110%;
          max-width: unset;
        }
      }

      p:last-of-type {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media (max-width:767.98px) {
  .commitSec__box {
    min-height: auto;
    margin-bottom: 10px;
  }

  .commitSec__row {
    margin-bottom: calc(0px * var(--resp));
  }

  .commitSec__deco1 {
    display: none;
  }
}