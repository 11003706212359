.contactSec {
  position: relative;
  padding-block: 0 calc(100px * var(--resp));

  &__star {
    position: absolute;
    right: 0%;
    top: 1.8rem;
    width: calc(40px * var(--resp)) !important;
  }

  &__copy-deco {
    margin-top: -1rem;
    width: 49px;
  }

  &__deco4 {
    position: absolute;
    rotate: 90deg;
    width: calc(66px* var(--resp));
    bottom: calc(-7.5rem * var(--resp));
    right: calc(12.25rem * var(--resp));
  }

  &__inner {
    display: flex;
    gap: calc(3.8rem * var(--resp));

    @media (min-width: 2200px) {
      gap: calc(5rem * var(--resp));
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: calc(20px * var(--resp));
  }

  &__form {
    flex: 1;
    margin-top: calc(2rem * var(--resp));

    &--row {
      flex: 1;
      display: flex;
      gap: 2rem;
      margin-bottom: calc(3.5rem * var(--resp));

      .form-group {
        flex: 0 0 calc(50% - 1rem);
        max-width: calc(50% - 1rem);

        &.flex-1 {
          flex: 1;
        }

        textarea.form-control {
          font-size: var(--fs14);
          padding: 0.8rem 0 0;
        }

        .form-control {
          border: 0;
          padding: 0;
          border-radius: 0;
          color: var(--col3);
          background: transparent;
          font-size: var(--fs14);
          border-bottom: 1px solid #FF5E48;


          &::placeholder {
            color: var(--col1);
          }
        }
      }
    }
  }

  &__left {
    flex: 1;
    background-color: var(--col2);
    border-radius: calc(14px* var(--resp));
    padding: calc(50px * var(--resp)) calc(50px * var(--resp)) calc(1.85rem * var(--resp));
    color: var(--col1);
    background-image: url('/public/landing_images/footer/deco4.svg'), url('/public/landing_images/footer/deco3.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: top right, bottom left;
    background-size: calc(569px* var(--resp)) auto, calc(536px* var(--resp)) auto;
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    h3 {
      margin: -1rem auto 0;
      font-size: var(--fs22);
      max-width: calc(75% * var(--resp));

      @media (min-width: 2200px) {
        max-width: calc(50% * var(--resp));
      }
    }
  }

  .btn-row {
    padding-top: calc(5rem * var(--resp));
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    hr {
      width: 100%;
      opacity: 1;
      margin-bottom: calc(1.65rem * var(--resp));
      border-color: #FF5E48;
    }

    .btn-white {
      background: var(--col1);
      margin-left: auto;
      margin-top: auto;
      padding: 0.45rem calc(30px * var(--resp)) 0.35rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.06px;
      height: calc(46px * var(--resp));
      transition: 0.3s ease-in-out;

      &:hover,
      &:focus,
      &:active {
        background: var(--caribbean-green);
      }

      @media (min-width: 2200px) {
        font-size: var(--fs13);
      }
    }
  }

  &__right {
    flex: 0 0 calc(40% * var(--resp));
    max-width: calc(40% * var(--resp));
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    padding-block: 0 calc(1.2rem * var(--resp));

    @media (max-width: 1600px) {
      flex: 0 0 calc(48% * var(--resp));
      max-width: calc(48% * var(--resp));
    }

    @media (min-width: 2200px) {
      flex: 0 0 calc(35% * var(--resp));
      max-width: calc(35% * var(--resp));
    }

    .section-desc {
      max-width: 90%;
    }

    .text-orange {
      color: #FF5E48;
      font-size: var(--fs22);
    }
  }

  &__plane {
    max-width: calc(122px * var(--resp));

    @media (min-width: 2200px) {
      width: 100%;
      max-width: calc(130px * var(--resp));
    }
  }
}

@media (max-width: 767.98px) {
  .contactSec {
    &__right {
      text-align: center;
      flex-basis: 90%;
      max-width: 90%;
      margin: auto;


      .text-orange {
        text-align-last: left;
        font-size: 12px;
      }

      .section-desc {
        padding: 0;
        max-width: 100%;
        margin-inline: auto;
      }
    }

    &__plane {
      margin-inline: auto;
    }

    &__deco4 {
      rotate: 82deg;
      width: calc(52px* var(--resp));
      bottom: calc(-5.7rem* var(--resp));
      right: calc(11.75rem* var(--resp));
    }

    &__outer {
      display: flex;
      margin-top: 2rem;

      .section-desc {
        margin: 0;
        padding: 0;

        br {
          display: none;
        }
      }
    }

    &__pair {
      text-align: left;
      flex: 1;

      &:first-of-type {
        flex: 0 0 45%;
        max-width: 45%;
      }
    }

    &__copy-deco {
      margin-top: -3rem;
      width: 30px;
      margin-left: -0.5rem;
    }

    .form-control {
      min-height: 2.35rem;
      height: 2.35rem;
    }

    &__inner {
      gap: 0.6rem;
      flex-flow: column-reverse nowrap;
    }

    &__form--row {
      gap: 1rem;
      margin-bottom: 2rem;

      .form-group {
        flex-basis: calc(50% - 0.5rem);
        max-width: calc(50% - 0.5rem);
      }
    }

    .btn-row {
      padding-top: 6rem;
    }

    &__left {
      padding: 4.5rem 1.2rem 1.2rem;

      h3 {
        max-width: 90%;
      }
    }
  }
}