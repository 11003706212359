.form {
  &-group {

    &--search,
    &--email {
      position: relative;
      border-radius: 0.9375rem;
      background: rgba(255, 255, 255, 0.49);
      padding: 0.5rem 0.56rem;
      box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
      backdrop-filter: blur(2px); 

      @include flexBox(flex, null, center, null);
      gap: 1rem;

      .form-control {
        height: auto;
        background: #FFF;
        min-width: 24.5rem;
        padding-left: 3.6rem;
        color: var(--text-theme);
        text-transform: capitalize;
        box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);

        @media (max-width: 767.98px) {
          min-width: unset;
        }

        &::placeholder {
          font-weight: 400;
          color: var(--neutral-01);
        }
      }

      .fab {
        position: absolute;
        left: 0.56rem;
        top: 0.5rem;
        z-index: 1;
      }
    }

    &--email {
      .form-control {
        text-transform: lowercase;

        &::placeholder {
          text-transform: capitalize;
        }
      }
    }

    ul.icons {
      @include flexBox(flex, null, center, null);
      gap: 1rem;
      padding-right: 0.65rem;

      a {
        padding: 0.35rem;
        position: relative;
        width: 1.85rem;
        @include flexBox(inline-flex, null, center, center);
      }

      .dot {
        position: absolute;
        right: 0.2rem;
        top: 0.05rem;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: #FF5E48;
      }
    }

    &--otp {
      padding: 0.5rem;

      @include flexBox(flex, null, center, space-between);
      gap: 0.8rem;

      .form-control {
        border-radius: 7px;
        width: 2.8125rem;
        height: 2.8125rem;
        min-width: unset;
        min-height: unset;
        text-align: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        @include ts(0.3s);

        &:focus,
        &:required:valid {
          color: white;
          background-color: var(--text-dark);
        }
      }
    }
  }

  &-control {
    height: 2.8125rem;
    min-height: 2.8125rem;

    &::-moz-placeholder {
      color: var(--neutral);
    }

    &::placeholder {
      color: var(--neutral);
    }
  }
}

.dark {
  width: 100%;
  height: 5rem;
  padding: 1rem;
  background-color: black;
}

.dark-options {
  border-radius: 6.25rem;
  background: var(--Heading-Black, #20272C);
  display: inline-flex;
  height: 1.5625rem;
  padding: 0.375rem 0.3125rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  flex-shrink: 0;

  span {
    color: var(--Carriben-Light-Green, #E3FAF6);
    font-family: Poppins;
    font-size: 0.5rem;
    font-weight: 400;
  }
}


// Folder Div 


.folder {
  position: relative;
  width: 7.75rem;
  height: 6.2rem;
  background: var(--bg);
  border-radius: 0.625rem;
  padding: 0.65rem;
  --bg: #dce2e4;
  transition: 0.2s ease-out;

  z-index: 1;
}


.top {
  background: #D4D4D4;
  position: absolute;
  left: 0;
  top: -12px;
  height: 50px;
  width: 100%;

  -webkit-clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(50% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
  overflow: hidden;
  border-radius: 0.625rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: 0.2s ease-out;

}

.head {
  position: relative;
}



.head .dot2 {
  position: absolute;
  right: 0;
  top: 0;


  display: flex;
  justify-content: center;
  align-items: center;
}

.head .dot2:hover {
  background: #ffffff97;
  user-select: none;
  cursor: pointer;
}

.head .dot2:active {
  background: #fff;
  user-select: none;
}

.info {
  span {
    color: var(--Body-text, #63727E);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;

  }

  position: absolute;
  width: 100%;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.folder-wrapper {
  width: 7.75rem;
}

.total-div {
  width: 100%;
  display: flex;
  justify-content: center;

  span {
    color: var(--Caribbean-Green, #36B8A3);
    margin-left: 0.5rem;
  }

  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 500;

}

//PDF button
.pdf {
  width: 2.5rem;
  height: 1.12rem;
  background-image: url('/public/images/icons/icon-pdf.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jpg {
  width: 2.5rem;
  height: 1.12rem;
  background-image: url('/public/images/icons/icon-jpg.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.docx {
  width: 2.5rem;
  height: 1.12rem;
  background-image: url('/public/images/icons/icon-docx.svg');
  background-size: 100% 100%;
}

.verified-div {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: var(--Caribbean-Green, #36B8A3);
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.pending-div {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: var(--Body-text, #63727E);
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.status-div {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: var(--Heading-Black, #20272C);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
  }
}

.table-actions {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 3px;
  padding-inline: 0.5rem;


  .table-action-innerdiv {
    display: inline-flex;
    align-items: center;
    background: var(--Lighter-Grey, #F3F6F8);
    padding: 0.25rem 0.5rem;
    gap: 0.25rem;

    span {
      color: var(--Body-text, #63727E);
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
    }
  }

}

// Search In Table
.search-input-wrapper {
  width: 29.4rem;
  height: 3.8rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.form-control-search {
  height: 100%;
  border: none;
  border-radius: 0.625rem;
  transition: all 0.3s ease-in-out;
  border-radius: 0.625rem;
  box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
  backdrop-filter: blur(2px);
  line-height: 100%;
  /* 0.625rem */
  letter-spacing: 0.0375rem;


}

.table-search {
  background: #FFF;
  display: flex;
  align-items: center;
  width: 22.5rem;
  height: 2.8125rem;
  border-radius: 0.625rem;
  color: #BCBCBC;
  box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);

  input {
    padding-left: 3.7rem;
    width: 100%;

    &:placeholder {
      color: #BCBCBC;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

}

.btn-fab {
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 0.6rem;
  border-radius: 0.625rem;
  background: inherit;
  height: 100%;
  position: relative;
}

.black-btn {
  border-radius: 12px;
  background: #20272C;
}

.filter-div {
  height: 100%;
  display: flex;
  gap: 1.18rem;
  flex: 1;
  justify-content: center;
  align-items: center;
}