/* Common */

.fade-in {
  opacity: 0;
}


// Landing page
html {
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  background-color: var(--col1);
  color: var(--col2);
  min-height: 100vh;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: var(--fs15);
  font-family: var(--ff1);
  overflow-x: hidden;

  a {
    text-decoration: none;
    color: var(--col2);

    transition: 0.23s ease-out;

    &:hover {
      color: var(--col3);
    }
  }

  .landing {
    position: relative;
    z-index: 1;

    .section-head1 img {
      display: inline-block;
    }
  }
}

.color-green {
  color: var(--col3);
}

.mobile-only {
  display: none !important;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 80vw;
  }
}

@media (min-width: 1600px) {}


@media (max-width: 1800px) {}

@media (max-width: 1400px) {}

@media (max-width: 1300px) {}

@media (max-width: 1200px) {}

@media (max-width: 991.98px) {
  .mobile-only {
    display: block !important;
  }

  .desk-only {
    display: none;
  }

  .landing {
    .button-dark {
      min-height: 2.65rem;
      padding: 1rem 1.2rem;
      font-size: 10px;
    }
  }
}

@media (max-width: 767.98px) {}