// Auth screen styling //
.auth-form-area {
  padding: 3.66rem 3rem 2rem;
  @include flexBox(flex, column nowrap, null, null);
  height: 100%;

  @media (max-width: 1450px),
  (max-height:760px) {
    padding: 2.2rem 2.2rem 0.8rem;
  }

  @media (max-width: 767.98px) {
    padding-inline: 1.8rem;
  }

  .logo {
    max-width: 7.896rem;

    @media (max-width: 767.98px) {
      margin: auto;
    }
  }

  form {
    flex: 1;
    margin-top: 12vh;
    max-width: 26rem;
    margin-inline: auto;
    @include flexBox(flex, column nowrap, null, null);

    @media (max-width: 767.98px) {
      margin-top: 18vh;
      max-width: 100%;
      width: 100%;
    }

    .form-group--search {
      .form-control {
        min-width: 20rem;

        @media (max-width: 767.98px) {
          min-width: unset;
        }
      }
    }

    @media (max-width: 767.98px) {
      .form-group--otp {
        position: relative;
        border-radius: 0.9375rem;
        background: rgba(255, 255, 255, 0.49);
        padding: 0.5rem 0.56rem;
        box-shadow: 0px 34px 34px 0px rgba(13, 46, 97, 0.05);
        backdrop-filter: blur(2px);
      }
    }

    .checkbox-wrapper {
      margin-block: 0.938rem 1.2rem;
      padding-left: 0.4rem;

      @media (max-width: 767.98px) {
        margin-block: 1.2rem 1.5rem;
      }
    }

    em {
      margin-block: 0.938rem 1.2rem;
      padding-left: 0.6rem;

      @media (max-width: 767.98px) {
        padding-left: 0;
        font-size: 12px;
        font-style: normal;

        strong {
          color: var(--text-dark);
        }
      }
    }

    .btn {
      height: 2.8125rem;
      border-radius: 0.375rem;
    }

    .or {
      margin: 1.2rem auto 0.65rem;
    }

    .alternative {
      @include flexBox(flex, null, center, center);
    }
  }

  .tnc-pp-links {
    width: 17rem;
    margin: 10vh auto 0;
    width: 100%;
    @include flexBox(flex, null, center, space-between);

    @media (max-width: 767.98px) {
      margin-top: 1rem;
    }

    a {

      &:hover,
      &:focus,
      &:active {
        color: var(--caribbean-green);
      }
    }
  }
}

.auth-features {
  padding: 3.66rem 3.5rem 1.5rem 3.2rem;
  @include flexBox(flex, column nowrap, null, null);
  height: 100%;

  @media (max-width: 1450px),
  (max-height:760px) {
    padding: 2rem 2rem 0.5rem 2.5rem;
  }

  &__top {
    flex: 1;

    @include flexBox(flex, column nowrap, center, center);
    padding-bottom: 4rem;

    @media (max-width: 1450px),
    (max-height:760px) {
      padding-bottom: 1rem;
    }

    @media (max-width: 767.98px) {
      max-width: calc(100% - 1.5rem);
    }
  }

  &__bottom {
    ul {
      // max-width: 40rem;
      margin: 0;
      @include flexBox(flex, null, center, space-between);

      .amazon-logo {
        margin-bottom: -0.5rem;
      }

      @media (max-width: 767.98px) {
        img {
          max-height: 0.65rem;

          &[alt="logo-sony"] {
            max-height: 0.4rem;
          }

          &[alt="logo-disnep"] {
            max-height: 0.8rem;
          }

          &[alt="logo-instacart"] {
            max-height: 0.5rem;
          }
        }
      }
    }
  }
}

.masked-text {
  position: relative;
  @include flexBox(inline-flex, column nowrap, null, null);
  font-size: 3.5rem;
  padding-bottom: 4.5rem;

  @media (max-width: 1450px),
  (max-height:760px) {
    font-size: 3.215rem;
  }

  .greenThemeText {
    line-height: 1.2;
    letter-spacing: 0.15rem;
  }

  .auth-grad-text {
    text-align: left;
    line-height: 1.2;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;

    margin-top: -0.5rem;
  }

  .dark-theme-mix-text {
    background-image: linear-gradient(90deg, #000 44%, #36B8A3 44%);
  }

  .dark-white-mix-text {
    background-image: linear-gradient(90deg, #000 44%, #fff 44%);
  }

  .above-img-text {
    position: absolute;
    z-index: 1;
    top: 18.125rem;
    right: 0;
    line-height: 1.2;
    color: var(--caribbean-light-green);

    @media (max-width: 1450px),
    (max-height:760px) {
      top: 16.5rem;
    }
  }

  .link-to-home {
    position: absolute;
    right: -5.35rem;
    height: 1rem;
    rotate: -90deg;
    top: 16.5rem;
    color: var(--primary-dark);

    @media (max-width: 1450px),
    (max-height:760px) {
      right: -5.2rem;
      top: 15rem;
    }
  }

  .main-bg {
    width: 216px;
    position: absolute;
    right: -0.885rem;
    top: 4rem;

    @media (max-width: 1450px),
    (max-height:760px) {
      width: 200px;
      top: 3.5rem;
    }
  }

  // Confetti images 
  .confetti {
    max-height: 1.2rem;
    position: absolute;

    &.cross-black {
      left: -1.25rem;
      top: 0.5rem;

      @media (max-width: 1450px),
      (max-height:760px) {
        top: 0;
      }

      @media (max-width: 767.98px) {
        top: unset;
        bottom: -2.35rem;
        left: 0.15rem;
      }
    }

    &.cross-green {
      right: -2.5rem;
      top: 3.35rem;

      @media (max-width: 767.98px) {
        right: -1.8rem;
        top: 0;
      }
    }

    &.rhombus-red {
      left: -1.2rem;
      top: 16rem;

      @media (max-width: 1450px),
      (max-height:760px) {
        top: 14.5rem;
      }

      @media (max-width: 767.98px) {
        left: 0rem;
        top: 16.5rem;
      }
    }

    &.rhombus-black {
      right: 6.55rem;
      top: 4.85rem;
      z-index: 2;

      @media (max-width: 1450px),
      (max-height:760px) {
        right: 5.9rem;
        top: 4.5rem;
      }
    }

    &.wave-red-long {
      right: -2.8rem;
      top: 0.65rem;

      @media (max-width: 767.98px) {
        top: 20rem;
        right: -2rem;
      }
    }

    &.wave-red-short {
      top: 18rem;
      left: 7rem;

      @media (max-width: 1450px),
      (max-height:760px) {
        top: 16.5rem;
        left: 6rem;
      }

      @media (max-width: 767.98px) {
        right: 0.25rem;
        top: 0.1rem;
        left: unset;
      }
    }

    &.line-black-short {
      top: 16.5rem;
      left: 4.15rem;

      @media (max-width: 1450px),
      (max-height:760px) {
        top: 15rem;
      }
    }
  }

  &--signup {
    font-size: 3.85rem;
    padding-right: 1.9rem;

    @media (max-width: 1450px),
    (max-height:760px) {
      font-size: 3.215rem;
      padding-right: 2.35rem;
    }

    .greenThemeText {
      line-height: 1.15;
    }

    .auth-grad-text {
      line-height: 1.15;
    }

    .dark-theme-mix-text {
      background-image: linear-gradient(90deg, #000 46.2%, #36B8A3 46.2%);
    }

    .dark-white-mix-text {
      background-image: linear-gradient(90deg, #000 46.2%, #fff 46.2%);
    }

    .main-bg {
      top: 4rem;

      @media (max-width: 1450px),
      (max-height:760px) {
        top: 3.45rem;
      }
    }

    .confetti {

      &.rhombus-red {
        top: 17rem;

        @media (max-width: 1450px),
        (max-height:760px) {
          top: 14.5rem;
        }
      }

      &.line-black-short {
        top: 17.5rem;

        @media (max-width: 1450px),
        (max-height:760px) {
          top: 15rem;
        }
      }

      &.rhombus-black {
        right: 5.65rem;
        top: 3.2rem;

        @media (max-width: 1450px),
        (max-height:760px) {
          right: 5.4rem;
          top: 2.6rem;
        }
      }

      &.wave-red-short {

        @media (max-width: 1450px),
        (max-height:760px) {
          left: 4.85rem;
        }
      }
    }
  }
}

.sub-help-info {
  @include flexBox(flex, null, flex-end, space-between);
  max-width: 27rem;
  width: 100%;
  margin-top: 4rem;
  padding-left: 2.5rem;
  font-size: 9.5px;

  @media (max-width: 1450px),
  (max-height:760px) {
    max-width: 26rem;
    font-size: 9.1px;
  }

  @media (max-width: 991.98px) {
    padding-left: 1.5rem;
    font-size: 12px;
    color: #63727E;
    margin-block: 2rem 1rem;

    span {
      opacity: 0.8;
    }
  }

  &>div {
    max-width: 33.33%;
    flex: 0 0 33.33%;
    text-align: right;

    @media (max-width: 991.98px) {
      max-width: 45%;
      flex: 0 0 45%;
    }
  }

  .top-red-bd-text {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: -1.125rem;
      left: 0;
      right: 0;
      height: 0.35581rem;
      background: linear-gradient(270deg, rgba(255, 113, 105, 0.28) 7.29%, #FF7169 43.75%, rgba(255, 113, 105, 0.21) 84.9%);
    }

  }

  .top-green-dark-bd-text {
    font-size: 1.25rem;
    color: #106456;
    padding: 0 0.25rem;

    position: relative;
    padding-bottom: 2px;

    &::after {
      content: "";
      position: absolute;
      top: -0.6rem;
      right: 1.25%;
      width: 85%;
      border-top: 1px solid #106456;
    }

    @media (max-width: 991.98px) {
      display: none;
    }
  }
}

// =================== //


// Profile organization //
.theme-feature-widget {
  border-radius: 0.938rem;
  background: url(/public/images/bg-particles/bg-icons-reduced-opacity.svg) no-repeat center;
  background-color: var(--text-dark);

  @include flexBox(flex, column nowrap, null, null);
  padding: 0.5rem 1.25rem 1.75rem;
  gap: 0.65rem;

  .right-out-canvas {
    width: 111%;
    max-width: unset;
    margin-top: -1rem;
    margin-right: -2rem;
  }

  &>p {
    opacity: 0.4;
    margin-block: 0.15rem 0.5rem;
    color: var(--caribbean-light-green);
  }
}

ul.avatars {
  @include flexBox(inline-flex, row wrap, center, null);
  gap: 0.31rem;

  img {
    width: 1.5625rem;
    height: 1.5625rem;
    object-fit: cover;
    border-radius: 50%;
  }
}

.light-green-theme-block {
  border-radius: 0.938rem;
  background: url(/public/images/bg-particles/light-green-theme-bg.svg) no-repeat center;
  background-color: #ACE1D9;
  padding: 1.8rem 1.25rem 1.75rem;

  &__inner {
    @include flexBox(flex, column nowrap, null, null);
    gap: 0.5rem;

    h4 {
      line-height: 1.4;
    }

    .upload-docs {
      padding-left: 3.88rem;

      .btn {
        background-color: #106456;
      }

      span {
        color: #106456;
      }
    }
  }
}

.access-block {
  @include flexBox(flex, null, center, space-between);
  gap: 1rem;
  background-color: var(--text-dark);
  min-height: 4.4375rem;

  .access-ques {
    padding: 0.8rem;
    max-width: min(60%, 33rem);
    flex: 0 0 min(60%, 33rem);
    text-align: center;

    background: url(/public/images/bg-particles/aside-active-bg-reverted.svg) no-repeat left;
    background-position-x: -7%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat right;
      background-position-x: 2%;
    }
  }

  .upload-docs {
    max-width: 19.5rem;
    padding-left: 3.65rem;
    margin-right: 0.8rem;
  }
}

// Preview blocks
.preview-blocks {
  @include flexBox(flex, null, null, null);
  gap: 1.45rem;
  margin-top: 1.5rem;

  &>div {
    flex: 1;
    padding: 12px;
    border-radius: 10px;
  }

  &__left {
    padding: 12px;
  }

  .preview-title-bar {
    padding-inline: 1rem;
    height: 3.375rem;
    border-radius: 0.5rem;
    @include flexBox(flex, null, center, null);
    background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat right center;
    background-color: var(--caribbean-light-green);
    background-position: 105%;
  }

  .preview-info {
    @include flexBox(flex, column wrap, null, null);
    gap: 1.2vh 2vw;
    margin: 1.8rem 1rem 2.2rem;
    max-height: 20rem;

    &>li {
      min-height: 4rem;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        flex: 1;
      }
    }

    .label {
      color: var(--text-theme-100);
      letter-spacing: 0.0375rem;
    }

    .value {

      &--masked {
        @include flexBox(inline-flex, row-reverse nowrap, center, null);
        gap: 0.25rem;

        .btn {
          padding: 0.25rem;
        }
      }

      &--tags {
        @include flexBox(inline-flex, null, center, null);
        gap: 0.25rem;

        .tag-type {
          min-height: 1.5rem;
          border-radius: 0.3125rem;
          background-color: var(--text-dark);
          padding-inline: 0.4rem;

          @include flexBox(inline-flex, row wrap, center, center);
        }
      }

      &--withImg {
        @include flexBox(inline-flex, null, center, null);
        gap: 0.5rem;

        img {
          flex-shrink: 0;
        }

        span {
          @include oneLineClamp(min(12vw, 10rem));
        }
      }
    }

    &--member {
      max-height: 14rem;
      margin: 1.8rem 1rem 3rem;
    }
  }

  .upload-docs {
    margin-top: auto;
    margin-left: 1rem;
    padding-inline: 3.75rem 1.5rem;
    background-color: var(--caribbean-light-green);

    span {
      color: var(--text-dark);
    }
  }
}

.flag-dd {
  @include flexBox(inline-flex, null, center, null);
  gap: 0.81rem;
  height: 100%;
  padding-left: 6px;

  &__ff {
    position: relative;
    background: transparent;

    &:focus {
      outline: 0;
    }

    &-inner {
      @include flexBox(inline-flex, null, center, center);
      width: 3rem;
      height: 3.00756rem;
      padding: 5px;
      border-radius: 6px;
      background-color: var(--caribbean-light-green);
      box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }

    }


    b.arrow {
      @include flexBox(inline-flex, null, center, flex-start);

      position: absolute;
      top: 50%;
      right: -0.8rem;
      padding: 0.1rem 0 0.1rem 0.12rem;
      transform: translateY(-50%);

      border-radius: 0 3rem 3rem 0;
      width: 0.8rem;
      height: 1.6rem;
      background-color: var(--caribbean-light-green);

      img {
        width: 0.4rem;
      }
    }
  }

  .dropdown-toggle::after {
    display: none;
    margin: 0;
  }

  .dropdown-menu {
    margin-top: 0.275rem !important;
    box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);

    a {
      color: var(--text-theme);
      padding: 0.6rem 1rem;
    }
  }

  &__name {
    padding-top: 0.65rem;

    p {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.3rem;
    }
  }
}

// Dashboard documents upload  
.grid-side-section {
  @include flexBox(flex, null, null, null);
  padding-block: 1.75rem;

  &__left {
    padding: 0 2.4rem;
    flex: 1;
    @include flexBox(inline-flex, column nowrap, null, null);

    .title-row {
      margin-inline: auto;
      display: inline-block;

      .step-num-label {
        letter-spacing: 0.25rem;
        text-align: left;
      }
    }

    &>p {
      max-width: min(35vw, 30rem);
      margin-inline: auto;
    }

  }

  &__right {
    flex: 0 0 20.75rem;
    max-width: 20.75rem;
    padding: 0 1.5rem 0 1.25rem;

  }
}

.radial-divider {
  width: 1px;
  height: 100%;
  background: radial-gradient(#e6e6e6, transparent 70%);
}

.right-steps {
  @include flexBox(flex, column nowrap, null, null);
  gap: 1rem;

  .steps {
    padding: 0.65rem;

    &__clickable {
      background: url(/public/images/bg-particles/aside-active-bg.svg) no-repeat center;
      background-position: 113% 24%;
      background-color: var(--caribbean-light-green);
      box-shadow: 0px 34px 26px 0px rgba(54, 184, 163, 0.03);
      padding: 1rem 0.94rem 1.2rem;

      @include flexBox(flex, column nowrap, flex-start, flex-start);
      gap: 0.25rem;

      .num {
        letter-spacing: 0.25rem;
      }

      &>h6 {
        @include flexBox(flex, null, center, space-between);

        img {
          margin-block: -0.2rem;
        }
      }
    }

    &>p {
      padding: 0.65rem 1rem 0.25rem;
    }

    &--done {
      .steps__clickable {
        background-image: none;
      }
    }

    &--current {
      .steps__clickable {
        background-color: var(--text-dark);

        .num {
          color: #FF7169;
        }

        h6 {
          color: var(--caribbean-light-green);

          img {
            display: none;
          }
        }
      }
    }

    &--upcoming {
      .steps__clickable {
        h6 {
          img {
            display: none;
          }
        }
      }
    }

  }

  .btn-solid--dark {
    height: 2.375rem;
  }
}

// Main Dashboard styling
.dashboard-content {
  @include flexBox(flex, column nowrap, null, null);
  gap: 1.15rem;
}

.line-ch-row {
  @include flexBox(flex, null, null, null);
  gap: 1.5rem;

  &__chart-box {
    flex: 1;
    padding: 1.4rem 1.5rem 1.2rem;

    @include flexBox(flex, column nowrap, null, null);

    .top-left,
    .bottom-right {
      position: absolute;
    }

    .top-left {
      top: 0.5rem;
      left: -1rem;
    }

    .bottom-right {
      bottom: 0.85rem;
      right: -1rem;
    }

    .double-line-chart {
      flex: 1;
      margin-right: -0.5rem;
      width: calc(100% + 0.5rem);
      max-width: calc(100% + 0.5rem);
      max-height: 20rem;
    }
  }

  .theme-feature-widget {
    flex: 0 0 17.1875rem;
    max-width: 17.1875rem;

    background-position: 50% 5%;
    background-size: 122%;
  }

  .sparkline-line-charts {
    @include flexBox(flex, null, null, null);
    flex: 1;
    gap: 0.5rem;
    margin-top: 2rem;

    .chart-area {
      max-width: 100%;
      flex: 1;
    }

    .sparks-box {
      flex: 0 0 14.5625rem;
      width: 14.5625rem;
      padding: 1.8rem 1.25rem;
      margin-bottom: 0.35rem;

      @media (max-width: 1450px),
      (max-height: 760px) {
        flex: 0 0 10.5625rem;
        width: 10.5625rem;
      }

      ul {
        @include flexBox(flex, column nowrap, null, space-between);
        height: 100%;
        gap: 1rem;
      }
    }
  }

  .background-none {
    background: none;
  }
}

.s-stats {
  @include flexBox(flex, null, center, space-between);
  gap: 1.5rem;

  &__left {
    h4 {
      color: #4E5969;
    }
  }

  &__right {
    flex: 1;
    max-width: 60%;
    max-height: 2.5rem;
    min-height: 2.5rem;

    @media (max-width: 1450px),
    (max-height: 760px) {
      max-width: 55%;
      max-height: 2.5rem;
      min-height: 2.5rem;
      margin-right: -0.35rem;
    }
  }
}

.widget-title-top {
  @include flexBox(flex, null, center, space-between);
  gap: 1rem;

  &__right {
    @include flexBox(inline-flex, null, center, null);
    gap: 1.2rem;

    .anchor-tag-list {
      @include flexBox(inline-flex, null, center, null);
      gap: 0.38rem;

      .lightgreenThemeText {
        @include flexBox(inline-flex, null, center, center);
        padding: 0.375rem 0.45rem;
        height: 1.5625rem;
        gap: 0.25rem;

        font-size: 8px;
        border-radius: 6.25rem;
        background-color: var(--text-dark);
      }
    }

    .filter-reverse {
      .dot {
        top: -0.3rem;
        right: -0.55rem;

        width: 0.5rem;
        height: 0.5rem;
        background-color: #FF5E48;
      }
    }
  }
}

.chart-stats {
  @include flexBox(flex, null, null, null);
  gap: 1rem;

  .num {
    flex: 0 0 48%;
    min-width: 9rem;
    @include flexBox(inline-flex, column nowrap, null, null);
    margin-block: 1rem 2rem;

    span {
      position: relative;
      padding-top: 0.55rem;
      width: max-content;

      &::after {
        content: "";
        position: absolute;
        width: 94%;
        top: 0;
        left: 0;
        border-top: 1px solid #FF5E48;
      }
    }

    &--red {
      span {
        color: #FF5E48;
      }
    }

    &--green {
      span {
        &::after {
          border-color: var(--caribbean-green);
        }
      }
    }
  }
}

ul.widget-stats {
  @include flexBox(flex, null, null, space-between);
  gap: min(2.5vw, 1.5rem);

  li {
    flex: 1;
    max-width: 12rem;
  }

  .stats {
    height: 6.4375rem;
    @include flexBox(inline-flex, column nowrap, null, center);
    padding-inline: min(6vw, 2.2rem) 1rem;

    &--yellow {
      background: url(/public/images/bg-particles/yellow-side-lines.svg) no-repeat left;
      background-color: #FFEBBD;
    }

    &--green {
      background: url(/public/images/bg-particles/green-dotted-circles.svg) no-repeat left;
      background-color: rgba($color: #36B8A3, $alpha: 0.41);
      background-position: -20%;
    }

    &--blue {
      background: url(/public/images/bg-particles/blue-cross-lines.svg) no-repeat left;
      background-color: #D3F4FF;
      background-position: 7% 85%;
    }

    &--gray {
      background: url(/public/images/bg-particles/gray-circle-lines.svg) no-repeat left top;
      background-color: var(--neutral-02);
    }

    &--peach {
      background: url(/public/images/bg-particles/peach-asterisk-lines.svg) no-repeat left;
      background-color: rgba($color: #FFBAB0, $alpha: 0.51);
    }
  }
}

.api-docs-row {
  @include flexBox(flex, null, null, null);
  gap: 1rem;

  &__left {
    padding: 1.8rem 1.4rem;
    @include flexBox(inline-flex, column nowrap, center, null);
    gap: 2rem;
    flex: 0 0 17.8125rem;
    max-width: 17.8125rem;

    .doughnut-chart {
      position: relative;
      @include flexBox(flex, column nowrap, center, center);
      width: 9.48931rem;
      height: 9.48931rem;
      // width: 100%;
      background-color: var(--caribbean-light-green);


      box-shadow: inset 0px 0px 9.335px 0px rgba(0, 0, 0, 0.10), 0px 11.41px 49.789px rgba(54, 184, 163, 0.47);


      .bg-white {
        @include flexBox(flex, column nowrap, center, center);

        position: absolute;
        inset: 10%;
        padding: 1.125rem;
      }

      .marker-dot {
        @include flexBox(inline-flex, null, center, center);
        padding: 0.75rem;
        position: absolute;
        top: 0.125rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;

        width: 2.2rem;
        height: 2.2rem;

        background-color: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(2.6px);

        span {
          width: 100%;
          height: 100%;
          background-color: var(--caribbean-green);
        }
      }

      #apiDoughnut {
        position: relative;
        z-index: 1;
        margin-top: -0.55rem;
        width: 8.15rem !important;
        height: 8.15rem !important;
      }
    }
  }

  &__list {
    @include flexBox(flex, row wrap, null, space-between);
    gap: 1.125rem 2rem;

    .api-info {
      .square {
        width: 2.0625rem;
        height: 2.0625rem;
        flex-shrink: 0;
        border-radius: 0.375rem;

        @include flexBox(inline-flex, null, center, center);

        &--gray {
          background-color: var(--neutral-01);
        }

        &--green {
          background-color: var(--caribbean-green);
        }

        &--mustard {
          background-color: #ED9600;
        }

      }
    }
  }

  &__right {
    flex: 1;

    .widget-title-top {
      padding: 1.8rem 1.4rem 1.2rem;
    }
  }
}

.table-status-area {
  margin-right: 0.1rem;
  padding: 0 1.3rem 0 1.4rem;
  max-height: 19rem;
  overflow-y: auto;
}

.doc-listing-widget {
  .widget-title-top {
    padding: 0.8rem 1.75rem 0.69rem;
  }

  .table-area {
    margin: 0;
    padding: 0 1.6rem 1rem;
  }
}

.dropdown-menu--modal {
  .dropdown-item {
    color: var(--text-theme);
    padding: 0.6rem 1rem;

    &:hover,
    &:focus {
      background-color: var(--caribbean-light-green);
    }

    &:active,
    &.active {
      color: white;
      background-color: var(--caribbean-green);
    }
  }
}

.msg-text-area {
  margin-top: 0.85rem;
  border-radius: 0.9375rem;
  border: 1px solid #EAEBEB;
  padding: 1rem 0.75rem 0.75rem;

  .title-msg {
    margin-bottom: 0.938rem;
  }

  textarea {
    border: 0;
    height: 9.75rem;
    border-top: 1px solid var(--neutral-02);
    border-bottom: 1px solid var(--neutral-02);
    padding: 0.75rem 0.1rem;
    margin-bottom: 0.68rem;

    &:focus {
      outline: 0;
      border-color: var(--neutral-02);
    }
  }
}

.mydoc1-modal-wrapper {
  &.width40rem {
    width: 41.0625rem;
  }
}

.restricted-area {
  @include flexBox(flex, null, center, space-between);
  gap: 1rem;

  padding: 0.44rem 0.5rem 0.44rem 1.38rem;
  height: 3.8125rem;
  margin-block: 1.2rem 0;
  border-radius: 0.9375rem;

  .label {
    padding-top: 1px;
    letter-spacing: 0.045rem;
  }

  .right {
    @include flexBox(inline-flex, null, center, space-between);
    gap: 0.25rem;

    .form-control {
      @include form-control;
      height: 2.8125rem;
      width: 8.9375rem;
      padding-left: 3.25rem;
      box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);
      letter-spacing: 0.6px;
      text-align: center;

      &::placeholder {
        font-size: 0.875rem;
        color: #BCBCBC;
        font-style: normal;
      }
    }

    span.fab {
      left: 0;
      top: 0;
    }

    span {
      padding: 0 0.25rem;
      letter-spacing: 0.045rem;
    }
  }
}

.docur-form {
  .blurred-widget-block {
    position: relative;
    backdrop-filter: unset;
  }

  .dropdown-menu {
    border: 0;
    margin-top: 0.25rem !important;
    box-shadow: 0px 15px 11px 0px rgba(54, 184, 163, 0.08);

    .typr-pair {
      cursor: pointer;
      display: flex;
      font-weight: 500;
      padding: 0.5rem 1rem;

      &:hover {
        background-color: var(--neutral-06);
      }
    }
  }
}

.input-wrapper,
.form-control--link {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn-fab-dropdown {
    right: 9px;
    top: -2px;
    bottom: -1px;

    &+.dropdown-menu {
      right: -0.6rem !important;
    }
  }
}

.form-control--link {
  padding-right: 0;
}

// Custom tooltip //
// .custom-tooltip {
//   display: none;
//   position: absolute;
//   width: 10rem;
//   height: 10rem;
//   background-color: #20272C;
//   border: 1px solid #ddd;
//   padding: 10px;
//   font-size: 14px;
// }

// .bar-chart-area:hover .custom-tooltip {
//   display: block;
// }