@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Playfair+Display:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700;800&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  // color-scheme: dark light;
}

body {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: 'Poppins', sans-serif;
}

img,
svg,
picture,
video {
  display: block;
  max-width: 100%;
}

input,
select,
textarea,
button {
  font: inherit;
}

// ======== Custom scrollbar ======== //
// ::-webkit-scrollbar-track {
//   background-color: transparent;
// }
// ::-webkit-scrollbar {
//   width: 3px;
//   height: 3px;
//   background-color: transparent;
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 3rem;
//   background: var(--primary);
// }
// // for firefox
// @supports (scrollbar-color: black gray) {
//   * {
//     scrollbar-color: var(--primary) transparent;
//     scrollbar-width: thin;
//   }
// }
// ================================== //