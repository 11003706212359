/* clientsSec section */
.clientsSec {
  text-align: center;
  padding: calc(50px * var(--resp)) 0;
  position: relative;

  &__title {
    font-size: calc(22px * var(--resp));
    margin-bottom: calc(30px * var(--resp));

    img {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__single {
    img {
      width: auto !important;
    }
  }

  &__deco1 {
    position: absolute;
    left: 5%;
    top: 10%;
  }

  &__deco2 {
    position: absolute;
    right: 15%;
    top: 0%;
  }

  &__deco3 {
    position: absolute;
    right: 5%;
    top: 20%;
  }

  &__deco4 {
    position: absolute;
    right: 45%;
    bottom: 15%;
  }

  &__all {
    .owl-item {
      padding-block: 50px;
    }
  }

  &__single {
    img {
      transform: scale(var(--resp));
    }

  }
}

@media (max-width:1800px) {}

@media (max-width:1600px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {}

@media (max-width:767.98px) {
  .clientsSec__title {
    margin-bottom: 0;
  }

  .clientsSec__title img {
    height: 30px;
  }
}

@media (max-width:1800px) {}

@media (max-width:1600px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {}

@media (max-width:767.98px) {}