/* Keyframes */

@keyframes fadeInAnim {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes showUpwards {
	0% {
		clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
	}

	100% {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}

@keyframes showDownwards {
	0% {
		clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
	}

	100% {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}

@keyframes showLeftwards {
	0% {
		clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
	}

	100% {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}

@keyframes showRightwards {
	0% {
		clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
	}

	100% {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}




// Animation towards right
@keyframes animToRight {
	0% {
		opacity: 0;
		transform: translateX(-5vw);
	}

	15% {
		opacity: 1;
	}

	75% {
		opacity: 1;
	}

	90% {
		opacity: 0;
		transform: translateX(5vw);
	}

	100% {
		opacity: 0;
		transform: translateX(5vw);
	}
}

.anim-to-right {
	animation-play-state: paused;
	opacity: 0;
	animation: animToRight 10s linear infinite forwards;
}

.section--active .anim-to-right {
	animation-play-state: play;
}

// Animation zoom in 
@keyframes animZoomIn {
	0% {
		opacity: 0;
		transform: scale(0) rotate(0deg);
	}

	30% {
		opacity: 1;
		transform: scale(1) rotate(180deg);
	}

	75% {
		opacity: 1;
		transform: scale(1) rotate(360deg);
	}

	90% {
		opacity: 0;
		transform: scale(0) rotate(0deg);
	}

	100% {
		opacity: 0;
		transform: scale(0) rotate(0deg);
	}
}

.anim-zoom-in {
	animation-play-state: paused;
	opacity: 0;
	animation: animZoomIn 10s linear infinite forwards;
}

.section--active .anim-zoom-in {
	animation-play-state: play;
}


// Click Arrow
@keyframes animClickArrow {

	0%,
	2.5%,
	97.5%,
	100% {
		transform: rotate(0deg) translate3d(0, 0, 0);
	}

	45%,
	50% {
		transform: rotate(-10deg) translate3d(20px, -20px, 0);
	}
}

.anim-click-arrow {
	transform-origin: top right;
	animation-play-state: paused;
	animation: animClickArrow 5s ease-in-out infinite forwards;
}

.section--active .anim-click-arrow {
	animation-play-state: play;
}

@keyframes animUpDown {

	0%,
	100% {
		transform: translateY(25px);
	}

	50% {
		transform: translateY(-25px);
	}
}

.anim-up-down {
	transform: translateY(25px);
	animation: animUpDown 5s ease-in-out forwards infinite;
	animation-play-state: paused;
}

.section--active .servicesSec__tooltip-up-down {
	animation-play-state: running;
}