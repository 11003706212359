/* verifySec section */
.verifySec {
  padding-bottom: calc(100px * var(--resp));

  &__inner {
    background-color: #20272C;
    color: #F3F6F8;
    border-radius: calc(14px * var(--resp));
    padding: calc(50px * var(--resp));
    background-image: url('/public/landing_images/verify/deco4.svg'), url('/public/landing_images/verify/deco3.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: top right, bottom left;
    background-size: calc(569px * var(--resp)) auto, calc(536px * var(--resp)) auto;
    text-align: center;
    position: relative;
  }

  &__deco1 {
    width: calc(569px * var(--resp));
    margin-bottom: calc(20px * var(--resp));
  }

  &__deco3 {
    width: calc(66px * var(--resp));
    margin-left: calc(130px * var(--resp));
  }

  h2 {
    color: #E3FAF6;
  }

  .section-desc {
    max-width: 47%;
    margin-inline: auto;
    font-size: calc(24px * var(--resp));
    margin-bottom: calc(30px * var(--resp));
  }

  .verifySec__buttons {
    display: flex;
    margin-inline: auto;
    width: calc(338px * var(--resp));
    border: 1px solid #FFFFFF;
    padding: calc(5px * var(--resp));
    justify-content: center;
    gap: 5px;
    border-radius: 2px;
    margin-bottom: calc(30px * var(--resp));
  }

  &__deco2 {
    position: absolute;
    right: 2%;
    bottom: 5%;
    width: calc(55px * var(--resp));
    user-select: none;
    pointer-events: none;
  }
}

@media (max-width: 991.98px) {
  .verifySec__inner {
    .section-head1 {
      padding-inline: 5px;
    }
  }

  .verifySec__deco1 {
    width: 100%;
  }

  .verifySec__inner {
    padding: calc(150px * var(--resp)) calc(25px * var(--resp)) calc(200px * var(--resp));
  }

  .verifySec .section-desc {
    max-width: 100%;
    font-size: calc(18px * var(--resp));
  }
}

@media (max-width: 991.98px) {}

@media (max-width:1800px) {}

@media (max-width:1600px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {}

@media (max-width:767.98px) {}