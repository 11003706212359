// Color Variables //
:root {
  --primary-dark: #000;
  --text-dark: #20272C;
  --text-theme: #135149;
  --text-theme-100: #106456;

  --neutral: #BCBCBC;
  --neutral-01: #63727E;
  --neutral-02: #E5EAEE;
  --neutral-03: #BCBCBC;
  --neutral-04: #BFC4C7;
  --neutral-05: #EAEAEA;
  --neutral-06: #f7f7f7;

  --caribbean-green: #36B8A3;
  --caribbean-light-green: #E3FAF6;
  --mustard: #ED9600;

  --white: #ffffff;

  --success: #20946b;
  --fail: #d90429;

  --pastel-green: #d2f2db;
  --pastel-blue: #d0e1ee;
  --pastel-pink: #efcfe4;


  // --main-shadow: 3px 4px 4px rgba(151, 134, 154, 0.13);
}