/* freedomSec section */
.freedomSec {
  margin-top: calc(50px * var(--resp));

  &__bg {
    width: 110%;
    max-width: unset;
  }

  &__code {
    width: 97%;
    margin-bottom: 1.5%;
  }

  .section-head1 {
    width: 60%;
  }

  &__content {
    position: absolute;
    top: 9%;
    left: 4%;
    right: 0;
  }

  &__star {
    position: absolute;
    right: 0%;
    bottom: 0%;
  }

  &__box {
    border-radius: calc(14px * var(--resp));
    background-color: #FEEDC2;
    width: 100%;
    background-image: url('/public/landing_images/freedom/bg2.svg');
    background-repeat: no-repeat;
    background-size: 28% auto;
    background-position: right bottom 20%;
    min-height: calc(210px * var(--resp));
    padding: calc(35px * var(--resp)) calc(25px * var(--resp)) calc(35px * var(--resp)) calc(15px * var(--resp));
    margin-bottom: calc(50px * var(--resp));

    h3 {
      margin-top: calc(20px * var(--resp));
      font-family: var(--ff2);
      font-size: calc(26px * var(--resp));
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 26.4px */
      text-transform: capitalize;
    }

    p {
      text-transform: capitalize;
      font-size: calc(18px * var(--resp));
    }

    .flex-shrink-0 {
      width: 30%;
      text-align: center;

      img {
        width: 80%;
      }
    }

    &-1 {
      background-color: #FEEDC2;
      margin-top: 22%;
      transform: translateX(-25%);
    }

    &-2 {
      background-color: #C3F3E7;
      transform: translateX(-15%);
    }

    &-3 {
      background-color: #FDD2C1;
      transform: translateX(-25%);
    }
  }

  &__curvy {
    height: calc(30px * var(--resp)) !important;
  }


  .hljs {
    background: transparent;
  }

  &__code {
    position: relative;
    display: flex;
    background: #292a2b;
    font-size: calc(18px * var(--resp));
    line-height: 1.5;
    font-family: var(--ff1);
    padding: calc(20px * var(--resp));
    padding-top: calc(40px * var(--resp));

    pre,
    code {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }

    pre code {
      font-size: calc(18px * var(--resp));
    }

    pre code.hljs {
      padding-top: 0;
    }
  }

  &__numberLine {
    color: #5c6370;
    font-family: var(--ff1);
  }

  &__type {
    position: absolute;
    top: 10px;
    font-family: var(--ff1);
    display: flex;
    white-space: nowrap;
  }

  @keyframes cursorAnim {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  }

  &.section--active #cursor {
    animation: cursorAnim 1s ease-in-out infinite;
  }
}

@media (max-width:1800px) {
  .freedomSec__box {
    width: 110%;
    min-height: calc(150px * var(--resp));
    padding: calc(25px * var(--resp)) calc(25px * var(--resp)) calc(25px * var(--resp)) calc(15px * var(--resp));
    margin-bottom: calc(30px * var(--resp));

  }

  .freedomSec__box h3 {
    font-size: calc(22px * var(--resp));
  }

  .freedomSec__box p {
    font-size: calc(15px * var(--resp));
  }
}

@media (max-width:1600px) {
  .freedomSec .section-head1 {
    font-size: calc(35px * var(--resp));
  }

  .freedomSec__content {
    top: 8%;
  }

  .freedomSec__bg {
    width: 112.5%;
  }
}

@media (max-width:1400px) {
  .freedomSec .section-head1 {
    font-size: calc(40px * var(--resp));
  }
}

@media (max-width:1300px) {}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {
  .freedomSec__bg {
    width: 100%;
    min-height: 500px;
    margin: -0.5rem;
  }

  .freedomSec {
    .container {
      padding-inline: 1.25rem;
    }

    .freedomSec__box-1 {
      margin-top: 10rem;
    }

    &__curvy {
      height: calc(18px* var(--resp)) !important;
    }

    &__box {
      margin-block: 0.8rem;
      padding: 0.8rem 0.65rem 0.8rem 0.5rem;
      min-height: calc(120px* var(--resp));

      p {
        margin-bottom: 0;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 0.25rem;
      }

      .flex-shrink-0 {
        width: 4rem;

        img {
          width: 100%;
        }
      }
    }

    .freedomSec__box-2 {
      margin-left: 0.75rem;
    }
  }
}

@media (max-width:767.98px) {
  .freedomSec {
    overflow: hidden;
  }

  .freedomSec__content {
    top: 5.6%;
  }

  .freedomSec__box {
    width: 100%;
    transform: translate3d(0, 0, 0);
  }
}