/* Typos */
.section-head1 {
  font-family: var(--ff2);
  font-weight: bold;
  font-size: calc(65px * var(--resp));
  text-transform: capitalize;
  line-height: 1.1;
  margin-bottom: calc(20px * var(--resp));

  img {
    display: inline-block;
    user-select: none;
    height: calc(70px * var(--resp));
    // margin-top: -15px;
  }
}

.storiesSec {
  .section-head1 {
    img {
      margin-top: -15px;
    }
  }
}

.section-desc {
  font-size: calc(20px * var(--resp));
  line-height: 117%;
  text-transform: capitalize;
  margin-bottom: calc(20px * var(--resp));
}

.section-greentxt {
  font-size: calc(22px * var(--resp));
  color: var(--col3);
  font-weight: 600;
  line-height: 120%;
  text-transform: capitalize;
}


@media (max-width:991.98px) {
  .section-head1 {
    padding-inline: 0.6rem;
    display: inline-block !important;
  }

  .storiesSec {
    .section-head1 {
      img {
        margin-top: -10px;
        margin-bottom: -0.5rem;
      }
    }
  }

  .storiesSec {
    .section-greentxt {
      padding-inline: 0.65rem;
    }

    &__img {
      margin: auto;
    }
  }

  .section-greentxt {
    font-size: calc(18px * var(--resp));
  }
}

@media (max-width:767.98px) {
  .section-desc {
    font-size: calc(15px * var(--resp));
    padding-inline: 10px;
  }

  .section-head1 {
    font-size: 29px !important;
    line-height: 1.3;

    img {
      height: calc(50px * var(--resp));
    }
  }
}