/* servicesSec section */
.servicesSec {
  // background: url(../images/stories/bg.png) no-repeat center center;
  background: url(/public/landing_images/stories/bg.png) no-repeat center center;
  background-size: 100% auto;
  min-height: calc(695px * var(--resp));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: calc(50px * var(--resp));

  &__deco3 {
    position: absolute;
    left: 5%;
    top: -5%;
  }

  .section-desc {
    max-width: 80%;
  }

  .section-greentxt {
    margin-bottom: calc(20px * var(--resp));
  }

  &__buttons {
    margin-bottom: calc(25px * var(--resp));
  }

  &__deco4-wrapper {
    padding-left: 15%;
  }

  &__deco4 {
    width: calc(66px * var(--resp));
  }

  &__tooltip {
    background-color: #fff;
    border-radius: calc(50px * var(--resp));
    padding: calc(10px * var(--resp)) calc(20px * var(--resp));
    box-shadow: 0 0 calc(30px * var(--resp)) rgba(206, 206, 206, 0.75);
    position: absolute;
    top: -20%;
    left: -10%;
    z-index: 1;
    width: 100%;

    &-text {
      font-size: calc(12px * var(--resp));
      line-height: 1.2;
      margin-bottom: calc(2px * var(--resp));
    }

    &-number {
      font-family: var(--ff3);
      font-size: calc(18px * var(--resp));
      line-height: 1;
    }

    &-right {
      border-bottom-right-radius: 15px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-left: 5.5px solid #ff634f;
        border-top: 5.5px solid transparent;
        border-bottom: 5.5px solid transparent;
        box-shadow: 0 0 calc(30px * var(--resp)) #ff634f;
        border-radius: 2px;
        transform: rotate(45deg) translate3d(150%, 20%, 0);
        transform-origin: center center;
      }
    }

    &-left {
      border-bottom-left-radius: 15px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border-right: 5.5px solid #ff634f;
        border-top: 5.5px solid transparent;
        border-bottom: 5.5px solid transparent;
        box-shadow: 0 0 calc(30px * var(--resp)) #ff634f;
        border-radius: 2px;
        transform: rotate(-45deg) translate3d(-150%, 11%, 0);
        transform-origin: center center;
      }
    }
  }

  &__blocks {
    margin-top: 20%;
    margin-inline: auto;
  }

  &__block {
    position: relative;
    width: calc(175px * var(--resp));
    height: calc(175px * var(--resp));

    img {
      display: block;
      width: 100%;
      user-select: none;
      pointer-events: none;
    }

    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      text-align: center;
      max-width: 80%;
      font-family: var(--ff3);
      line-height: 1;
      color: rgba(32, 39, 44, 0.65);
      font-size: calc(16px * var(--resp));
    }

    &-row {
      display: flex;
      gap: 0;
      justify-content: end;
    }

    &-1 {
      .servicesSec__tooltip-number {
        color: #ba7f75;
      }

      .servicesSec__tooltip {
        left: 70%;
      }
    }

    &-2 {
      .servicesSec__tooltip-number {
        color: #beeffe;
      }

    }

    &-3 {
      .servicesSec__tooltip-number {
        color: #ffcf67;
      }

      .servicesSec__tooltip {
        left: 97.5%;
      }
    }

    &-4 {
      .servicesSec__tooltip-number {
        color: #1f272c;
      }

      .servicesSec__tooltip {
        left: -80%;
        top: 6%;
      }

      .servicesSec__block-text {
        color: rgba(255, 255, 255, 0.75);
      }
    }

    &-5 {
      .servicesSec__tooltip-number {
        color: #ff634f;
      }

      .servicesSec__tooltip {
        left: -40%;
        top: -10%;
      }
    }

    &-6 {
      .servicesSec__tooltip-number {
        color: #00b8a3;
      }

      .servicesSec__tooltip {
        left: 90%;
        top: 47%;
      }
    }
  }

  .servicesSec__block-5 .servicesSec__tooltip {
    border-end-end-radius: 0.25rem;
  }


}

@keyframes tooltipAnimUpDown {

  0%,
  100% {
    transform: translateY(5px);
  }

  50% {
    transform: translateY(-5px);
  }
}

.servicesSec__tooltip-up-down {
  transform: translateY(25px);
  animation: tooltipAnimUpDown 5s ease-in-out forwards infinite;
  animation-play-state: paused;
}

.section--active .servicesSec__tooltip-up-down {
  animation-play-state: running;
}

// Media Queries 
@media (max-width:1800px) {
  .servicesSec__blocks {
    --resp: 0.75;
  }
}

@media (max-width:1600px) {}

@media (max-width:1400px) {
  .servicesSec__blocks {
    --resp: 0.7;
  }
}

@media (max-width:1300px) {
  .servicesSec__blocks {
    --resp: 0.65;
  }
}

@media (max-width: 1200px) {
  .servicesSec {
    min-height: auto;
    background-size: auto 100%;
  }

  .servicesSec__tooltip-left {
    border-radius: calc(50px * var(--resp));
    border-bottom-right-radius: 15px;
  }

  .servicesSec__tooltip-left::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: auto;
    border-right: none;
    border-left: 7px solid #ff634f;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    box-shadow: 0 0 calc(30px * var(--resp)) #ff634f;
    border-radius: 2px;
    transform: rotate(45deg) translate3d(150%, 20%, 0);
    transform-origin: center center;
  }

  .servicesSec__tooltip {
    left: -70% !important;
  }

  .servicesSec__block-6 .servicesSec__tooltip {
    top: 0%;
  }
}

@media (max-width:991.98px) {
  .servicesSec__blocks {
    margin-top: 30px;
  }

}

@media (max-width:767.98px) {
  .servicesSec__deco3 {
    top: -10%;
    left: -5%;
  }

  .servicesSec__blocks {
    margin-top: -90px;
  }

  .servicesSec {
    background-size: auto 100%;
  }

  .servicesSec__tooltip-left {
    border-radius: calc(50px * var(--resp));
    border-bottom-right-radius: 15px;
  }

  .servicesSec {
    .section-head1 img {
      height: 50px;
    }

    &__deco4 {
      width: 35px;
      rotate: 60deg;
      margin-top: 1.5rem;

      &-wrapper {
        padding-left: 6.8%;
      }
    }

    .button-dark {
      margin-left: 0.6rem;
    }

    &__tooltip-left::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: auto;
      border-right: none;
      border-left: 4px solid #ff634f;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      box-shadow: 0 0 calc(30px * var(--resp)) #ff634f;
      border-radius: 2px;
      transform: rotate(45deg) translate3d(150%, 20%, 0);
      transform-origin: center center;
    }

    &__tooltip-right::after {
      border-left: 4px solid #ff634f;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  .servicesSec__tooltip {
    transform: translate3d(-34%, 5%, 0);
    left: -40% !important;
  }

  .servicesSec__block-6 .servicesSec__tooltip {
    top: 0%;
  }

  .servicesSec__block {
    position: relative;
    width: calc(150px * var(--resp));
    height: calc(150px * var(--resp));
  }

  .servicesSec__block-4 .servicesSec__tooltip {
    top: 0;
  }
}