/* numberSec section */
.numberSec {
  text-align: center;
  padding: calc(100px * var(--resp)) 0;
  position: relative;

  &__deco1 {
    position: absolute;
    left: 5%;
    top: 10%;
  }

  &__deco2 {
    position: absolute;
    right: 5%;
    top: 20%;
  }

  &__deco3 {
    position: absolute;
    right: 45%;
    bottom: 15%;
  }

  &__title {
    text-align: center;
    font-family: var(--ff2);
    font-size: calc(22px * var(--resp));
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;
    margin-bottom: calc(35px * var(--resp));

    img {
      width: calc(63px * var(--resp));
    }
  }

  &__inner {
    border-radius: 999px;
    border: 1px solid #E1E1E1;
    padding: calc(20px * var(--resp));
  }

  &__single {
    border-radius: 999px;
    padding: calc(20px * var(--resp));

    &-1 {
      background-color: #F1FCFA;

      .numberSec__count {
        color: #36B8A3;
      }
    }

    &-2 {
      background-color: #FFF8F1;

      .numberSec__count {
        color: rgba(237, 150, 0, 1);
      }
    }

    &-3 {
      background-color: #F0FAFF;

      .numberSec__count {
        color: rgba(95, 169, 194, 1);
      }
    }

    &-4 {
      background-color: #F2F2F2;

      .numberSec__count {
        color: rgba(99, 114, 126, 1);
      }
    }
  }

  &__count {
    color: #36B8A3;
    font-family: var(--ff3);
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: calc(0px * var(--resp));
  }

  &__text {
    font-family: var(--ff2);
    font-size: calc(15px * var(--resp));
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;
  }
}


@media (max-width:1800px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {}

@media (max-width:767.98px) {
  .numberSec {
    &__inner {
      .row>* {
        padding-inline: 0.15rem;
      }
    }

    &__title {
      font-size: 1rem;
    }

    &__single {
      border-radius: 2rem;
    }
  }

  .numberSec__inner {
    padding: calc(8px * var(--resp));

    .row {
      --bs-gutter-x: 10px;
    }
  }

  .numberSec__single {
    padding: calc(10px * var(--resp));
  }

  .numberSec__count {
    font-size: 28px;
  }

  .numberSec__text {
    font-size: calc(13px * var(--resp));
  }

  .numberSec__title img {
    display: inline-block;
  }
}