/* storiesSec section */
.storiesSec {
  background: url('/public/landing_images/stories/bg.png') no-repeat center center;
  background-size: 100% auto;
  min-height: calc(695px * var(--resp));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: calc(50px * var(--resp));

  .row {
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 85%;
  }

  &__deco1 {
    position: absolute;
    right: 20%;
    bottom: 20%;
    width: 6%;
  }

  &__deco2 {
    position: absolute;
    left: 10%;
    top: 10%;
    width: 6%;
    pointer-events: none;
    user-select: none;
  }
}

@media (max-width:1800px) {}

@media (max-width:1600px) {}

@media (max-width:1400px) {}

@media (max-width:1300px) {}

@media (max-width: 1200px) {}

@media (max-width:991.98px) {}

@media (max-width:767.98px) {
  .storiesSec {
    background-size: auto 100%;
    background-position: left 0% center;

    .row {
      flex-direction: column-reverse
    }
  }
}